import { ServiceDetail } from "@/types/service";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface ServiceDetailsProps {
  details: ServiceDetail[];
}

const ServiceDetails = ({ details }: ServiceDetailsProps) => {
  return (
    <Accordion type="single" collapsible>
      {details.map((detail, idx) => (
        <AccordionItem key={idx} value={`item-${idx}`}>
          <AccordionTrigger className="text-left">
            {detail.title}
          </AccordionTrigger>
          <AccordionContent>
            {detail.content}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ServiceDetails;