import { useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { BookOpen } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface FeaturedEventsProps {
  language: string;
}

const FeaturedEvents = ({ language }: FeaturedEventsProps) => {
  const navigate = useNavigate();

  const { data: events } = useQuery({
    queryKey: ['featuredEvents'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('gallery_events')
        .select('*, gallery_images(*)')
        .eq('is_featured', true)
        .order('event_date', { ascending: false })
        .limit(3);

      if (error) throw error;
      return data;
    },
  });

  const handleViewAllClick = () => {
    navigate('/gallery');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="py-20 bg-neutral-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-neutral-dark mb-4"
          >
            {language === 'KO' ? '두루의 전문성과 활동' : 'Our Expertise in Action'}
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
            className="text-lg text-gray-600"
          >
            {language === 'KO' 
              ? '두루는 한국에서 성공적인 비즈니스를 위한 전문적인 지원과 네트워킹을 제공합니다'
              : 'DURU provides professional support and networking opportunities for successful business operations in Korea'
            }
          </motion.p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {events?.map((event, index) => {
            const coverImage = event.gallery_images?.find(img => img.is_cover)?.image_url;
            
            return (
              <motion.div
                key={event.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
                className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={coverImage || '/placeholder.svg'}
                    alt={language === 'KO' ? event.title_ko || event.title_en : event.title_en}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-neutral-dark mb-2">
                    {language === 'KO' ? event.title_ko || event.title_en : event.title_en}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    {language === 'KO' ? event.description_ko || event.description_en : event.description_en}
                  </p>
                  <div className="text-sm text-gray-500">
                    {new Date(event.event_date).toLocaleDateString(language === 'KO' ? 'ko-KR' : 'en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        <div className="text-center">
          <Button
            onClick={handleViewAllClick}
            className="inline-flex items-center gap-2 px-6 py-3 bg-primary text-white rounded-full hover:bg-primary-dark transition-colors h-11"
          >
            <BookOpen className="w-5 h-5" />
            {language === 'KO' ? '더 많은 활동 보기' : 'View More Activities'}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FeaturedEvents;