
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { CreateButton } from './AdminButtonStyles';
import GalleryEventEditor from './gallery/GalleryEventEditor';
import GalleryEventTable from './gallery/GalleryEventTable';
import { useToast } from '@/hooks/use-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import type { GalleryEvent } from '@/types/gallery';

const GalleryManager = () => {
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<GalleryEvent | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<GalleryEvent | null>(null);
  const [isDeletingId, setIsDeletingId] = useState<string | undefined>();

  const { data: events, isLoading, refetch } = useQuery({
    queryKey: ['gallery-events-admin'],
    queryFn: async () => {
      const { data: eventsData, error: eventsError } = await supabase
        .from('gallery_events')
        .select('*')
        .order('event_date', { ascending: false });

      if (eventsError) throw eventsError;

      const eventsWithImages = await Promise.all(
        (eventsData || []).map(async (event) => {
          const { data: coverImage } = await supabase
            .from('gallery_images')
            .select('*')
            .eq('event_id', event.id)
            .eq('is_cover', true)
            .maybeSingle();

          return {
            ...event,
            cover_image: coverImage ? [coverImage] : [],
          };
        })
      );

      return eventsWithImages as GalleryEvent[];
    },
  });

  const handleCreateNew = () => {
    setSelectedEvent(null);
    setIsEditing(true);
  };

  const handleEdit = (event: GalleryEvent) => {
    setSelectedEvent(event);
    setIsEditing(true);
  };

  const handleDelete = async (event: GalleryEvent) => {
    setEventToDelete(event);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (!eventToDelete) return;

    setIsDeletingId(eventToDelete.id);
    try {
      // 1. Get all images for this event
      const { data: images } = await supabase
        .from('gallery_images')
        .select('*')
        .eq('event_id', eventToDelete.id);

      if (images && images.length > 0) {
        // 2. Delete images from storage
        const fileNames = images.map(img => {
          const urlParts = img.image_url.split('/');
          return urlParts[urlParts.length - 1];
        });

        const { error: storageError } = await supabase.storage
          .from('gallery_images')
          .remove(fileNames);

        if (storageError) throw storageError;

        // 3. Delete image records from the database
        const { error: imagesError } = await supabase
          .from('gallery_images')
          .delete()
          .eq('event_id', eventToDelete.id);

        if (imagesError) throw imagesError;
      }

      // 4. Delete the event
      const { error: eventError } = await supabase
        .from('gallery_events')
        .delete()
        .eq('id', eventToDelete.id);

      if (eventError) throw eventError;

      toast({
        title: "Success",
        description: "Event deleted successfully",
      });

      await refetch();
    } catch (error: any) {
      toast({
        title: "Error",
        description: `Failed to delete event: ${error.message}`,
        variant: "destructive",
      });
    } finally {
      setIsDeletingId(undefined);
      setShowDeleteDialog(false);
      setEventToDelete(null);
    }
  };

  if (isEditing) {
    return (
      <div className="space-y-4">
        <Button variant="outline" onClick={() => setIsEditing(false)}>
          Back to List
        </Button>
        <GalleryEventEditor
          onSave={async () => {
            await refetch();
            setIsEditing(false);
            setSelectedEvent(null);
          }}
          initialData={selectedEvent}
        />
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Gallery Events</h2>
        <CreateButton onClick={handleCreateNew}>
          Create New Event
        </CreateButton>
      </div>

      <GalleryEventTable 
        events={events || []} 
        onEdit={handleEdit} 
        onDelete={handleDelete}
        isDeletingId={isDeletingId}
      />

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the event "{eventToDelete?.title_en}" and all its associated images.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={confirmDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default GalleryManager;

