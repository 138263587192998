"use client";

import { useEffect, useRef } from "react";

interface BorderBeamProps {
  size?: number;
  duration?: number;
  delay?: number;
  className?: string;
}

export const BorderBeam = ({
  size = 250,
  duration = 10,
  delay = 0,
  className = "",
}: BorderBeamProps) => {
  const beamRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!beamRef.current || !containerRef.current) return;

    const container = containerRef.current;
    const beam = beamRef.current;

    const moveBeam = (e: MouseEvent) => {
      const rect = container.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      beam.style.left = `${x}px`;
      beam.style.top = `${y}px`;
    };

    container.addEventListener("mousemove", moveBeam);

    return () => {
      container.removeEventListener("mousemove", moveBeam);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`absolute inset-0 overflow-hidden rounded-[inherit] [mask-image:linear-gradient(white,transparent)] ${className}`}
    >
      <div
        ref={beamRef}
        className="pointer-events-none absolute h-[calc(var(--size)*1px)] w-[calc(var(--size)*1px)] translate-x-[-50%] translate-y-[-50%] animate-border-beam rounded-full bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)]"
        style={{
          "--size": size,
          "--duration": `${duration}s`,
          "--delay": `${delay}s`,
        } as React.CSSProperties}
      />
    </div>
  );
};