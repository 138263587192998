export interface TeamTranslations {
  title: string;
  subtitle: string;
  cta: string;
}

export const teamTranslations = {
  EN: {
    title: "Meet Our Dedicated Team",
    subtitle: "Our expert team brings years of experience in international business consulting, ensuring your success in the Indian market.",
    cta: "Learn more about our team"
  },
  KO: {
    title: "전문 팀 소개",
    subtitle: "우리의 전문가 팀은 국제 비즈니스 컨설팅에서 수년간의 경험을 보유하고 있어 인도 시장에서의 성공을 보장합니다.",
    cta: "팀 소개 더 보기"
  }
};