
export const heroTranslations = {
  EN: {
    badge: "Your Gateway to India",
    title: "Expert Business",
    titleHighlight: "Setup in India",
    subtitle: "Helping global businesses expand into India with seamless incorporation & compliance.",
    cta: {
      primary: "Get Started",
      secondary: "Learn More"
    }
  },
  KO: {
    badge: "인도 진출 전문",
    title: "인도 시장 진출을 위한",
    titleHighlight: "원스탑 토탈 솔루션",
    subtitle: "법인 설립, 사업 셋업, 인력 채용, 연간 회계 업무 등\n한국 기업의 인도 진출을 종합적으로 지원합니다",
    cta: {
      primary: "시작하기",
      secondary: "자세히 보기"
    }
  }
};
