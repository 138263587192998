
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "@/utils/translations";

interface CeoPreviewSectionProps {
  language: string;
}

const CeoPreviewSection = ({ language }: CeoPreviewSectionProps) => {
  const t = useTranslation(language);

  return (
    <section className="w-full bg-neutral-dark py-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center gap-8">
          {/* CEO Image */}
          <div className="w-full md:w-1/4">
            <div className="relative aspect-square overflow-hidden rounded-lg shadow-xl">
              <img
                src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/ceo-yongjoo-yoo-duru-compliance.webp"
                alt={t.ceoPreview.imageAlt}
                className="object-cover w-full h-full scale-x-[-1]"
              />
            </div>
          </div>

          {/* Content */}
          <div className="w-full md:w-3/4 space-y-4">
            <blockquote className="text-gray-300 leading-relaxed max-w-2xl text-lg italic">
              "{t.ceoPreview.message}"
            </blockquote>
            
            <div className="flex flex-col gap-1">
              <p className="text-white font-medium">Yongjoo Yoo</p>
              <p className="text-gray-400">CEO of Duru Compliance</p>
            </div>

            <Link 
              to="/about" 
              className="inline-flex items-center gap-2 text-secondary hover:text-secondary-light font-medium transition-colors"
            >
              {t.ceoPreview.cta}
              <ArrowRight className="w-4 h-4" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CeoPreviewSection;
