import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import LanguageSelector from "@/components/navbar/LanguageSelector";
import NavLinks from "@/components/navbar/NavLinks";
import { useTranslation } from '@/utils/translations';
import { cn } from '@/lib/utils';

interface DesktopMenuProps {
  language: string;
  onLanguageChange: (lang: string) => void;
  isActive: (section: string) => boolean;
  isHeroSection: boolean;
}

const DesktopMenu = ({ language, onLanguageChange, isActive, isHeroSection }: DesktopMenuProps) => {
  const t = useTranslation(language);

  return (
    <div className="hidden md:flex md:items-center md:justify-between md:flex-1 pl-8">
      {/* Center-aligned navigation links */}
      <div className="flex-1 flex justify-center space-x-6">
        <NavLinks 
          isActive={isActive} 
          language={language} 
          textColor={cn(
            "text-neutral-dark",
            isHeroSection && "text-neutral-dark"
          )}
        />
      </div>
      
      {/* Right-aligned language selector and contact button */}
      <div className="flex items-center space-x-4">
        <LanguageSelector 
          language={language} 
          onLanguageChange={onLanguageChange} 
        />
        <Link to="/contact">
          <Button 
            variant="default" 
            className={cn(
              "bg-primary hover:bg-primary/90 text-white transition-all duration-300 transform hover:scale-105",
              isHeroSection && "bg-primary hover:bg-primary/90"
            )}
          >
            {t.navbar.contactUs}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DesktopMenu;