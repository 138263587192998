import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '@/utils/translations';
import { cn } from '@/lib/utils';
import NavLink from './NavLink';
import {
  handleHomeNavigation,
  handleServicesNavigation,
  handleAboutNavigation
} from '@/utils/navigation';

interface NavLinksProps {
  isActive: (section: string) => boolean;
  isMobile?: boolean;
  onMobileClick?: () => void;
  language: string;
  textColor?: string;
}

const NavLinks = ({ 
  isActive, 
  isMobile = false, 
  onMobileClick, 
  language, 
  textColor = 'text-gray-600' 
}: NavLinksProps) => {
  const t = useTranslation(language);
  const location = useLocation();
  const navigate = useNavigate();

  const linkClass = isMobile
    ? cn(
        "block px-3 py-2 text-gray-600 hover:text-primary relative",
        "after:content-[''] after:absolute after:bottom-0 after:left-3 after:right-3 after:h-0.5 after:bg-primary after:scale-x-0 after:transition-transform after:duration-200",
        "hover:after:scale-x-100"
      )
    : textColor;

  return (
    <>
      <NavLink
        to="/"
        onClick={(e) => handleHomeNavigation(e, navigate, location.pathname, onMobileClick)}
        isActive={isActive('home')}
        className={linkClass}
      >
        {t.navbar.home}
      </NavLink>

      <NavLink
        to="/#services"
        onClick={(e) => handleServicesNavigation(e, navigate, location.pathname, onMobileClick)}
        isActive={isActive('services')}
        className={linkClass}
      >
        {t.navbar.services}
      </NavLink>

      <NavLink
        to="/about"
        onClick={(e) => handleAboutNavigation(e, navigate, onMobileClick)}
        isActive={isActive('about') || location.pathname === '/about'}
        className={linkClass}
      >
        {t.navbar.about}
      </NavLink>

      <NavLink
        to="/gallery"
        onClick={onMobileClick}
        isActive={location.pathname === '/gallery'}
        className={linkClass}
      >
        {t.navbar.gallery}
      </NavLink>

      <NavLink
        to="/insights"
        onClick={onMobileClick}
        isActive={isActive('insights') || location.pathname === '/insights'}
        className={linkClass}
      >
        {t.navbar.insights}
      </NavLink>
    </>
  );
};

export default NavLinks;