import { Button } from '../ui/button';
import { LucideIcon } from 'lucide-react';

interface EditorToolbarButtonProps {
  onClick: () => void;
  isActive?: boolean;
  icon: LucideIcon;
}

const EditorToolbarButton = ({ onClick, isActive, icon: Icon }: EditorToolbarButtonProps) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={onClick}
      className={isActive ? 'bg-muted' : ''}
    >
      <Icon className="h-4 w-4" />
    </Button>
  );
};

export default EditorToolbarButton;