import { ServiceTranslations } from "@/types/service";
import { servicesCommonTranslations } from "./services/common";
import { incorporationTranslations } from "./services/incorporation";
import { strategyTranslations } from "./services/strategy";
import { recruitmentTranslations } from "./services/recruitment";
import { taxationTranslations } from "./services/taxation";
import { realEstateTranslations } from "./services/realEstate";

export const servicesTranslations = {
  EN: {
    ...servicesCommonTranslations.EN,
    incorporation: incorporationTranslations.EN,
    strategy: strategyTranslations.EN,
    recruitment: recruitmentTranslations.EN,
    taxation: taxationTranslations.EN,
    realEstate: realEstateTranslations.EN
  },
  KO: {
    ...servicesCommonTranslations.KO,
    incorporation: incorporationTranslations.KO,
    strategy: strategyTranslations.KO,
    recruitment: recruitmentTranslations.KO,
    taxation: taxationTranslations.KO,
    realEstate: realEstateTranslations.KO
  }
};