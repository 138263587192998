import { ServiceCardProps } from "@/types/service";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { CheckCircle2, Award } from "lucide-react";
import ServiceDetails from "./ServiceDetails";

const ServiceCard = ({ content, badge }: ServiceCardProps) => {
  return (
    <Card className="h-full bg-white transition-all duration-300 hover:shadow-xl">
      <CardHeader className="text-left">
        <div className="flex items-center gap-2 mb-3">
          <Badge variant="secondary" className="bg-primary/10 text-primary hover:bg-primary/20">
            <Award className="w-3 h-3 mr-1" />
            {badge}
          </Badge>
        </div>
        <CardTitle className="text-2xl">{content.title}</CardTitle>
        <CardDescription className="text-base mt-2">
          {content.description}
        </CardDescription>
      </CardHeader>
      <CardContent className="text-left">
        <div className="mb-6">
          <h4 className="font-semibold mb-3">Key Benefits:</h4>
          <ul className="space-y-2">
            {content.items.map((benefit, idx) => (
              <li key={idx} className="flex items-start gap-2">
                <CheckCircle2 className="w-5 h-5 text-primary mt-0.5 flex-shrink-0" />
                <span>{benefit}</span>
              </li>
            ))}
          </ul>
        </div>
        <ServiceDetails details={content.details} />
      </CardContent>
    </Card>
  );
};

export default ServiceCard;