
import { Share2, Facebook, Linkedin, Mail, Twitter } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface InsightShareMenuProps {
  onShare: (platform: string) => void;
}

const InsightShareMenu = ({ onShare }: InsightShareMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm" 
          aria-label="Share this article"
          className="h-8 w-8 p-0 text-gray-600 hover:text-primary min-w-[32px]"
        >
          <Share2 className="w-4 h-4 stroke-[1.5]" aria-hidden="true" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-48 bg-white">
        <DropdownMenuItem 
          onClick={() => onShare('twitter')} 
          className="hover:bg-gray-100"
        >
          <Twitter className="w-4 h-4 stroke-[1.5] mr-2" aria-hidden="true" /> 
          <span>Share on Twitter</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => onShare('facebook')} 
          className="hover:bg-gray-100"
        >
          <Facebook className="w-4 h-4 stroke-[1.5] mr-2" aria-hidden="true" /> 
          <span>Share on Facebook</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => onShare('linkedin')} 
          className="hover:bg-gray-100"
        >
          <Linkedin className="w-4 h-4 stroke-[1.5] mr-2" aria-hidden="true" /> 
          <span>Share on LinkedIn</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => onShare('email')} 
          className="hover:bg-gray-100"
        >
          <Mail className="w-4 h-4 stroke-[1.5] mr-2" aria-hidden="true" /> 
          <span>Share via Email</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default InsightShareMenu;
