
export const servicesCommonTranslations = {
  EN: {
    title: "One-Stop Solution for Your Business in India",
    subtitle: "We take care of everything so you can focus on growth"
  },
  KO: {
    title: "인도 시장 진출을 위한 원스톱 솔루션",
    subtitle: "인도 시장 진출에 대한 모든 스트레스를 날려드립니다"
  }
};
