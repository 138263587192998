export const expertsTranslations = {
  EN: {
    title: "Our Core Team",
    subtitle: "The driving force behind our exceptional service quality and rapid response",
    description: "Duru directly employs all necessary professionals for business management support, ensuring superior performance through systematic guidance under Korean managers.",
    experts: [
      {
        name: "Yongjin Ham",
        position: "Managing Director",
        bio: "Expert in company incorporation, setup, corporate compliance, accounting management, and recruitment"
      },
      {
        name: "Nizam Thampi",
        position: "Director",
        bio: "Specialist in ERP system development, IT network infrastructure, and data management"
      },
      {
        name: "Rahul Xavier",
        position: "Chief Operating Officer",
        bio: "Expert in HR, Indian recruitment, new business development, and government relations"
      },
      {
        name: "Hemanth Kumar",
        position: "Chartered Accountant",
        bio: "Specialist in company setup, corporate taxation, international finance, and business audits"
      },
      {
        name: "Abhishek Gaur",
        position: "Chartered Accountant",
        bio: "Expert in annual accounting, financial compliance, tax advisory, and investment management"
      }
    ]
  },
  KO: {
    title: "두루의 핵심 인력",
    subtitle: "최고의 서비스 품질과 신속한 대응을 가능하게 하는 원동력",
    description: "두루는 경영 지원 업무에 필요한 모든 전문가를 직접 고용하고, 한국인 관리자의 체계적인 지침 아래 업무를 수행함으로써 타사 대비 뛰어난 성과를 보장합니다.",
    experts: [
      {
        name: "함용진",
        position: "매니징 디렉터",
        bio: "법인 설립 및 셋업, 법인 규정 준수, 회계 관리, 인력채용 전문"
      },
      {
        name: "니잠 탐피",
        position: "디렉터",
        bio: "ERP 시스템 개발, IT 네트워크 구축, 데이터 관리 전문"
      },
      {
        name: "라훌 자비에르",
        position: "최고운영책임자",
        bio: "인사, 인도 인력 채용, 신규 사업 개발, 대관업무 전문"
      },
      {
        name: "헤만트 쿠마르",
        position: "공인회계사",
        bio: "법인 설립 및 셋업, 기업 과세, 국제 금융, 비즈니스 감사 전문"
      },
      {
        name: "아비셱 가우르",
        position: "공인회계사",
        bio: "연간 회계, 재무 규정 준수, 세무 자문, 투자금 관리 전문"
      }
    ]
  }
};