import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight, CheckCircle, Award, Users, Building, ShieldCheck } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/utils/translations";

interface WhyChooseDuruProps {
  language: string;
}

const WhyChooseDuru = ({ language }: WhyChooseDuruProps) => {
  const navigate = useNavigate();
  const t = useTranslation(language);

  const stats = [
    {
      icon: Building,
      number: "128+",
      label: t.about.whyChoose.stats.businesses
    },
    {
      icon: Users,
      number: "1,000+",
      label: t.about.whyChoose.stats.clients
    },
    {
      icon: Award,
      number: "1st",
      label: t.about.whyChoose.stats.experience
    },
    {
      icon: ShieldCheck,
      number: "50+",
      label: t.about.whyChoose.stats.recognitions
    }
  ];

  const solutions = t.about.whyChoose.solutions;

  return (
    <section className="py-12 bg-gradient-to-b from-[#0EA5E9]/10 to-[#0EA5E9]/5">
      <div className="container mx-auto px-4">
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          {/* Left Column - Content */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="space-y-6"
          >
            <div>
              <h2 className="text-3xl font-bold text-neutral-dark mb-2">
                {t.about.whyChoose.title}
              </h2>
              <p className="text-lg text-neutral-600">
                {t.about.whyChoose.subtitle}
              </p>
            </div>

            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-semibold text-primary mb-3">
                  {t.about.whyChoose.solutionsTitle}
                </h3>
                <ul className="space-y-2">
                  {solutions.map((solution, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: index * 0.1 }}
                      className="flex items-start gap-2"
                    >
                      <CheckCircle className="w-5 h-5 text-secondary flex-shrink-0 mt-0.5" />
                      <span className="text-sm text-neutral-600">{solution}</span>
                    </motion.li>
                  ))}
                </ul>
              </div>
            </div>

            <Button 
              size="sm"
              className="group bg-secondary hover:bg-secondary-light text-white shadow-md hover:shadow-lg transition-all duration-300"
              onClick={() => navigate('/contact')}
            >
              {t.about.whyChoose.cta}
              <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>

          {/* Right Column - Stats */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="grid grid-cols-2 gap-4"
          >
            {stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="p-6 rounded-lg bg-white/10 backdrop-blur-sm border border-white/20 shadow-xl hover:shadow-2xl transition-all duration-300 hover:bg-white/15 group"
                >
                  <Icon className="w-6 h-6 text-primary mb-2 group-hover:scale-110 transition-transform" />
                  <div className="text-2xl font-bold text-primary mb-1 group-hover:text-primary-light transition-colors">
                    {stat.number}
                  </div>
                  <div className="text-xs text-neutral-600 group-hover:text-neutral-500 transition-colors">
                    {stat.label}
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseDuru;