
import { Image } from 'lucide-react';
import { useState } from 'react';
import { Editor } from '@tiptap/react';
import EditorToolbarButton from '../../EditorToolbarButton';
import ImageUploadDialog from '@/components/insights/editor/ImageUploadDialog';

interface ImageUploadProps {
  editor: Editor;
  insightId?: string;
}

export const ImageUpload = ({ editor, insightId }: ImageUploadProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleImageUploaded = (imageUrl: string, metadata: { title: string; caption: string; altText: string }) => {
    editor.chain().focus().setImage({ 
      src: imageUrl,
      alt: metadata.altText,
      title: metadata.title,
    }).run();
  };

  return (
    <>
      <EditorToolbarButton
        onClick={() => setIsDialogOpen(true)}
        icon={Image}
      />
      <ImageUploadDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onImageUploaded={handleImageUploaded}
        insightId={insightId}
      />
    </>
  );
};
