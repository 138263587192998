import AboutHeader from "@/components/about/AboutHeader";
import CeoMessage from "@/components/about/CeoMessage";
import WhyChooseDuru from "@/components/about/WhyChooseDuru";
import CoreValues from "@/components/about/CoreValues";
import ExpertsSection from "@/components/about/ExpertsSection";
import FeaturedEvents from "@/components/about/FeaturedEvents";
import CallToAction from "@/components/about/CallToAction";
import Footer from "@/components/Footer";

interface AboutProps {
  language: string;
}

const About = ({ language }: AboutProps) => {
  return (
    <main className="min-h-screen flex flex-col">
      <div className="flex flex-col">
        <AboutHeader language={language} />
        <CoreValues language={language} />
        <CeoMessage language={language} />
        <WhyChooseDuru language={language} />
        <ExpertsSection language={language} />
        <FeaturedEvents language={language} />
        <CallToAction language={language} />
      </div>
      <Footer language={language} />
    </main>
  );
};

export default About;