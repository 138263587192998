
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Save, Send, Trash, Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';

interface ActionButtonsProps {
  status: 'draft' | 'published' | 'unpublished';
  isSaving: boolean;
  onSave: (action: 'draft' | 'publish' | 'unpublish' | 'delete') => Promise<void>;
}

const ActionButtons = ({ status, isSaving, onSave }: ActionButtonsProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onSave('delete');
    } finally {
      setIsDeleting(false);
    }
  };

  const renderButtons = () => {
    switch (status) {
      case 'draft':
        return (
          <>
            <Button
              onClick={() => onSave('draft')}
              variant="outline"
              disabled={isSaving}
              className="flex items-center gap-1.5 text-sm"
              size="sm"
            >
              <Save className="w-3.5 h-3.5" />
              Save Draft
            </Button>
            <Button
              onClick={() => onSave('publish')}
              disabled={isSaving}
              className="flex items-center gap-1.5 bg-[#F97316] hover:bg-[#EA580C] text-sm"
              size="sm"
            >
              <Send className="w-3.5 h-3.5" />
              Publish
            </Button>
          </>
        );

      case 'published':
        return (
          <>
            <Button
              onClick={() => onSave('draft')}
              variant="outline"
              disabled={isSaving}
              className="flex items-center gap-1.5 text-sm"
              size="sm"
            >
              <Save className="w-3.5 h-3.5" />
              Save Changes
            </Button>
            <Button
              onClick={() => onSave('unpublish')}
              variant="secondary"
              disabled={isSaving}
              className="flex items-center gap-1.5 text-sm"
              size="sm"
            >
              <EyeOff className="w-3.5 h-3.5" />
              Unpublish
            </Button>
          </>
        );

      case 'unpublished':
        return (
          <>
            <Button
              onClick={() => onSave('draft')}
              variant="outline"
              disabled={isSaving}
              className="flex items-center gap-1.5 text-sm"
              size="sm"
            >
              <Save className="w-3.5 h-3.5" />
              Save Changes
            </Button>
            <Button
              onClick={() => onSave('publish')}
              disabled={isSaving}
              className="flex items-center gap-1.5 bg-[#F97316] hover:bg-[#EA580C] text-sm"
              size="sm"
            >
              <Eye className="w-3.5 h-3.5" />
              Republish
            </Button>
          </>
        );
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 px-8 py-4 z-50 shadow-lg">
      <div className="max-w-[300px] ml-auto flex justify-end gap-3">
        {renderButtons()}
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="destructive"
              size="sm"
              className="flex items-center gap-1.5 text-sm"
              disabled={isSaving || isDeleting}
            >
              <Trash className="w-3.5 h-3.5" />
              Delete
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the insight.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};

export default ActionButtons;
