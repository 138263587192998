import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Linkedin } from "lucide-react";
import { motion } from "framer-motion";
import { useTranslation } from "@/utils/translations";

interface ExpertsSectionProps {
  language: string;
}

const ExpertsSection = ({ language }: ExpertsSectionProps) => {
  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const t = useTranslation(language);

  const experts = [
    {
      name: language === "KO" ? "함용진" : "Yongjin Ham",
      position: t.about.experts.experts[0].position,
      bio: t.about.experts.experts[0].bio,
      linkedIn: "https://www.linkedin.com/in/yongjin-ham/",
      image: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/yongjin_ham.avif"
    },
    {
      name: language === "KO" ? "니잠 탐피" : "Nizam Thampi",
      position: t.about.experts.experts[1].position,
      bio: t.about.experts.experts[1].bio,
      linkedIn: "https://www.linkedin.com/in/nizam-thampi/",
      image: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/nizam_thampi.avif"
    },
    {
      name: language === "KO" ? "라훌 자비에르" : "Rahul Xavier",
      position: t.about.experts.experts[2].position,
      bio: t.about.experts.experts[2].bio,
      linkedIn: "https://www.linkedin.com/in/rahul-xavier/",
      image: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/rahul_xavier.avif"
    },
    {
      name: language === "KO" ? "헤만트 쿠마르" : "Hemanth Kumar",
      position: t.about.experts.experts[3].position,
      bio: t.about.experts.experts[3].bio,
      linkedIn: "https://www.linkedin.com/in/hemanthkumarjain/",
      image: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/hemant_kumar.avif"
    },
    {
      name: language === "KO" ? "아비셱 가우르" : "Abhishek Gaur",
      position: t.about.experts.experts[4].position,
      bio: t.about.experts.experts[4].bio,
      linkedIn: "https://www.linkedin.com/in/ca-abhishek-gaur-11bb5b94/",
      image: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/abhishek_gaur.avif"
    }
  ];

  return (
    <section id="experts-section" className="py-20 bg-gradient-to-b from-neutral-900 to-neutral-800">
      {/* Section Header */}
      <div className="text-center max-w-3xl mx-auto mb-16 space-y-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold text-white"
        >
          {t.about.experts.title}
        </motion.h2>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2 }}
          className="space-y-4"
        >
          <p className="text-lg text-neutral-200">
            {t.about.experts.subtitle}
          </p>
          <p className="text-neutral-300">
            {t.about.experts.description}
          </p>
        </motion.div>
      </div>

      {/* Experts Grid */}
      <div className="container px-4 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
          {experts.map((expert, index) => (
            <motion.div
              key={expert.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              onHoverStart={() => setHoveredId(index)}
              onHoverEnd={() => setHoveredId(null)}
            >
              <Card className="h-full overflow-hidden group transition-all duration-300 hover:shadow-2xl hover:shadow-secondary/20 bg-neutral-800/50 backdrop-blur-sm border-neutral-700">
                <div className="relative aspect-[4/3]">
                  <img
                    src={expert.image}
                    alt={expert.name}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  {/* Overlay on hover */}
                  <div className={`absolute inset-0 bg-black/60 flex items-center justify-center transition-opacity duration-300 ${hoveredId === index ? 'opacity-100' : 'opacity-0'}`}>
                    <a
                      href={expert.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 bg-secondary rounded-full hover:bg-secondary-light transition-colors duration-300"
                    >
                      <Linkedin className="w-6 h-6 text-white" />
                    </a>
                  </div>
                </div>
                <div className="p-4 space-y-2">
                  <h3 className="text-lg font-semibold text-white">{expert.name}</h3>
                  <p className="text-secondary font-medium text-sm">{expert.position}</p>
                  <p className="text-neutral-300 text-xs">{expert.bio}</p>
                </div>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertsSection;