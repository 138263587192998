
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import { FileAttachment } from './extensions/FileAttachment';
import SlashCommands from './extensions/SlashCommands';
import { editorStyles } from './styles/editorStyles';

export const getExtensions = (currentLanguage: 'en' | 'ko' = 'en') => [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
    heading: {
      levels: [1, 2, 3],
    },
  }),
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: 'text-primary underline hover:text-primary/80 transition-colors',
    },
    validate: href => /^https?:\/\//.test(href),
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableCell,
  TableHeader,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'center', 'right'],
    defaultAlignment: 'left',
  }),
  Image.configure({
    allowBase64: true,
    inline: true,
  }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === 'heading') {
        return currentLanguage === 'en' 
          ? 'Enter heading...'
          : '제목을 입력하세요...';
      }
      if (node.type.name === 'paragraph') {
        return currentLanguage === 'en' 
          ? 'Press Enter for new line, type / for commands, or start writing...'
          : 'Enter 키를 눌러 새 줄을 만들거나, /를 입력하여 명령어를 사용하거나 작성을 시작하세요...';
      }
      return '';
    },
    showOnlyWhenEditable: true,
    includeChildren: false,
  }),
  FileAttachment,
  BubbleMenu,
  SlashCommands,
];

export { editorStyles };
