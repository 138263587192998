import HeroMainContent from "./hero/HeroMainContent";
import ScrollingTrustBanner from "./hero/ScrollingTrustBanner";
import { AnimatedGridPattern } from "./ui/animated-grid-pattern";

interface HeroProps {
  language: string;
}

const Hero = ({ language }: HeroProps) => {
  return (
    <div className="relative w-full min-h-[100dvh] bg-neutral">
      <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-secondary/5" />
      <AnimatedGridPattern
        numSquares={30}
        maxOpacity={0.03}
        duration={3}
        repeatDelay={1}
        className="[mask-image:radial-gradient(800px_circle_at_center,white,transparent)]"
      />
      
      <div className="relative z-10 container mx-auto px-4">
        <div className="min-h-[100dvh] flex items-center">
          <div className="w-full py-24 md:py-16">
            <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
              <div className="flex flex-col justify-center text-center lg:text-left mt-8 md:mt-12 lg:mt-0">
                <HeroMainContent language={language} />
              </div>
              <div className="flex justify-center lg:justify-end">
                <ScrollingTrustBanner language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;