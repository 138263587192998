import { motion } from "framer-motion";
import { useTranslation } from "@/utils/translations";
import { Badge } from "@/components/ui/badge";

interface AboutHeaderProps {
  language: string;
}

const AboutHeader = ({ language }: AboutHeaderProps) => {
  const t = useTranslation(language);

  return (
    <section className="bg-white">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 items-center gap-8 min-h-[600px]">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="flex flex-col justify-center max-w-xl py-20 pr-8 space-y-8"
          >
            <Badge variant="secondary" className="w-fit">
              About Us
            </Badge>
            <h1 className="text-4xl md:text-5xl font-bold text-neutral-dark">
              {t.about.header.title}
            </h1>
            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              {t.about.header.description}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="h-full bg-white pt-20"
          >
            <div className="h-full relative bg-white">
              <img
                src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/banners/about_banner_image.webp"
                alt="Duru Compliance Office"
                className="w-full h-[500px] object-contain bg-white mix-blend-multiply"
                style={{ backgroundColor: 'white' }}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;