
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import type { Insight } from "@/types/insight";
import { useToast } from "@/hooks/use-toast";
import { format } from "date-fns";
import { calculateReadingTime } from "@/utils/readingTime";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import InsightHeader from "@/components/insights/InsightHeader";
import InsightContent from "@/components/insights/InsightContent";
import InsightFooter from "@/components/insights/InsightFooter";
import SuggestedInsights from "@/components/insights/SuggestedInsights";

interface InsightDetailProps {
  language: string;
}

const InsightDetail = ({ language }: InsightDetailProps) => {
  const { slug } = useParams();
  const { toast } = useToast();
  const [isLiked, setIsLiked] = useState(false);

  const { data: insight, isLoading } = useQuery({
    queryKey: ["insight", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("insights")
        .select("*")
        .eq("slug", slug)
        .single();

      if (error) throw error;
      
      console.log('Raw insight content:', language === 'EN' ? data?.content_en : data?.content_ko);
      
      return data as Insight;
    },
  });

  useEffect(() => {
    const incrementViewCount = async () => {
      if (!insight) return;
      
      const { error } = await supabase
        .from("insights")
        .update({ views_count: (insight.views_count || 0) + 1 })
        .eq("id", insight.id);

      if (error) console.error("Error incrementing view count:", error);
    };

    incrementViewCount();
  }, [insight]);

  const handleLike = async () => {
    if (!insight) return;

    try {
      const newLikesCount = isLiked ? (insight.likes_count || 0) - 1 : (insight.likes_count || 0) + 1;
      
      const { error } = await supabase
        .from("insights")
        .update({ likes_count: newLikesCount })
        .eq("id", insight.id);

      if (error) throw error;

      setIsLiked(!isLiked);
      
      toast({
        title: isLiked ? "Removed from likes" : "Added to likes",
        description: isLiked ? "Article removed from your likes" : "Article added to your likes",
      });
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleShare = (platform: string) => {
    const url = window.location.href;
    const title = language === 'EN' ? insight?.title_en || "" : insight?.title_ko || "";
    
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
      email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`Check out this insight: ${url}`)}`
    };

    window.open(shareUrls[platform as keyof typeof shareUrls], "_blank");
  };

  if (isLoading) {
    return (
      <div className="min-h-screen pt-24 flex items-center justify-center">
        <div className="animate-pulse space-y-4">
          <div className="h-4 w-48 bg-gray-200 rounded"></div>
          <div className="h-4 w-64 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  if (!insight) {
    return (
      <div className="min-h-screen pt-24 flex items-center justify-center">
        <p className="text-gray-500">Insight not found</p>
      </div>
    );
  }

  const readingTime = calculateReadingTime(language === 'EN' ? insight.content_en || '' : insight.content_ko || '');

  return (
    <main className="min-h-screen bg-white print:bg-white print:!pt-0">
      <div className="max-w-4xl mx-auto px-4">
        <div className="pt-20 pb-4 print:!pt-0">
          <div className="flex flex-col space-y-6">
            <Breadcrumb className="print:hidden">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to="/">Home</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to="/insights">Insights</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>{language === 'EN' ? insight?.title_en : insight?.title_ko}</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>

            <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 leading-tight py-2 print:!mt-0">
              {language === 'EN' ? insight?.title_en : insight?.title_ko}
            </h1>
            
            <div className="flex items-center gap-4 text-sm text-gray-600">
              {insight.published_at && (
                <time dateTime={insight.published_at}>
                  {format(new Date(insight.published_at), "MMMM d, yyyy")}
                </time>
              )}
              <span>·</span>
              <span>{readingTime} min read</span>
            </div>
          </div>
        </div>

        <article className="py-2 print:!mt-0">
          <InsightHeader
            views_count={insight?.views_count}
            likes_count={insight?.likes_count}
            isLiked={isLiked}
            onLike={handleLike}
            onShare={handleShare}
          />

          {insight && (
            <div className="mt-8">
              <InsightContent 
                content={language === 'EN' ? insight.content_en || '' : insight.content_ko || ''} 
              />
            </div>
          )}

          <InsightFooter tags={insight?.tags || []} />
        </article>
      </div>

      <section className="max-w-4xl mx-auto px-4 py-12 border-t border-gray-100 print:hidden">
        <h2 className="text-2xl font-bold mb-8">Suggested Insights</h2>
        <SuggestedInsights currentInsightId={insight?.id || ''} />
      </section>
    </main>
  );
};

export default InsightDetail;

