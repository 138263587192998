
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { EditButton, DeleteButton } from '@/components/admin/AdminButtonStyles';
import { Logo } from "../../../types/logo";

interface LogoTableProps {
  logos: Logo[];
  onToggleActive: (logo: Logo) => void;
  onDelete: (id: string) => void;
}

const LogoTable = ({ logos, onToggleActive, onDelete }: LogoTableProps) => {
  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Logo</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {logos.map((logo) => (
            <TableRow key={logo.id}>
              <TableCell>
                <img
                  src={logo.logo_url}
                  alt={logo.name}
                  className="h-12 w-12 object-contain"
                />
              </TableCell>
              <TableCell>{logo.name}</TableCell>
              <TableCell>{logo.type}</TableCell>
              <TableCell>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    logo.is_active
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {logo.is_active ? "Active" : "Inactive"}
                </span>
              </TableCell>
              <TableCell>
                <div className="flex gap-2">
                  <EditButton onClick={() => onToggleActive(logo)} />
                  <DeleteButton onClick={() => onDelete(logo.id)} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default LogoTable;
