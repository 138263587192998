
import { supabase } from '@/integrations/supabase/client';

export const deleteInsightImages = async (insightId: string) => {
  // 1. Get all associated images to delete from storage
  const { data: insightImages } = await supabase
    .from('insight_images')
    .select('image_url')
    .eq('insight_id', insightId);

  // Get thumbnail URL from the insight itself
  const { data: insight } = await supabase
    .from('insights')
    .select('thumbnail_url')
    .eq('id', insightId)
    .single();

  // 2. Delete all associated images from storage
  if (insightImages && insightImages.length > 0) {
    const storageUrls = insightImages
      .map(img => {
        try {
          const url = new URL(img.image_url);
          return url.pathname.split('/').pop();
        } catch (e) {
          console.error('Invalid URL:', img.image_url);
          return null;
        }
      })
      .filter(Boolean);

    if (storageUrls.length > 0) {
      const { error: storageError } = await supabase.storage
        .from('insight_attachments')
        .remove(storageUrls);

      if (storageError) {
        console.error('Error deleting images from storage:', storageError);
      }
    }
  }

  // Delete thumbnail if it exists
  if (insight?.thumbnail_url) {
    try {
      const thumbnailUrl = new URL(insight.thumbnail_url);
      const thumbnailPath = thumbnailUrl.pathname.split('/').pop();
      if (thumbnailPath) {
        await supabase.storage
          .from('insight_attachments')
          .remove([thumbnailPath]);
      }
    } catch (e) {
      console.error('Error deleting thumbnail:', e);
    }
  }

  // 3. Delete associated images from the database
  const { error: imageDeleteError } = await supabase
    .from('insight_images')
    .delete()
    .eq('insight_id', insightId);

  if (imageDeleteError) {
    throw imageDeleteError;
  }
};

export const updateTemporaryImages = async (insightId: string) => {
  const { error: updateError } = await supabase
    .from('insight_images')
    .update({ is_temporary: false })
    .match({ insight_id: insightId, is_temporary: true });

  if (updateError) {
    console.error('Error updating temporary images:', updateError);
  }
};
