interface InsightFooterProps {
  tags: string[];
}

const InsightFooter = ({ tags }: InsightFooterProps) => {
  return (
    <footer className="mt-16 pt-8 border-t border-gray-100">
      <div className="flex flex-wrap gap-2">
        {tags.map((tag) => (
          <span
            key={tag}
            className="px-3 py-1 bg-gray-100 text-gray-700 text-sm rounded-full"
          >
            {tag}
          </span>
        ))}
      </div>
    </footer>
  );
};

export default InsightFooter;