import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import LanguageSelector from "@/components/navbar/LanguageSelector";
import NavLinks from "@/components/navbar/NavLinks";
import { useTranslation } from '@/utils/translations';

interface MobileMenuProps {
  isOpen: boolean;
  language: string;
  onLanguageChange: (lang: string) => void;
  onClose: () => void;
}

const MobileMenu = ({ isOpen, language, onLanguageChange, onClose }: MobileMenuProps) => {
  const t = useTranslation(language);

  if (!isOpen) return null;

  return (
    <div className="md:hidden fixed top-16 inset-x-0 w-screen bg-white shadow-lg">
      <div className="px-4 pt-2 pb-3 space-y-1">
        <NavLinks 
          isActive={() => false} 
          isMobile 
          onMobileClick={onClose} 
          language={language}
          textColor="text-gray-600"
        />
        <LanguageSelector 
          language={language} 
          onLanguageChange={(lang) => {
            onLanguageChange(lang);
            onClose();
          }}
          isMobile 
        />
        <div className="py-2">
          <Link to="/contact" onClick={onClose}>
            <Button variant="default" className="w-full bg-primary hover:bg-primary/90 text-white">
              {t.navbar.contactUs}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;