import React from 'react';
import { TabsContent } from "@/components/ui/tabs";
import LogoGrid from './LogoGrid';

interface TabContentProps {
  value: string;
  logos: any[];
  emptyMessage: string;
}

const TabContent = ({ value, logos, emptyMessage }: TabContentProps) => {
  return (
    <TabsContent value={value} className="mt-8">
      {logos.length > 0 ? (
        <LogoGrid logos={logos} />
      ) : (
        <p className="text-center text-neutral-600">{emptyMessage}</p>
      )}
    </TabsContent>
  );
};

export default TabContent;