
import { useEffect } from "react";
import LogoUploadModal from "@/components/LogoUploadModal";
import LogoTable from "./logo/LogoTable";
import { useLogoManager } from "./logo/useLogoManager";

const LogoManager = () => {
  const { logos, handleLogoUpload, handleToggleActive, handleDeleteLogo, fetchLogos } = useLogoManager();

  useEffect(() => {
    fetchLogos();
  }, []);

  return (
    <>
      <div className="mb-6">
        <LogoUploadModal onUpload={handleLogoUpload} />
      </div>
      <LogoTable 
        logos={logos}
        onToggleActive={handleToggleActive}
        onDelete={handleDeleteLogo}
      />
    </>
  );
};

export default LogoManager;
