import { motion } from "framer-motion";
import { Award } from "lucide-react";
import { cn } from "@/lib/utils";

interface ScrollingTrustBannerProps {
  language: string;
}

const achievements = [
  {
    title: "Global Business Partner",
    organization: "Small & Medium Business Administration",
    year: "Since 2022",
    logo: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/trust_banner/kr_smba_logo.gif"
  },
  {
    title: "Global ICT Portal Local Expert",
    organization: "National IT Industry Promotion Agency",
    year: "Since 2021",
    logo: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/trust_banner/nipa_logo.png"
  },
  {
    title: "Global Regional Expert",
    organization: "Korea Trade-Investment Promotion Agency",
    year: "Since 2019",
    logo: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/trust_banner/kotra_logo.png"
  },
  {
    title: "Recruiting Agent",
    organization: "Ministry of External Affairs, Gov't of India",
    year: "Since 2019",
    logo: "https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/trust_banner/Ministry_of_External_Affairs_India.svg.png"
  }
];

const ScrollingTrustBanner = ({ language }: ScrollingTrustBannerProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="lg:justify-self-end w-full max-w-lg flex items-center"
    >
      <div className="relative overflow-hidden bg-gradient-to-br from-secondary/90 to-primary rounded-xl p-4 shadow-xl border-none w-full h-[460px]">
        <div className="absolute top-0 right-0 w-40 h-40 bg-secondary/20 rounded-full mix-blend-overlay filter blur-xl opacity-70 -mr-16 -mt-16" />
        <div className="absolute bottom-0 left-0 w-40 h-40 bg-primary/20 rounded-full mix-blend-overlay filter blur-xl opacity-70 -ml-16 -mb-16" />
        
        <div className="relative h-full flex flex-col">
          <motion.div 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            className="text-center mb-3"
          >
            <div className="flex items-center justify-center gap-1.5 mb-1.5">
              <Award className="w-5 h-5 text-white animate-pulse" />
              <h2 className="text-lg md:text-xl font-bold text-white">
                {language === "KO" ? "신뢰할 수 있는 전문성" : "Trusted Excellence"}
              </h2>
            </div>
            <div className="w-10 h-0.5 bg-white/80 mx-auto mb-2" />
            <p className="text-white/80 text-xs md:text-sm">
              {language === "KO" 
                ? "2018년부터 128개 이상의 기업의 인도 시장 진출을 지원했습니다"
                : "Empowering 128+ companies in their Indian market journey since 2018"}
            </p>
          </motion.div>

          <div className="relative flex-1 overflow-hidden">
            {/* Top fade effect */}
            <div className="absolute top-0 left-0 right-0 h-12 bg-gradient-to-b from-[#000B45]/50 to-transparent z-10" />
            
            <div className="absolute inset-0 flex flex-col gap-2 animate-scroll-up">
              {[...achievements, ...achievements].map((item, index) => (
                <ScrollingCard key={`card-${index}`} {...item} index={index} />
              ))}
            </div>

            {/* Bottom fade effect */}
            <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-[#000B45]/50 to-transparent z-10" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

interface ScrollingCardProps {
  title: string;
  organization: string;
  year: string;
  logo: string;
  index: number;
}

const ScrollingCard = ({ title, organization, year, logo, index }: ScrollingCardProps) => {
  return (
    <div className={cn(
      "flex flex-col md:flex-row items-center gap-2 md:gap-3 p-2.5 rounded-lg transition-all duration-300",
      "hover:transform hover:scale-[1.02] bg-black/30 hover:bg-black/40 backdrop-blur-sm"
    )}>
      <div className="w-16 md:w-20 h-12 md:h-16 bg-white rounded-lg shadow-md p-2 flex items-center justify-center shrink-0">
        <img 
          src={logo} 
          alt={`${organization} logo`}
          className="max-w-full max-h-full object-contain"
        />
      </div>
      <div className="flex-grow text-center md:text-left">
        <h3 className="font-semibold text-white text-sm mb-1">
          {title}
        </h3>
        <p className="text-white/90 text-xs md:text-sm mb-0.5 leading-relaxed">
          {organization}
        </p>
        <p className="text-secondary-light text-xs font-medium">
          {year}
        </p>
      </div>
    </div>
  );
};

export default ScrollingTrustBanner;
