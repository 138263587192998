
import { MessageSquare, Tag, Text, X } from 'lucide-react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Badge } from '@/components/ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface SeoSectionProps {
  isEnglish: boolean;
  metaDescription: string;
  setMetaDescription: (value: string) => void;
  newKeyword: string;
  setNewKeyword: (value: string) => void;
  handleAddKeyword: (e: React.KeyboardEvent) => void;
  metaKeywords: string[];
  handleRemoveKeyword: (keyword: string) => void;
}

const SeoSection = ({
  isEnglish,
  metaDescription = '', // Provide default value
  setMetaDescription,
  newKeyword = '', // Provide default value
  setNewKeyword,
  handleAddKeyword,
  metaKeywords = [], // Provide default value
  handleRemoveKeyword,
}: SeoSectionProps) => {
  return (
    <TooltipProvider>
      <div className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Label className="flex items-center gap-2">
              <MessageSquare className="w-4 h-4 text-gray-500" />
              {isEnglish ? "SEO Description" : "SEO 설명"}
            </Label>
            <Tooltip>
              <TooltipTrigger className="cursor-help">
                <Text className="w-4 h-4 text-gray-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p>{isEnglish 
                  ? "Should be 150-160 characters long. Write a compelling summary that accurately describes the content."
                  : "150-160자 정도가 적당합니다. 콘텐츠를 정확하게 설명하는 매력적인 요약을 작성하세요."}</p>
              </TooltipContent>
            </Tooltip>
          </div>
          <Textarea
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            placeholder={isEnglish ? "Enter meta description for SEO" : "SEO를 위한 메타 설명 입력"}
            className="min-h-[80px] border-gray-200 focus:border-gray-300 focus:ring-gray-200"
            maxLength={160}
          />
          <div className="text-sm text-gray-500 text-right">
            {metaDescription?.length || 0}/160 characters
          </div>
        </div>

        <div className="space-y-2">
          <Label className="flex items-center gap-2">
            <Tag className="w-4 h-4 text-gray-500" />
            {isEnglish ? "SEO Keywords" : "SEO 키워드"}
          </Label>
          <div className="space-y-3">
            <Input
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              onKeyDown={handleAddKeyword}
              placeholder={isEnglish ? "Type and press Enter to add keywords" : "키워드를 입력하고 Enter를 누르세요"}
              className="border-gray-200 focus:border-gray-300 focus:ring-gray-200"
            />
            <div className="flex flex-wrap gap-2">
              {metaKeywords?.map((keyword) => (
                <Badge key={keyword} variant="outline" className="flex items-center gap-1 px-3 py-1">
                  {keyword}
                  <X
                    className="h-3 w-3 cursor-pointer hover:text-destructive transition-colors"
                    onClick={() => handleRemoveKeyword(keyword)}
                  />
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default SeoSection;
