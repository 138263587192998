
import { Editor } from '@tiptap/react';
import { 
  ArrowDown, 
  ArrowUp, 
  Trash2, 
  Table as TableIcon,
  ArrowLeftToLine,
  ArrowRightToLine,
  Columns,
  X
} from 'lucide-react';
import EditorToolbarButton from '../EditorToolbarButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useState } from 'react';

interface TableControlsProps {
  editor: Editor;
}

const TableControls = ({ editor }: TableControlsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const addTable = () => {
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
    setIsOpen(false);
  };

  const addRowBefore = () => {
    editor.chain().focus().addRowBefore().run();
    setIsOpen(false);
  };

  const addRowAfter = () => {
    editor.chain().focus().addRowAfter().run();
    setIsOpen(false);
  };

  const deleteRow = () => {
    editor.chain().focus().deleteRow().run();
    setIsOpen(false);
  };

  const addColumnBefore = () => {
    editor.chain().focus().addColumnBefore().run();
    setIsOpen(false);
  };

  const addColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run();
    setIsOpen(false);
  };

  const deleteColumn = () => {
    editor.chain().focus().deleteColumn().run();
    setIsOpen(false);
  };

  const deleteTable = () => {
    editor.chain().focus().deleteTable().run();
    setIsOpen(false);
  };

  const isTableSelected = editor.isActive('table');

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <div>
          <EditorToolbarButton
            onClick={() => {}}
            icon={TableIcon}
            isActive={isTableSelected}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        className="w-48 bg-white border border-gray-200 shadow-lg rounded-md z-[100]"
        onCloseAutoFocus={(e) => {
          e.preventDefault();
          editor.commands.focus();
        }}
      >
        <DropdownMenuGroup>
          {!isTableSelected && (
            <DropdownMenuItem 
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
              onClick={addTable}
              onSelect={(e) => {
                e.preventDefault();
                addTable();
              }}
            >
              <TableIcon className="mr-2 h-4 w-4" />
              Insert Table
            </DropdownMenuItem>
          )}
          {isTableSelected && (
            <>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  addRowBefore();
                }}
              >
                <ArrowUp className="mr-2 h-4 w-4" />
                Add Row Before
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  addRowAfter();
                }}
              >
                <ArrowDown className="mr-2 h-4 w-4" />
                Add Row After
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  deleteRow();
                }}
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete Row
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  addColumnBefore();
                }}
              >
                <ArrowLeftToLine className="mr-2 h-4 w-4" />
                Add Column Before
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  addColumnAfter();
                }}
              >
                <ArrowRightToLine className="mr-2 h-4 w-4" />
                Add Column After
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  deleteColumn();
                }}
              >
                <Columns className="mr-2 h-4 w-4" />
                Delete Column
              </DropdownMenuItem>
              <DropdownMenuItem 
                className="px-3 py-2 hover:bg-gray-100 text-red-600 cursor-pointer" 
                onSelect={(e) => {
                  e.preventDefault();
                  deleteTable();
                }}
              >
                <X className="mr-2 h-4 w-4" />
                Delete Table
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TableControls;
