import { Editor } from '@tiptap/react';
import { Heading1, Heading2, Heading3 } from 'lucide-react';
import EditorToolbarButton from '../EditorToolbarButton';

interface HeadingControlsProps {
  editor: Editor;
}

const HeadingControls = ({ editor }: HeadingControlsProps) => {
  return (
    <>
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        isActive={editor.isActive('heading', { level: 1 })}
        icon={Heading1}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        isActive={editor.isActive('heading', { level: 2 })}
        icon={Heading2}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        isActive={editor.isActive('heading', { level: 3 })}
        icon={Heading3}
      />
    </>
  );
};

export default HeadingControls;