
import { Label } from '@/components/ui/label';
import { ThumbnailUpload } from '../ThumbnailUpload';
import { Image } from 'lucide-react';

interface MetadataSidebarProps {
  thumbnailUrl: string;
  setThumbnailUrl: (url: string) => void;
}

const MetadataSidebar = ({
  thumbnailUrl,
  setThumbnailUrl,
}: MetadataSidebarProps) => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-full">
        <Label className="sr-only">Featured Image</Label>
        <ThumbnailUpload
          currentThumbnail={thumbnailUrl}
          onThumbnailChange={setThumbnailUrl}
        />
      </div>
    </div>
  );
};

export default MetadataSidebar;
