
import { Editor } from '@tiptap/react';
import { ImageUpload } from './media/ImageUpload';
import { FileUpload } from './media/FileUpload';

interface MediaControlsProps {
  editor: Editor;
  insightId?: string;
}

const MediaControls = ({ editor, insightId }: MediaControlsProps) => {
  return (
    <div className="flex items-center gap-1">
      <ImageUpload editor={editor} insightId={insightId} />
      <FileUpload editor={editor} insightId={insightId} />
    </div>
  );
};

export default MediaControls;
