import { BubbleMenu, Editor } from '@tiptap/react';
import { 
  Bold, 
  Italic, 
  List, 
  ListOrdered,
  ChevronDown,
  ChevronUp,
  Heading1,
  Heading2,
  Heading3,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Table,
  Image,
  FileText
} from 'lucide-react';
import { useState } from 'react';
import EditorToolbarButton from './EditorToolbarButton';
import { cn } from '@/lib/utils';
import { ImageUpload } from './toolbar/media/ImageUpload';
import { FileUpload } from './toolbar/media/FileUpload';

interface BubbleToolbarProps {
  editor: Editor;
}

const BubbleToolbar = ({ editor }: BubbleToolbarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const addTable = () => {
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  };

  return (
    <BubbleMenu 
      editor={editor}
      tippyOptions={{ 
        duration: 100,
        placement: 'top',
      }}
      className={cn(
        "flex items-center gap-1 bg-white rounded-lg shadow-lg border border-gray-200 p-1 transition-all",
        isExpanded ? "flex-col" : "flex-row"
      )}
    >
      {/* Basic Formatting - Always visible */}
      <div className="flex items-center gap-1">
        <EditorToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive('bold')}
          icon={Bold}
        />
        <EditorToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive('italic')}
          icon={Italic}
        />
      </div>

      {isExpanded && (
        <>
          {/* Lists */}
          <div className="flex items-center gap-1 border-t border-gray-200 pt-1 mt-1">
            <EditorToolbarButton
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive('bulletList')}
              icon={List}
            />
            <EditorToolbarButton
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              isActive={editor.isActive('orderedList')}
              icon={ListOrdered}
            />
          </div>

          {/* Headings */}
          <div className="flex items-center gap-1 border-t border-gray-200 pt-1 mt-1">
            <EditorToolbarButton
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              isActive={editor.isActive('heading', { level: 1 })}
              icon={Heading1}
            />
            <EditorToolbarButton
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              isActive={editor.isActive('heading', { level: 2 })}
              icon={Heading2}
            />
            <EditorToolbarButton
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              isActive={editor.isActive('heading', { level: 3 })}
              icon={Heading3}
            />
          </div>

          {/* Alignment */}
          <div className="flex items-center gap-1 border-t border-gray-200 pt-1 mt-1">
            <EditorToolbarButton
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              isActive={editor.isActive({ textAlign: 'left' })}
              icon={AlignLeft}
            />
            <EditorToolbarButton
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              isActive={editor.isActive({ textAlign: 'center' })}
              icon={AlignCenter}
            />
            <EditorToolbarButton
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              isActive={editor.isActive({ textAlign: 'right' })}
              icon={AlignRight}
            />
          </div>

          {/* Table and Media */}
          <div className="flex items-center gap-1 border-t border-gray-200 pt-1 mt-1">
            <EditorToolbarButton
              onClick={addTable}
              isActive={editor.isActive('table')}
              icon={Table}
            />
            <ImageUpload editor={editor} />
            <FileUpload editor={editor} />
          </div>
        </>
      )}

      {/* Expand/Collapse Button */}
      <EditorToolbarButton
        onClick={() => setIsExpanded(!isExpanded)}
        icon={isExpanded ? ChevronUp : ChevronDown}
      />
    </BubbleMenu>
  );
};

export default BubbleToolbar;