import React from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ImagePlus } from "lucide-react";

interface LogoFormData {
  name: string;
  type: "Government_Relation" | "Client" | "Partner";
  file: FileList;
}

interface LogoUploadModalProps {
  onUpload: (data: { name: string; type: string; file: File }) => void;
}

const LogoUploadModal = ({ onUpload }: LogoUploadModalProps) => {
  const form = useForm<LogoFormData>();

  const onSubmit = (data: LogoFormData) => {
    if (data.file?.[0]) {
      onUpload({
        name: data.name,
        type: data.type,
        file: data.file[0],
      });
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="flex items-center gap-2">
          <ImagePlus className="h-4 w-4" />
          Upload New Logo
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg">
        <DialogHeader>
          <DialogTitle className="text-gray-900 dark:text-gray-100">Upload New Logo</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-700 dark:text-gray-300">Company Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter company name" className="bg-white dark:bg-gray-900" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-700 dark:text-gray-300">Logo Type</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="bg-white dark:bg-gray-900">
                        <SelectValue placeholder="Select logo type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="bg-white dark:bg-gray-900">
                      <SelectItem value="Government_Relation">Government Relation</SelectItem>
                      <SelectItem value="Client">Client</SelectItem>
                      <SelectItem value="Partner">Partner</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field: { onChange, value, ...field } }) => (
                <FormItem>
                  <FormLabel className="text-gray-700 dark:text-gray-300">Logo File</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        onChange(e.target.files);
                      }}
                      className="bg-white dark:bg-gray-900"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full">Upload Logo</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default LogoUploadModal;