import { Heart, Clock, Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import InsightShareMenu from "./InsightShareMenu";

interface InsightCardFooterProps {
  readingTime: number;
  likesCount: number;
  viewsCount: number;
  isLiked: boolean;
  onLike: () => void;
  onShare: (platform: string) => void;
}

const InsightCardFooter = ({
  readingTime,
  likesCount,
  viewsCount,
  isLiked,
  onLike,
  onShare
}: InsightCardFooterProps) => {
  return (
    <div className="flex items-center justify-between pt-3 border-t border-gray-100">
      <div className="flex items-center gap-1.5 flex-wrap">
        <span className="inline-flex items-center gap-1 text-[10px] bg-primary/10 text-primary px-2 py-0.5 rounded-full whitespace-nowrap">
          <Clock className="w-3 h-3" />
          {readingTime || 5} min
        </span>
        <span className="inline-flex items-center gap-1 text-[10px] bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full whitespace-nowrap">
          <Eye className="w-3 h-3" />
          {viewsCount}
        </span>
      </div>
      
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          className={`h-7 px-2 gap-1.5 hover:text-primary ${isLiked ? 'text-primary' : 'text-gray-600'}`}
          onClick={onLike}
        >
          <Heart className={`w-3.5 h-3.5 ${isLiked ? 'fill-current' : ''}`} />
          <span className="text-xs">{likesCount}</span>
        </Button>
        <InsightShareMenu onShare={onShare} />
      </div>
    </div>
  );
};

export default InsightCardFooter;