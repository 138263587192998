import { useTranslation } from "@/utils/translations";
import { ServiceContent } from "@/types/service";
import ServiceCard from "./ServiceCard";
import CallToActionCard from "./CallToActionCard";
import { Badge } from "@/components/ui/badge";

const Services = ({ language }: { language: string }) => {
  const t = useTranslation(language);

  const getServiceContent = (key: string): ServiceContent => {
    const content = t.services[key as keyof typeof t.services] as ServiceContent;
    return {
      title: content.title,
      description: content.description,
      items: content.items,
      details: content.details
    };
  };

  const services = [
    {
      key: "strategy",
      badge: "Feasibility Study"
    },
    {
      key: "incorporation",
      badge: "Incorporation"
    },
    {
      key: "recruitment",
      badge: "Recruitment"
    },
    {
      key: "taxation",
      badge: "Compliance"
    },
    {
      key: "realEstate",
      badge: "Real Estate"
    }
  ];

  return (
    <section id="services" className="py-20 px-6 bg-neutral">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <div className="flex justify-center mb-4">
            <Badge variant="secondary" className="bg-primary text-white hover:bg-primary-dark">
              Services
            </Badge>
          </div>
          <h2 className="text-4xl font-bold mb-4">{t.services.title}</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t.services.subtitle}
          </p>
        </div>

        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {services.slice(0, 3).map((service, index) => (
            <ServiceCard
              key={index}
              content={getServiceContent(service.key)}
              badge={service.badge}
            />
          ))}
          {services.slice(3, 5).map((service, index) => (
            <ServiceCard
              key={index + 3}
              content={getServiceContent(service.key)}
              badge={service.badge}
            />
          ))}
          <CallToActionCard language={language} />
        </div>
      </div>
    </section>
  );
};

export default Services;