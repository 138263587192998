
import { useState, useEffect, useMemo } from 'react';
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { supabase } from '@/integrations/supabase/client';
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Search, CheckCircle2, FolderOpen } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

interface PredefinedTag {
  id: string;
  name: string;
  category: string;
  description: string;
}

interface TagSelectorProps {
  selectedTags: string[];
  onTagsChange: (tags: string[]) => void;
}

const TagSelector = ({ selectedTags, onTagsChange }: TagSelectorProps) => {
  const [tags, setTags] = useState<PredefinedTag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      if (!mounted) return;
      
      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error: fetchError } = await supabase
          .from('predefined_tags')
          .select('*')
          .order('category', { ascending: true });

        if (fetchError) throw fetchError;
        if (!data) throw new Error('No tags found');

        if (mounted) {
          setTags(data);
          // Initially expand all categories
          setExpandedCategories(new Set(data.map(tag => tag.category)));
        }
      } catch (err) {
        console.error('Error fetching tags:', err);
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load tags');
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    fetchTags();
  }, [mounted]);

  const toggleTag = (tagName: string) => {
    const newSelectedTags = selectedTags.includes(tagName)
      ? selectedTags.filter(t => t !== tagName)
      : [...selectedTags, tagName];
    
    console.log('Toggling tag:', tagName, 'New tags:', newSelectedTags);
    onTagsChange(newSelectedTags);
  };

  const toggleCategory = (category: string) => {
    const newExpanded = new Set(expandedCategories);
    if (newExpanded.has(category)) {
      newExpanded.delete(category);
    } else {
      newExpanded.add(category);
    }
    setExpandedCategories(newExpanded);
  };

  const filteredAndGroupedTags = useMemo(() => {
    const filtered = tags.filter(tag => 
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.category.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filtered.reduce<Record<string, PredefinedTag[]>>((acc, tag) => {
      if (!acc[tag.category]) {
        acc[tag.category] = [];
      }
      acc[tag.category].push(tag);
      return acc;
    }, {});
  }, [tags, searchQuery]);

  if (!mounted || isLoading) {
    return (
      <div className="w-full p-2">
        <p className="text-sm text-gray-500">Loading tags...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full p-2">
        <p className="text-sm text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
        <Input
          placeholder="Search tags..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-8"
        />
      </div>

      {selectedTags.length > 0 && (
        <div className="px-2">
          <p className="text-sm text-gray-500 mb-2">
            Selected Tags ({selectedTags.length})
          </p>
          <div className="flex flex-wrap gap-2">
            {selectedTags.map((tag) => (
              <Badge
                key={tag}
                variant="default"
                className="cursor-pointer bg-[#E5DEFF] hover:bg-[#D6BCFA] text-[#2D3748] hover:text-[#1A202C] transition-all duration-200 group"
                onClick={() => toggleTag(tag)}
              >
                {tag}
                <span className="ml-1 opacity-60 group-hover:opacity-100">×</span>
              </Badge>
            ))}
          </div>
        </div>
      )}

      <ScrollArea className="h-[300px] w-full rounded-md border p-2">
        <div className="space-y-4">
          {Object.entries(filteredAndGroupedTags).map(([category, categoryTags]) => (
            <Collapsible
              key={category}
              open={expandedCategories.has(category)}
              onOpenChange={() => toggleCategory(category)}
            >
              <CollapsibleTrigger className="flex w-full items-center justify-between px-2 py-1 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded-md transition-colors">
                <div className="flex items-center gap-2">
                  <FolderOpen className="h-4 w-4 text-gray-400" />
                  <span>{category}</span>
                  <span className="text-xs text-gray-400">
                    ({categoryTags.length})
                  </span>
                </div>
              </CollapsibleTrigger>
              
              <CollapsibleContent className="pt-2">
                <div className="flex flex-wrap gap-2 px-2">
                  {categoryTags.map((tag) => (
                    <Badge
                      key={tag.id}
                      variant={selectedTags.includes(tag.name) ? "default" : "outline"}
                      className={cn(
                        "cursor-pointer transition-all duration-200 flex items-center gap-1",
                        selectedTags.includes(tag.name)
                          ? "bg-[#E5DEFF] hover:bg-[#D6BCFA] text-[#2D3748] hover:text-[#1A202C]" 
                          : "bg-[#F8F7FD] hover:bg-[#F1F0FB] text-[#4A5568] border-[#E2E8F0]"
                      )}
                      onClick={() => toggleTag(tag.name)}
                      title={tag.description || undefined}
                    >
                      {tag.name}
                      {selectedTags.includes(tag.name) && (
                        <CheckCircle2 className="h-3 w-3 text-[#2D3748]" />
                      )}
                    </Badge>
                  ))}
                </div>
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default TagSelector;

