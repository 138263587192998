
import { Helmet } from "react-helmet";
import type { EventWithImages } from "@/hooks/useGalleryEvents";

interface GallerySEOProps {
  language: string;
  events: EventWithImages[];
}

const GallerySEO = ({ language, events }: GallerySEOProps) => {
  const featuredEvent = events.find(event => event.is_featured);
  const defaultImage = featuredEvent?.coverImage?.image_url || '/og-image.png';

  return (
    <Helmet>
      <title>
        {language === 'KO' 
          ? 'Duru Compliance 갤러리 - 비즈니스 이벤트 및 글로벌 활동'
          : 'Duru Compliance Gallery - Business Events & Global Presence'}
      </title>
      <meta
        name="description"
        content={
          language === 'KO'
            ? 'Duru Compliance의 비즈니스 서밋, 파트너십, 기업 이정표를 통한 여정을 살펴보세요. 인도에서의 글로벌 확장 스토리.'
            : 'Explore Duru Compliance\'s journey through business summits, partnerships, and corporate milestones. Our global expansion story in India.'
        }
      />
      
      {/* OpenGraph Tags */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={
          language === 'KO'
            ? 'Duru Compliance 갤러리 - 비즈니스 이벤트 및 글로벌 활동'
            : 'Duru Compliance Gallery - Business Events & Global Presence'
        }
      />
      <meta
        property="og:description"
        content={
          language === 'KO'
            ? 'Duru Compliance의 비즈니스 서밋, 파트너십, 기업 이정표를 통한 여정을 살펴보세요.'
            : 'Explore Duru Compliance\'s journey through business summits, partnerships, and corporate milestones.'
        }
      />
      <meta property="og:image" content={defaultImage} />
      <meta property="og:image:alt" content="Duru Compliance Gallery" />
      
      {/* Language Alternates */}
      <link rel="alternate" hrefLang="en" href="https://durucompliance.com/gallery" />
      <link rel="alternate" hrefLang="ko" href="https://durucompliance.com/ko/gallery" />
      <link rel="canonical" href={`https://durucompliance.com${language === 'KO' ? '/ko' : ''}/gallery`} />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": language === 'KO' 
            ? 'Duru Compliance 갤러리'
            : 'Duru Compliance Gallery',
          "description": language === 'KO'
            ? 'Duru Compliance의 비즈니스 서밋, 파트너십, 기업 이정표를 통한 여정을 살펴보세요.'
            : 'Explore Duru Compliance\'s journey through business summits, partnerships, and corporate milestones.',
          "publisher": {
            "@type": "Organization",
            "name": "Duru Compliance",
            "url": "https://durucompliance.com"
          },
          "inLanguage": language === 'KO' ? "ko" : "en",
          "numberOfItems": events.length,
          "itemListElement": events.map((event, index) => ({
            "@type": "ImageGallery",
            "position": index + 1,
            "name": language === 'KO' && event.title_ko ? event.title_ko : event.title_en,
            "description": language === 'KO' && event.description_ko ? event.description_ko : event.description_en,
            "image": event.coverImage?.image_url,
            "datePublished": event.event_date,
            "location": {
              "@type": "Place",
              "name": event.location
            }
          }))
        })}
      </script>
    </Helmet>
  );
};

export default GallerySEO;
