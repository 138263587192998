
import { Button } from '@/components/ui/button';
import { useState } from 'react';

interface LanguageToggleProps {
  currentLanguage: 'en' | 'ko';
  onLanguageChange: (lang: 'en' | 'ko') => void;
}

const LanguageToggle = ({ currentLanguage, onLanguageChange }: LanguageToggleProps) => {
  return (
    <div className="inline-flex rounded-lg border border-gray-200 p-1 bg-white">
      <Button
        variant={currentLanguage === 'en' ? 'default' : 'ghost'}
        size="sm"
        onClick={() => onLanguageChange('en')}
        className={`${
          currentLanguage === 'en' 
            ? 'bg-primary text-white hover:bg-primary/90' 
            : 'hover:bg-gray-100'
        }`}
      >
        English
      </Button>
      <Button
        variant={currentLanguage === 'ko' ? 'default' : 'ghost'}
        size="sm"
        onClick={() => onLanguageChange('ko')}
        className={`${
          currentLanguage === 'ko' 
            ? 'bg-primary text-white hover:bg-primary/90' 
            : 'hover:bg-gray-100'
        }`}
      >
        한국어
      </Button>
    </div>
  );
};

export default LanguageToggle;
