import { AlertCircle } from 'lucide-react';

interface FormTipsProps {
  section: 'basic' | 'details';
}

export const FormTips = ({ section }: FormTipsProps) => {
  const tips = section === 'basic' ? [
    'Use descriptive, keyword-rich titles in both languages',
    'Write detailed descriptions that explain the event\'s significance',
    'Ensure translations accurately convey the same message'
  ] : [
    'Set accurate dates for proper chronological ordering',
    'Choose specific locations to help with event context',
    'Select the most relevant category for better organization',
    'Mark significant events as featured to highlight them'
  ];

  return (
    <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
      <h3 className="text-sm font-medium text-blue-800 mb-2">
        {section === 'basic' ? 'Tips for Event Creation:' : 'Event Details Tips:'}
      </h3>
      <ul className="text-sm text-blue-700 list-disc pl-4 space-y-1">
        {tips.map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>
    </div>
  );
};