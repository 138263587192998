
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { EditButton, DeleteButton } from '@/components/admin/AdminButtonStyles';
import type { GalleryEvent } from '@/types/gallery';

interface GalleryEventTableProps {
  events: GalleryEvent[];
  onEdit: (event: GalleryEvent) => void;
  onDelete: (event: GalleryEvent) => void;
  isDeletingId?: string;
}

const GalleryEventTable = ({ events, onEdit, onDelete, isDeletingId }: GalleryEventTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Title (EN)</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Location</TableHead>
          <TableHead>Category</TableHead>
          <TableHead>Featured</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((event) => (
          <TableRow key={event.id}>
            <TableCell>{event.title_en}</TableCell>
            <TableCell>{new Date(event.event_date).toLocaleDateString()}</TableCell>
            <TableCell>{event.location}</TableCell>
            <TableCell>{event.category}</TableCell>
            <TableCell>{event.is_featured ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <EditButton onClick={() => onEdit(event)} />
                <DeleteButton 
                  onClick={() => onDelete(event)}
                  disabled={isDeletingId === event.id} 
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GalleryEventTable;

