import { Mail, MapPin } from "lucide-react";
import { contactTranslations } from "@/utils/translations/contact";
import { Button } from "@/components/ui/button";

interface ContactInfoProps {
  language: string;
}

const ContactInfo = ({ language }: ContactInfoProps) => {
  const translations = contactTranslations[language as keyof typeof contactTranslations];

  return (
    <div className="space-y-8">
      <div className="bg-gradient-to-br from-white to-neutral-50 rounded-xl p-8 shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300">
        <div className="flex items-center gap-6 mb-6">
          <div className="w-20 h-20 rounded-full overflow-hidden ring-4 ring-primary/10">
            <img 
              src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/yongjin_ham.avif" 
              alt={language === 'KO' ? '함용진' : 'Yongjin Ham'}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h3 className="font-semibold text-xl text-neutral-dark">
              {language === 'KO' ? '함용진' : 'Yongjin Ham'}
            </h3>
            <p className="text-primary/80 font-medium">
              {language === 'KO' ? '대표이사' : 'Managing Director'}
            </p>
          </div>
        </div>
        
        <div className="space-y-6">
          <div className="flex items-center gap-3 group">
            <div className="p-2 rounded-lg bg-primary/5 group-hover:bg-primary/10 transition-colors">
              <Mail className="w-5 h-5 text-primary" />
            </div>
            <a 
              href="mailto:yongjin@duruper.com" 
              className="text-gray-600 hover:text-primary transition-colors"
            >
              yongjin@duruper.com
            </a>
          </div>
          
          <div className="flex gap-3">
            <Button
              variant="outline"
              className="flex-1 bg-white hover:bg-primary/5 border-gray-200"
              onClick={() => window.open('https://open.kakao.com/o/sKpfsVYg', '_blank')}
            >
              <img 
                src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/footer/kakaotalk_logo.png"
                alt="Kakao"
                className="w-6 h-6 mr-2"
              />
              {language === 'KO' ? '카카오톡' : 'Kakao'}
            </Button>
            <Button
              variant="outline"
              className="flex-1 bg-white hover:bg-primary/5 border-gray-200"
              onClick={() => window.open('https://wa.me/919008177740', '_blank')}
            >
              <img 
                src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/footer/whatsapp-48.png"
                alt="WhatsApp"
                className="w-6 h-6 mr-2"
              />
              {language === 'KO' ? '왓츠앱' : 'WhatsApp'}
            </Button>
          </div>
        </div>
      </div>
      
      <div className="bg-gradient-to-br from-white to-neutral-50 rounded-xl p-8 shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300">
        <div className="flex items-start gap-4 group">
          <div className="p-3 rounded-lg bg-primary/5 group-hover:bg-primary/10 transition-colors">
            <MapPin className="w-5 h-5 text-primary" />
          </div>
          <div>
            <h3 className="font-medium text-lg text-neutral-dark mb-2">
              {translations.info.address.label}
            </h3>
            <p className="text-gray-600 leading-relaxed">
              {translations.info.address.value}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;