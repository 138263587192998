
import { useState } from 'react';
import { useToast } from '@/hooks/use-toast';
import type { Insight } from '@/types/insight';
import InsightList from './insights/InsightList';
import InsightHeader from './insights/editor/InsightHeader';
import InsightEditorView from './insights/editor/InsightEditorView';
import { useInsightManagement } from '@/hooks/useInsightManagement';

const InsightManager = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const { toast } = useToast();
  const { insights, isLoading, handleSave, handleToggleVisibility, handleDelete } = useInsightManagement();

  const handleCreateNew = () => {
    setSelectedInsight(null);
    setIsEditing(true);
  };

  const handleEdit = (insight: Insight) => {
    setSelectedInsight(insight);
    setIsEditing(true);
  };

  const handleSaveWrapper = async (data: any) => {
    const success = await handleSave(data, selectedInsight);
    if (success) {
      setIsEditing(false);
      setSelectedInsight(null);
      toast({
        title: "Success",
        description: "Article saved successfully",
      });
    }
  };

  if (isEditing) {
    return (
      <InsightEditorView
        onBack={() => setIsEditing(false)}
        selectedInsight={selectedInsight}
        onSave={handleSaveWrapper}
      />
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <InsightHeader onCreateNew={handleCreateNew} />
      <InsightList 
        insights={insights || []}
        onEdit={handleEdit}
        onToggleVisibility={handleToggleVisibility}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default InsightManager;
