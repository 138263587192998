
import { cn } from '@/lib/utils';

interface EditorLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const EditorLayout = ({ children, className }: EditorLayoutProps) => {
  return (
    <div className={cn("space-y-0", className)}>
      {children}
    </div>
  );
};

export default EditorLayout;
