import { useState } from 'react';
import ImageUpload from '../ImageUpload';
import GalleryImageGrid from '../GalleryImageGrid';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { InfoIcon } from 'lucide-react';

interface ImageSectionProps {
  eventId: string;
}

export const ImageSection = ({ eventId }: ImageSectionProps) => {
  const [refreshKey, setRefreshKey] = useState(0);

  const handleUploadComplete = () => {
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="space-y-4">
      <div className="border-t pt-4">
        <h3 className="text-lg font-medium mb-4">Event Images</h3>
        
        <Alert className="mb-4">
          <InfoIcon className="h-4 w-4" />
          <AlertDescription>
            <h4 className="font-medium mb-2">Image Upload Guidelines:</h4>
            <ul className="list-disc pl-4 space-y-1 text-sm">
              <li>Recommended image size: 1200x800 pixels</li>
              <li>Maximum file size: 2MB</li>
              <li>Supported formats: JPEG, PNG</li>
              <li>Set a cover image for better gallery presentation</li>
              <li>Use descriptive alt text for better accessibility and SEO</li>
              <li>Add captions in both English and Korean when possible</li>
            </ul>
          </AlertDescription>
        </Alert>

        <ImageUpload eventId={eventId} onUploadComplete={handleUploadComplete} />
      </div>
      <GalleryImageGrid key={refreshKey} eventId={eventId} />
    </div>
  );
};