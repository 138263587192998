import { format } from 'date-fns';
import { AlertCircle, Eye, EyeOff, Image as ImageIcon, MoreVertical, Pencil, Trash2, CheckCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { Insight } from '@/types/insight';

interface InsightListItemProps {
  insight: Insight;
  onEdit: (insight: Insight) => void;
  onToggleVisibility: (insight: Insight) => void;
  onDelete: (insight: Insight) => void;
}

const InsightListItem = ({ insight, onEdit, onToggleVisibility, onDelete }: InsightListItemProps) => {
  const getMissingFields = (insight: Insight) => {
    const missingFields = [];
    
    // English fields
    if (!insight.title_en) missingFields.push('English Title');
    if (!insight.content_en) missingFields.push('English Content');
    if (!insight.description_en) missingFields.push('English Description');
    if (!insight.meta_description_en) missingFields.push('English Meta Description');
    if (!insight.meta_keywords_en?.length) missingFields.push('English Meta Keywords');
    
    // Korean fields
    if (!insight.title_ko) missingFields.push('Korean Title');
    if (!insight.content_ko) missingFields.push('Korean Content');
    if (!insight.description_ko) missingFields.push('Korean Description');
    if (!insight.meta_description_ko) missingFields.push('Korean Meta Description');
    if (!insight.meta_keywords_ko?.length) missingFields.push('Korean Meta Keywords');
    
    // Other required fields
    if (!insight.thumbnail_url) missingFields.push('Thumbnail');
    if (!insight.tags?.length) missingFields.push('Tags');
    if (!insight.author) missingFields.push('Author');
    
    return missingFields;
  };

  const missingFields = getMissingFields(insight);
  const hasMissingFields = missingFields.length > 0;

  return (
    <div className="flex items-center gap-4 p-4 bg-white rounded-lg border border-gray-100 hover:shadow-md transition-shadow">
      <div className="w-32 h-24 flex-shrink-0 bg-gray-100 rounded-md overflow-hidden">
        {insight.thumbnail_url ? (
          <img
            src={insight.thumbnail_url}
            alt={insight.title_en}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center text-gray-400">
            <ImageIcon className="w-8 h-8" />
          </div>
        )}
      </div>
      
      <div className="flex-grow min-w-0">
        <div className="flex items-start justify-between gap-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 truncate">
              {insight.title_en}
            </h3>
            <p className="text-sm text-gray-500 mt-1">
              {format(new Date(insight.created_at), 'MMM d, yyyy')}
            </p>
            <p className="text-sm text-gray-600 mt-2 line-clamp-2">
              {insight.description_en}
            </p>
          </div>
          
          <div className="flex items-center gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  {hasMissingFields ? (
                    <AlertCircle className="h-4 w-4 text-amber-500 cursor-help" />
                  ) : (
                    <CheckCircle className="h-4 w-4 text-green-500 cursor-help" />
                  )}
                </TooltipTrigger>
                <TooltipContent className="p-3 max-w-xs bg-white border border-gray-200 shadow-lg text-red-600">
                  {hasMissingFields ? (
                    <>
                      <p className="font-medium mb-1">Missing Fields:</p>
                      <ul className="list-disc list-inside text-sm">
                        {missingFields.map((field) => (
                          <li key={field}>{field}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <p className="text-green-600">All fields are filled correctly!</p>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="h-8 w-8 text-gray-500 hover:text-primary hover:bg-gray-100"
                >
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48 bg-white border border-gray-200 shadow-lg">
                <DropdownMenuItem 
                  onClick={() => onEdit(insight)}
                  className="flex items-center gap-2 text-gray-700 hover:text-primary hover:bg-gray-50"
                >
                  <Pencil className="h-4 w-4" />
                  Edit
                </DropdownMenuItem>
                {insight.status === 'published' ? (
                  <DropdownMenuItem 
                    onClick={() => onToggleVisibility(insight)}
                    className="flex items-center gap-2 text-gray-700 hover:text-primary hover:bg-gray-50"
                  >
                    <EyeOff className="h-4 w-4" />
                    Unpublish
                  </DropdownMenuItem>
                ) : insight.status === 'draft' ? (
                  <DropdownMenuItem 
                    onClick={() => onToggleVisibility(insight)}
                    className="flex items-center gap-2 text-gray-700 hover:text-primary hover:bg-gray-50"
                  >
                    <Eye className="h-4 w-4" />
                    Publish
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem 
                    onClick={() => onToggleVisibility(insight)}
                    className="flex items-center gap-2 text-gray-700 hover:text-primary hover:bg-gray-50"
                  >
                    <Eye className="h-4 w-4" />
                    Republish
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem 
                  onClick={() => onDelete(insight)}
                  className="flex items-center gap-2 text-red-600 hover:text-red-700 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        
        <div className="flex items-center gap-2 mt-3">
          <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
            insight.status === 'published' 
              ? 'bg-green-100 text-green-700' 
              : insight.status === 'draft'
              ? 'bg-gray-100 text-gray-700'
              : 'bg-yellow-100 text-yellow-700'
          }`}>
            {insight.status.charAt(0).toUpperCase() + insight.status.slice(1)}
          </span>
          {insight.tags.map((tag, index) => (
            <span
              key={index}
              className="inline-flex items-center px-2 py-1 rounded-full bg-gray-100 text-xs text-gray-600"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InsightListItem;
