import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { Toaster } from "@/components/ui/toaster";
import { ToastProvider } from "@/components/ui/toast";
import Navbar from "@/components/Navbar";
import Index from "@/pages/Index";
import About from "@/pages/About";
import Contact from "@/pages/Contact";
import Gallery from "@/pages/Gallery";
import Insights from "@/pages/Insights";
import InsightDetail from "@/pages/InsightDetail";
import NotFound from "@/pages/NotFound";
import Admin from "@/pages/Admin";
import Login from "@/pages/Login";

const queryClient = new QueryClient();

function App() {
  const [language, setLanguage] = useState(() => {
    // Get browser language
    const browserLang = navigator.language.toLowerCase();
    const userPreference = localStorage.getItem('preferred-language');
    
    // If user has a saved preference, use that
    if (userPreference) {
      return userPreference;
    }
    
    // Check if the browser language is Korean
    if (browserLang.startsWith('ko')) {
      return 'KO';
    }
    
    // Default to English for all other languages
    return 'EN';
  });

  // Save language preference when it changes
  useEffect(() => {
    localStorage.setItem('preferred-language', language);
  }, [language]);

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Router>
          <div className="flex flex-col min-h-screen">
            <Navbar 
              onLanguageChange={handleLanguageChange}
              initialLanguage={language}
            />
            <Routes>
              <Route path="/" element={<Index language={language} />} />
              <Route path="/about" element={<About language={language} />} />
              <Route path="/contact" element={<Contact language={language} />} />
              <Route path="/gallery" element={<Gallery language={language} />} />
              <Route path="/insights" element={<Insights language={language} />} />
              <Route path="/insights/:slug" element={<InsightDetail language={language} />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Toaster />
          </div>
        </Router>
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;