
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import InsightEditor from '@/components/InsightEditor';
import type { Insight, InsightFormData } from '@/types/insight';
import { useToast } from '@/hooks/use-toast';

interface InsightEditorViewProps {
  onBack: () => void;
  selectedInsight: Insight | null;
  onSave: (data: InsightFormData) => Promise<void>;
}

const InsightEditorView = ({ onBack, selectedInsight, onSave }: InsightEditorViewProps) => {
  return (
    <div className="space-y-4">
      <Button variant="outline" onClick={onBack} className="flex items-center gap-2">
        <ArrowLeft className="h-4 w-4" />
        Back to List
      </Button>
      <InsightEditor
        onSave={onSave}
        initialData={selectedInsight || undefined}
      />
    </div>
  );
};

export default InsightEditorView;

