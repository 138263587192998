export interface CeoPreviewTranslations {
  imageAlt: string;
  message: string;
  cta: string;
}

export const ceoPreviewTranslations = {
  EN: {
    imageAlt: "Yongjoo Yoo - CEO, Yonsei University Alumni",
    message: "Based on my experience in managing overseas business support for large corporations, I established Duru in 2017, and have since supported more than 100 Korean companies to successfully enter the Indian market. Duru will contribute to the successful entry and growth of our clients in India based on competence and responsibility.",
    cta: "Meet Our Leadership Team"
  },
  KO: {
    imageAlt: "유용주 대표 - 연세대학교 동문",
    message: "대기업 해외 사업장의 경영지원 총괄 업무 경험을 바탕으로 2017년 두루(Duru)를 설립하였으며, 현재까지 100개 이상의 한국 기업이 인도 시장에 성공적으로 진출할 수 있도록 지원해왔습니다. 두루는 실력과 책임감을 바탕으로 고객사의 성공적인 인도 진출과 성장에 기여하겠습니다.",
    cta: "리더십 팀 소개"
  }
};