
import ArticleHeader from './sections/ArticleHeader';
import TitleSection from './sections/TitleSection';
import ExternalUrlSection from './sections/ExternalUrlSection';
import ArticleSummarySection from './sections/ArticleSummarySection';
import TagsSection from './sections/TagsSection';
import SeoSection from './sections/SeoSection';

interface MainSectionProps {
  // English content
  title_en: string;
  setTitleEn: (value: string) => void;
  description_en: string;
  setDescriptionEn: (value: string) => void;
  meta_description_en: string;
  setMetaDescriptionEn: (value: string) => void;
  meta_keywords_en: string[];
  
  // Korean content
  title_ko: string;
  setTitleKo: (value: string) => void;
  description_ko: string;
  setDescriptionKo: (value: string) => void;
  meta_description_ko: string;
  setMetaDescriptionKo: (value: string) => void;
  meta_keywords_ko: string[];
  
  // Common props
  isExternal: boolean;
  external_url: string;
  setExternalUrl: (value: string) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
  newTag: string;
  setNewTag: (value: string) => void;
  handleAddTag: (e: React.KeyboardEvent) => void;
  handleRemoveTag: (tag: string) => void;
  newKeyword: string;
  setNewKeyword: (value: string) => void;
  handleAddKeyword: (e: React.KeyboardEvent) => void;
  handleRemoveKeyword: (keyword: string) => void;
  currentLanguage: 'en' | 'ko';
  onLanguageChange: (lang: 'en' | 'ko') => void;
}

const MainSection = ({
  // English props
  title_en,
  setTitleEn,
  description_en,
  setDescriptionEn,
  meta_description_en,
  setMetaDescriptionEn,
  meta_keywords_en,
  
  // Korean props
  title_ko,
  setTitleKo,
  description_ko,
  setDescriptionKo,
  meta_description_ko,
  setMetaDescriptionKo,
  meta_keywords_ko,
  
  // Common props
  isExternal,
  external_url,
  setExternalUrl,
  tags,
  setTags,
  newTag,
  setNewTag,
  handleAddTag,
  handleRemoveTag,
  newKeyword,
  setNewKeyword,
  handleAddKeyword,
  handleRemoveKeyword,
  currentLanguage,
  onLanguageChange,
}: MainSectionProps) => {
  const isEnglish = currentLanguage === 'en';

  return (
    <div className="lg:col-span-2 space-y-6">
      <ArticleHeader
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
      />
      
      <div className="grid gap-6">
        <TitleSection
          title_en={title_en}
          setTitleEn={setTitleEn}
          title_ko={title_ko}
          setTitleKo={setTitleKo}
          isEnglish={isEnglish}
        />
        
        {isExternal ? (
          <ExternalUrlSection
            externalUrl={external_url}
            setExternalUrl={setExternalUrl}
          />
        ) : (
          <ArticleSummarySection
            description_en={description_en}
            setDescriptionEn={setDescriptionEn}
            description_ko={description_ko}
            setDescriptionKo={setDescriptionKo}
            isEnglish={isEnglish}
          />
        )}

        <TagsSection
          tags={tags}
          newTag={newTag}
          setNewTag={setNewTag}
          handleAddTag={handleAddTag}
          handleRemoveTag={handleRemoveTag}
          setTags={setTags}
        />

        <SeoSection
          isEnglish={isEnglish}
          metaDescription={isEnglish ? meta_description_en : meta_description_ko}
          setMetaDescription={isEnglish ? setMetaDescriptionEn : setMetaDescriptionKo}
          newKeyword={newKeyword}
          setNewKeyword={setNewKeyword}
          handleAddKeyword={handleAddKeyword}
          metaKeywords={isEnglish ? meta_keywords_en : meta_keywords_ko}
          handleRemoveKeyword={handleRemoveKeyword}
        />
      </div>
    </div>
  );
};

export default MainSection;
