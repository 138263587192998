import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { Form } from "@/components/ui/form";
import { useToast } from '@/hooks/use-toast';
import { EventFormFields } from './form/EventFormFields';
import { ImageSection } from './form/ImageSection';
import type { GalleryEvent } from '@/types/gallery';

interface GalleryEventEditorProps {
  initialData?: GalleryEvent;
  onSave: () => void;
}

const GalleryEventEditor = ({ initialData, onSave }: GalleryEventEditorProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tempEventId, setTempEventId] = useState<string | null>(null);
  
  const form = useForm({
    defaultValues: {
      title_en: initialData?.title_en || '',
      title_ko: initialData?.title_ko || '',
      description_en: initialData?.description_en || '',
      description_ko: initialData?.description_ko || '',
      event_date: initialData?.event_date || '',
      location: initialData?.location || '',
      category: initialData?.category || '',
      is_featured: initialData?.is_featured || false,
    },
  });

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      // Remove cover_image from data if it exists
      const { cover_image, ...eventData } = data;
      
      if (initialData) {
        const { error } = await supabase
          .from('gallery_events')
          .update({
            ...eventData,
            updated_at: new Date().toISOString(),
          })
          .eq('id', initialData.id);

        if (error) throw error;
      } else {
        const { data: newEvent, error } = await supabase
          .from('gallery_events')
          .insert([eventData])
          .select()
          .single();

        if (error) throw error;
        setTempEventId(newEvent.id);
      }

      toast({
        title: 'Success',
        description: `Event ${initialData ? 'updated' : 'created'} successfully`,
      });

      if (!tempEventId) {
        onSave();
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const eventId = initialData?.id || tempEventId;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <EventFormFields form={form} section="basic" />
          <div>
            <EventFormFields form={form} section="details" />
            {eventId && <ImageSection eventId={eventId} />}
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <Button
            type="button"
            variant="outline"
            onClick={() => onSave()}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : initialData ? 'Update Event' : 'Create Event'}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default GalleryEventEditor;