import { useTranslation } from "@/utils/translations";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { contactTranslations } from "@/utils/translations/contact";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface ContactFormProps {
  language: string;
}

const ContactForm = ({ language }: ContactFormProps) => {
  const { toast } = useToast();
  const translations = contactTranslations[language as keyof typeof contactTranslations];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!recaptchaValue) {
      toast({
        title: "Error",
        description: "Please complete the reCAPTCHA verification",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData(e.currentTarget);
      const data = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
        phone: formData.get('phone') as string,
        subject: formData.get('subject') as string,
        message: formData.get('message') as string,
        recaptchaToken: recaptchaValue,
      };

      // Store in database
      const { error: dbError } = await supabase.functions.invoke('handle-contact-form', {
        body: data,
      });

      if (dbError) throw dbError;

      // Send emails
      const { error: emailError } = await supabase.functions.invoke('handle-contact-email', {
        body: data,
      });

      if (emailError) throw emailError;

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: "Error",
        description: "There was an error submitting your message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="bg-white rounded-xl p-8 shadow-lg border border-gray-100 text-center">
        <div className="mb-6">
          <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <svg
              className="w-8 h-8 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-semibold text-neutral-dark mb-4">
          {translations.form.success}
        </h2>
        <p className="text-gray-600 mb-8">
          {translations.form.successDetail}
        </p>
        <Button 
          onClick={() => setIsSubmitted(false)}
          variant="outline"
          className="mx-auto"
        >
          Send Another Message
        </Button>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-8 shadow-lg border border-gray-100">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-neutral-dark">
          {translations.form.title}
        </h2>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid md:grid-cols-2 gap-4">
          <Input 
            id="name" 
            name="name"
            placeholder={translations.form.name} 
            className="bg-white border-gray-200 text-neutral-dark placeholder:text-gray-400 focus:border-primary focus:ring-primary"
            required 
          />
          <Input 
            id="email" 
            name="email"
            type="email" 
            placeholder={translations.form.email} 
            className="bg-white border-gray-200 text-neutral-dark placeholder:text-gray-400 focus:border-primary focus:ring-primary"
            required 
          />
        </div>
        <Input 
          id="phone"
          name="phone"
          type="tel"
          placeholder={translations.form.phone}
          className="bg-white border-gray-200 text-neutral-dark placeholder:text-gray-400 focus:border-primary focus:ring-primary"
          aria-describedby="phone-hint"
        />
        <p id="phone-hint" className="text-sm text-gray-500 -mt-4">
          {translations.form.phoneHint}
        </p>
        <Input 
          id="subject" 
          name="subject"
          placeholder={translations.form.subject} 
          className="bg-white border-gray-200 text-neutral-dark placeholder:text-gray-400 focus:border-primary focus:ring-primary"
          required 
        />
        <Textarea
          id="message"
          name="message"
          placeholder={translations.form.message}
          className="min-h-[120px] bg-white border-gray-200 text-neutral-dark placeholder:text-gray-400 focus:border-primary focus:ring-primary resize-none"
          required
        />
        <div className="flex justify-center mb-4">
          <ReCAPTCHA
            sitekey="6LeGjs0qAAAAALpPzlUKfe6DxXjN7Gmbzw9IqJIk"
            onChange={(value) => setRecaptchaValue(value)}
          />
        </div>
        <Button 
          type="submit" 
          className="w-full bg-primary hover:bg-primary-light text-white transition-colors"
          disabled={isSubmitting || !recaptchaValue}
        >
          {isSubmitting ? "Sending..." : translations.form.submit}
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;