import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Logo } from "../../../types/logo";

export const useLogoManager = () => {
  const [logos, setLogos] = useState<Logo[]>([]);
  const { toast } = useToast();

  const fetchLogos = async () => {
    try {
      const { data, error } = await supabase
        .from('logos')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setLogos(data || []);
    } catch (error) {
      console.error('Error fetching logos:', error);
      toast({
        title: "Error fetching logos",
        variant: "destructive",
      });
    }
  };

  const handleLogoUpload = async (data: { name: string; type: string; file: File }) => {
    try {
      const fileExt = data.file.name.split('.').pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;
      const filePath = `logos/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('dcl_website_asset')
        .upload(filePath, data.file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('dcl_website_asset')
        .getPublicUrl(filePath);

      const { error: dbError } = await supabase
        .from('logos')
        .insert({
          name: data.name,
          type: data.type,
          logo_url: publicUrl,
        });

      if (dbError) throw dbError;

      toast({
        title: "Logo uploaded successfully",
      });

      fetchLogos();
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast({
        title: "Error uploading logo",
        variant: "destructive",
      });
    }
  };

  const handleToggleActive = async (logo: Logo) => {
    try {
      const { error } = await supabase
        .from('logos')
        .update({ is_active: !logo.is_active })
        .eq('id', logo.id);

      if (error) throw error;

      toast({
        title: `Logo ${!logo.is_active ? 'activated' : 'deactivated'} successfully`,
      });

      fetchLogos();
    } catch (error) {
      console.error('Error updating logo status:', error);
      toast({
        title: "Error updating logo status",
        variant: "destructive",
      });
    }
  };

  const handleDeleteLogo = async (id: string) => {
    try {
      const { error } = await supabase
        .from('logos')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Logo deleted successfully",
      });

      fetchLogos();
    } catch (error) {
      console.error('Error deleting logo:', error);
      toast({
        title: "Error deleting logo",
        variant: "destructive",
      });
    }
  };

  return {
    logos,
    handleLogoUpload,
    handleToggleActive,
    handleDeleteLogo,
    fetchLogos,
  };
};