
import { Helmet } from "react-helmet";

interface SEOMetaTagsProps {
  language: string;
  path: string;
  pageType?: 'homepage' | 'about' | 'contact' | 'blog' | 'article' | 'service';
  article?: {
    title: string;
    description: string;
    publishedTime: string;
    modifiedTime: string;
    author: string;
    tags: string[];
  };
  service?: {
    name: string;
    description: string;
    provider: string;
    areaServed: string;
  };
}

const SEOMetaTags = ({ language, path, pageType = 'homepage', article, service }: SEOMetaTagsProps) => {
  const isKorean = language === 'KO';
  const baseUrl = 'https://durucompliance.com';
  const currentUrl = `${baseUrl}${path}`;
  const alternateUrl = isKorean ? baseUrl : `${baseUrl}/ko${path}`;
  
  const metaTags = {
    EN: {
      title: "India Business Setup | Incorporation, Compliance & Market Expansion",
      description: "Expert business setup and compliance solutions in India. We help global companies with company registration, taxation, HR, and real estate for smooth market entry and expansion.",
      keywords: "India business setup, company incorporation India, business compliance India, foreign company registration, India market entry, business consulting India, taxation India, HR services India, real estate investment India",
      ogTitle: "India Business Setup | Expand in India with Expert Compliance Solutions",
      ogDescription: "Seamless incorporation, taxation, HR, and compliance solutions for global companies entering India.",
      twitterTitle: "Expand Your Business in India | Duru Compliance",
      twitterDescription: "One-stop business setup and compliance services for global investors in India.",
      locale: "en_US",
      siteName: "Duru Compliance"
    },
    KO: {
      title: "인도 법인 설립 및 경영 지원 | 인도 시장 진출 전문가",
      description: "인도 법인 설립, 세무, HR 및 부동산 컨설팅을 포함한 원스톱 솔루션을 제공합니다. 글로벌 기업의 인도 시장 진출을 지원합니다.",
      keywords: "인도 법인 설립, 인도 시장 진출, 인도 법인 경영, 인도 세무 컨설팅, 인도 HR 서비스, 인도 투자, 인도 기업 확장, 인도 비즈니스 컨설팅",
      ogTitle: "인도 법인 설립 및 경영 지원 | 두루 컴플라이언스",
      ogDescription: "글로벌 기업의 인도 시장 진출을 위한 원스톱 솔루션",
      twitterTitle: "인도 시장 진출의 파트너 | 두루 컴플라이언스",
      twitterDescription: "인도 법인 설립부터 경영 지원까지 원스톱 서비스",
      locale: "ko_KR",
      siteName: "두루 컴플라이언스"
    }
  };

  const content = metaTags[isKorean ? 'KO' : 'EN'];

  // Generate appropriate schema based on page type
  const getSchema = () => {
    const baseSchema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": isKorean ? "두루 컴플라이언스" : "Duru Compliance",
      "url": baseUrl,
      "logo": `${baseUrl}/og-image.png`,
      "sameAs": [
        "https://www.linkedin.com/company/duru-compliance/",
        "https://www.facebook.com/durucompliance",
        "https://www.instagram.com/duru_compliance/"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91-900-817-7740",
        "contactType": "customer service",
        "availableLanguage": ["English", "Korean"]
      }
    };

    if (pageType === 'article' && article) {
      return {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": article.title,
        "description": article.description,
        "author": {
          "@type": "Person",
          "name": article.author
        },
        "datePublished": article.publishedTime,
        "dateModified": article.modifiedTime,
        "publisher": baseSchema,
        "keywords": article.tags.join(", "),
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": currentUrl
        }
      };
    }

    if (pageType === 'service' && service) {
      return {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": service.name,
        "description": service.description,
        "provider": {
          "@type": "Organization",
          "name": service.provider
        },
        "areaServed": service.areaServed,
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": currentUrl
        }
      };
    }

    return baseSchema;
  };

  return (
    <Helmet htmlAttributes={{ lang: isKorean ? 'ko' : 'en' }}>
      <title>{content.title}</title>
      <meta name="description" content={content.description} />
      <meta name="keywords" content={content.keywords} />
      
      {/* OpenGraph Tags */}
      <meta property="og:title" content={content.ogTitle} />
      <meta property="og:description" content={content.ogDescription} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content={pageType === 'article' ? 'article' : 'website'} />
      <meta property="og:site_name" content={content.siteName} />
      <meta property="og:locale" content={content.locale} />
      
      {/* Article-specific tags */}
      {pageType === 'article' && article && (
        <>
          <meta property="article:published_time" content={article.publishedTime} />
          <meta property="article:modified_time" content={article.modifiedTime} />
          <meta property="article:author" content={article.author} />
          {article.tags.map(tag => (
            <meta property="article:tag" content={tag} key={tag} />
          ))}
        </>
      )}
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={content.twitterTitle} />
      <meta name="twitter:description" content={content.twitterDescription} />
      <meta name="twitter:site" content={content.siteName} />
      
      {/* Canonical and Language Alternates */}
      <link rel="canonical" href={currentUrl} />
      <link rel="alternate" hrefLang={isKorean ? 'ko' : 'en'} href={currentUrl} />
      <link rel="alternate" hrefLang={isKorean ? 'en' : 'ko'} href={alternateUrl} />
      <link rel="alternate" hrefLang="x-default" href={baseUrl} />

      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(getSchema())}
      </script>
    </Helmet>
  );
};

export default SEOMetaTags;
