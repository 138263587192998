import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import { Pencil, Star, Trash2 } from 'lucide-react';
import type { GalleryImage } from '@/types/gallery';

interface GalleryImageGridProps {
  eventId: string;
}

const GalleryImageGrid = ({ eventId }: GalleryImageGridProps) => {
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    fetchImages();
  }, [eventId]);

  const fetchImages = async () => {
    try {
      const { data, error } = await supabase
        .from('gallery_images')
        .select('*')
        .eq('event_id', eventId)
        .order('display_order', { ascending: true });

      if (error) throw error;
      setImages(data || []);
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Failed to load images',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSetCover = async (imageId: string) => {
    try {
      // First, remove cover status from all images
      await supabase
        .from('gallery_images')
        .update({ is_cover: false })
        .eq('event_id', eventId);

      // Then set the selected image as cover
      const { error } = await supabase
        .from('gallery_images')
        .update({ is_cover: true })
        .eq('id', imageId);

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Cover image updated successfully',
      });

      fetchImages();
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    }
  };

  const handleDelete = async (imageId: string) => {
    if (!confirm('Are you sure you want to delete this image?')) return;

    try {
      const { error } = await supabase
        .from('gallery_images')
        .delete()
        .eq('id', imageId);

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Image deleted successfully',
      });

      fetchImages();
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    }
  };

  if (loading) {
    return <div>Loading images...</div>;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {images.map((image) => (
        <div key={image.id} className="relative group">
          <img
            src={image.image_url}
            alt={image.alt_text || 'Gallery image'}
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg flex items-center justify-center space-x-2">
            <Button
              size="icon"
              variant={image.is_cover ? "default" : "secondary"}
              onClick={() => handleSetCover(image.id)}
              title={image.is_cover ? "Current cover image" : "Set as cover"}
            >
              <Star className={`h-4 w-4 ${image.is_cover ? "text-yellow-400" : ""}`} />
            </Button>
            <Button
              size="icon"
              variant="destructive"
              onClick={() => handleDelete(image.id)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
          {image.is_cover && (
            <div className="absolute top-2 right-2 bg-yellow-400 text-black text-xs px-2 py-1 rounded">
              Cover
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GalleryImageGrid;