import { useState, useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";

interface Logo {
  id: string;
  name: string;
  logo_url: string;
  type: string;
  display_order: number;
}

export const useLogos = () => {
  const [clientLogos, setClientLogos] = useState<Logo[]>([]);
  const [governmentLogos, setGovernmentLogos] = useState<Logo[]>([]);
  const [partnerLogos, setPartnerLogos] = useState<Logo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const { data: logos, error: fetchError } = await supabase
          .from('logos')
          .select('*')
          .eq('is_active', true)
          .order('display_order', { ascending: true });

        if (fetchError) {
          console.error('Error fetching logos:', fetchError);
          setError('Failed to load logos');
          return;
        }

        if (logos) {
          setClientLogos(logos.filter(logo => logo.type.toLowerCase() === 'client'));
          setGovernmentLogos(logos.filter(logo => logo.type.toLowerCase() === 'government_relation'));
          setPartnerLogos(logos.filter(logo => logo.type.toLowerCase() === 'partner'));
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An unexpected error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogos();
  }, []);

  return { clientLogos, governmentLogos, partnerLogos, isLoading, error };
};