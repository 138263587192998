
import { Globe } from 'lucide-react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';

interface ExternalUrlSectionProps {
  externalUrl: string;
  setExternalUrl: (value: string) => void;
}

const ExternalUrlSection = ({ externalUrl, setExternalUrl }: ExternalUrlSectionProps) => {
  return (
    <div className="space-y-2">
      <Label className="flex items-center gap-2">
        <Globe className="w-4 h-4 text-gray-500" />
        External URL
      </Label>
      <Input
        placeholder="https://..."
        value={externalUrl}
        onChange={(e) => setExternalUrl(e.target.value)}
        className="border-gray-200 focus:border-gray-300 focus:ring-gray-200"
      />
    </div>
  );
};

export default ExternalUrlSection;
