
import { Paperclip } from 'lucide-react';
import { Editor } from '@tiptap/react';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/integrations/supabase/client';
import EditorToolbarButton from '../../EditorToolbarButton';
import { Input } from '@/components/ui/input';

interface FileUploadProps {
  editor: Editor;
  insightId?: string;
}

export const FileUpload = ({ editor, insightId }: FileUploadProps) => {
  const { toast } = useToast();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;
      const filePath = `attachments/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('insight_attachments')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('insight_attachments')
        .getPublicUrl(filePath);

      // Save file metadata to insight_images
      const { error: dbError } = await supabase
        .from('insight_images')
        .insert([{
          insight_id: insightId || null,
          image_url: publicUrl,
          title: file.name,
          alt_text: file.name,
          is_temporary: !insightId, // Mark as temporary if no insight ID exists
        }]);

      if (dbError) throw dbError;

      editor.chain().focus().insertContent({
        type: 'fileAttachment',
        attrs: {
          href: publicUrl,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        },
      }).run();

      toast({
        title: "Success",
        description: "File uploaded successfully",
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: "Error",
        description: "Failed to upload file",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="relative">
      <Input
        type="file"
        className="absolute inset-0 opacity-0 cursor-pointer"
        onChange={handleFileUpload}
      />
      <EditorToolbarButton
        onClick={() => {}}
        icon={Paperclip}
      />
    </div>
  );
};
