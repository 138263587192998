
import { Button } from "@/components/ui/button";
import { Edit, Trash2, Plus } from "lucide-react";

export const CreateButton = ({ onClick, children }: { onClick: () => void; children: React.ReactNode }) => (
  <Button 
    onClick={onClick} 
    className="bg-primary-light hover:bg-primary text-white flex items-center gap-2"
  >
    <Plus className="h-4 w-4" />
    {children}
  </Button>
);

export const EditButton = ({ onClick }: { onClick: () => void }) => (
  <Button 
    variant="ghost" 
    size="icon" 
    onClick={onClick}
    className="text-gray-600 hover:text-primary hover:bg-gray-100"
  >
    <Edit className="h-4 w-4" />
  </Button>
);

export const DeleteButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => (
  <Button 
    variant="ghost" 
    size="icon" 
    onClick={onClick}
    disabled={disabled}
    className="text-destructive hover:text-destructive hover:bg-red-50"
  >
    <Trash2 className="h-4 w-4" />
  </Button>
);

export const SubmitButton = ({ children }: { children: React.ReactNode }) => (
  <Button 
    type="submit"
    className="bg-primary-light hover:bg-primary text-white"
  >
    {children}
  </Button>
);

export const CancelButton = ({ onClick }: { onClick: () => void }) => (
  <Button 
    type="button" 
    variant="outline" 
    onClick={onClick}
    className="text-gray-600 hover:text-gray-800 hover:bg-gray-100"
  >
    Cancel
  </Button>
);

