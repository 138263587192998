import { motion } from "framer-motion";

interface GalleryHeaderProps {
  language: string;
}

const GalleryHeader = ({ language }: GalleryHeaderProps) => {
  return (
    <motion.section 
      className="pt-24 pb-12 bg-gradient-to-b from-primary/5 to-transparent"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-center"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            {language === 'KO' ? '비즈니스 이벤트 및 글로벌 활동' : 'Our Business Events & Global Presence'}
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            {language === 'KO' 
              ? '비즈니스 서밋, 파트너십, 기업 이정표를 통한 우리의 여정을 살펴보세요. Duru Compliance가 인도에서 글로벌 확장의 미래를 어떻게 형성하고 있는지 확인하세요.'
              : 'Explore our journey through business summits, partnerships, and corporate milestones. See how Duru Compliance is shaping the future of global expansion in India.'}
          </p>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default GalleryHeader;