import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import type { GalleryEvent, GalleryImage } from "@/types/gallery";

export interface EventWithImages extends GalleryEvent {
  images: GalleryImage[];
  coverImage: GalleryImage | null;
  imageCount: number | null;
}

export const useGalleryEvents = (selectedCategory: string | null) => {
  return useQuery({
    queryKey: ['gallery-events', selectedCategory],
    queryFn: async () => {
      let query = supabase
        .from('gallery_events')
        .select('*')
        .order('event_date', { ascending: false });

      if (selectedCategory) {
        query = query.eq('category', selectedCategory);
      }

      const { data: eventsData, error: eventsError } = await query;
      if (eventsError) throw eventsError;

      const eventsWithImages = await Promise.all(
        (eventsData || []).map(async (event) => {
          const { data: imagesData } = await supabase
            .from('gallery_images')
            .select('*')
            .eq('event_id', event.id)
            .order('display_order', { ascending: true });

          const { data: coverImageData } = await supabase
            .from('gallery_images')
            .select('*')
            .eq('event_id', event.id)
            .eq('is_cover', true)
            .maybeSingle();

          const { count } = await supabase
            .from('gallery_images')
            .select('*', { count: 'exact' })
            .eq('event_id', event.id);

          return {
            ...event,
            images: imagesData || [],
            coverImage: coverImageData,
            imageCount: count
          };
        })
      );

      return eventsWithImages as EventWithImages[];
    },
  });
};