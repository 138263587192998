import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import Logo from '@/components/navbar/Logo';
import DesktopMenu from '@/components/navbar/DesktopMenu';
import MobileMenu from '@/components/navbar/MobileMenu';
import MobileMenuButton from '@/components/navbar/MobileMenuButton';

interface NavbarProps {
  onLanguageChange: (lang: string) => void;
  initialLanguage: string;
}

const Navbar = ({ onLanguageChange, initialLanguage }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(initialLanguage);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100);

      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        const rect = servicesSection.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          setActiveSection('services');
        } else if (rect.top > 100) {
          setActiveSection('home');
        }
      }
    };

    if (location.pathname === '/') {
      // Add scroll event listener
      window.addEventListener('scroll', handleScroll, { passive: true });
      // Initial check
      handleScroll();

      // Cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setActiveSection('');
    }
  }, [location.pathname]);

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    onLanguageChange(newLanguage);
  };

  const isActive = (section: string) => {
    if (location.pathname === '/' && section === 'home' && activeSection === '') {
      return true;
    }
    return activeSection === section;
  };

  const isHeroSection = location.pathname === '/' && !isScrolled;

  return (
    <nav 
      className={cn(
        "fixed top-0 left-0 right-0 z-50 transition-all duration-300 w-screen min-w-full",
        isHeroSection ? "bg-transparent" : "bg-white shadow-sm"
      )}
    >
      <div className="w-screen min-w-full px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between w-full">
          <Logo />

          <DesktopMenu 
            language={language}
            onLanguageChange={handleLanguageChange}
            isActive={isActive}
            isHeroSection={isHeroSection}
          />

          <div className="md:hidden flex items-center">
            <MobileMenuButton 
              isOpen={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              isHeroSection={isHeroSection}
            />
          </div>
        </div>
      </div>

      <MobileMenu 
        isOpen={isOpen}
        language={language}
        onLanguageChange={handleLanguageChange}
        onClose={() => setIsOpen(false)}
      />
    </nav>
  );
};

export default Navbar;