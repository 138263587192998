
export const headerTranslations = {
  EN: {
    title: "Supporting Over 120 Korean Companies in India Since 2017",
    description: "Duru has earned its trusted status by achieving 100% success rate in helping Korean companies enter India, recognized as KOTRA Global Expert, NIPA GIP Consultant, and KOSME Global Business Partner."
  },
  KO: {
    title: "2017년부터 현재까지 120개 이상 한국 기업의 인도 진출을 지원",
    description: "두루는 한국 기업의 인도 진출 업무를 100% 성공적으로 수행함으로써 KOTRA 글로벌 지역전문가, NIPA GIP 컨설턴트, 중진공 글로벌 비즈니스 파트너로 활동 중인 신뢰할 수 있는 기업입니다."
  }
};

