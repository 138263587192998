export interface TestimonialsTranslations {
  title: string;
  subtitle: string;
  description: string;
  items: {
    content: string;
    author: string;
    company: string;
  }[];
  clients: {
    name: string;
    logo: string;
  }[];
  tabs: {
    clients: string;
    government: string;
    partners: string;
  };
  emptyStates: {
    clients: string;
    government: string;
    partners: string;
  };
}

export const testimonialsTranslations: Record<'EN' | 'KO', TestimonialsTranslations> = {
  EN: {
    title: "Our Global Network",
    subtitle: "Trusted by businesses worldwide",
    description: "Trusted by leading companies, governments, and partners worldwide in facilitating successful business ventures in India",
    items: [
      {
        content: "Their expertise in Indian market entry was invaluable. They guided us through every step of the process with professionalism and attention to detail.",
        author: "John Smith",
        company: "TechCorp International"
      },
      {
        content: "The team's knowledge of local regulations and business practices helped us avoid potential pitfalls and accelerated our market entry.",
        author: "Sarah Johnson",
        company: "Global Solutions Ltd"
      }
    ],
    clients: [
      {
        name: "TechCorp",
        logo: "/placeholder.svg"
      },
      {
        name: "Global Solutions",
        logo: "/placeholder.svg"
      }
    ],
    tabs: {
      clients: "Trusted Clients",
      government: "Government Relations",
      partners: "Strategic Partners"
    },
    emptyStates: {
      clients: "No client logos available.",
      government: "No government relation logos available.",
      partners: "No partner logos available."
    }
  },
  KO: {
    title: "글로벌 네트워크",
    subtitle: "전 세계 기업이 신뢰하는 파트너",
    description: "정부 기관, 대기업, 중소기업, 스타트업 모두에게 신뢰받는 기업",
    items: [
      {
        content: "인도 시장 진출에 대한 그들의 전문성은 매우 가치있었습니다. 전문성과 세심한 주의를 기울여 모든 과정을 안내해주었습니다.",
        author: "김정훈",
        company: "테크코프 인터내셔널"
      },
      {
        content: "현지 규정과 비즈니스 관행에 대한 팀의 지식은 잠재적인 위험을 피하고 시장 진출을 가속화하는 데 도움이 되었습니다.",
        author: "이미영",
        company: "글로벌 솔루션즈"
      }
    ],
    clients: [
      {
        name: "테크코프",
        logo: "/placeholder.svg"
      },
      {
        name: "글로벌 솔루션즈",
        logo: "/placeholder.svg"
      }
    ],
    tabs: {
      clients: "고객사",
      government: "정부 기관",
      partners: "전략적 파트너"
    },
    emptyStates: {
      clients: "고객사 로고가 없습니다.",
      government: "정부 기관 로고가 없습니다.",
      partners: "파트너 로고가 없습니다."
    }
  }
};
