import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/" className="flex items-center">
      <img 
        src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/dcl_logo/Duru-Compliance-Logo.png" 
        alt="DCL Logo" 
        className="h-10 w-auto"
      />
    </Link>
  );
};

export default Logo;