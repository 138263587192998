import React from 'react';
import { Building2, Globe2, Users } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import SectionHeader from './testimonials/SectionHeader';
import TabContent from './testimonials/TabContent';
import LoadingState from './testimonials/LoadingState';
import ErrorState from './testimonials/ErrorState';
import { useLogos } from '@/hooks/useLogos';
import { testimonialsTranslations } from '@/utils/translations/testimonials';

interface TestimonialsProps {
  language: string;
}

const Testimonials = ({ language }: TestimonialsProps) => {
  const { clientLogos, governmentLogos, partnerLogos, isLoading, error } = useLogos();
  const translations = testimonialsTranslations[language as keyof typeof testimonialsTranslations] || testimonialsTranslations.EN;

  if (isLoading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader 
          title={translations.title}
          description={translations.description}
        />

        <div className="flex justify-center w-full mb-12">
          <Tabs defaultValue="clients" className="w-full max-w-2xl">
            <TabsList className="w-full bg-white/5 border border-white/10">
              <TabsTrigger 
                value="clients" 
                className="flex-1 flex items-center gap-2 text-neutral-600 hover:bg-white/5 rounded-md px-4 py-2 
                           data-[state=active]:bg-secondary/10 data-[state=active]:text-secondary 
                           data-[state=active]:border data-[state=active]:border-secondary/30"
              >
                <Users className="w-4 h-4" />
                {translations.tabs.clients}
              </TabsTrigger>
              <TabsTrigger 
                value="government" 
                className="flex-1 flex items-center gap-2 text-neutral-600 hover:bg-white/5 rounded-md px-4 py-2
                           data-[state=active]:bg-secondary/10 data-[state=active]:text-secondary 
                           data-[state=active]:border data-[state=active]:border-secondary/30"
              >
                <Globe2 className="w-4 h-4" />
                {translations.tabs.government}
              </TabsTrigger>
              <TabsTrigger 
                value="partners" 
                className="flex-1 flex items-center gap-2 text-neutral-600 hover:bg-white/5 rounded-md px-4 py-2
                           data-[state=active]:bg-secondary/10 data-[state=active]:text-secondary 
                           data-[state=active]:border data-[state=active]:border-secondary/30"
              >
                <Building2 className="w-4 h-4" />
                {translations.tabs.partners}
              </TabsTrigger>
            </TabsList>

            <TabContent 
              value="clients"
              logos={clientLogos}
              emptyMessage={translations.emptyStates.clients}
            />
            <TabContent 
              value="government"
              logos={governmentLogos}
              emptyMessage={translations.emptyStates.government}
            />
            <TabContent 
              value="partners"
              logos={partnerLogos}
              emptyMessage={translations.emptyStates.partners}
            />
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;