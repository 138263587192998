
import { Input } from '@/components/ui/input';

interface TitleSectionProps {
  title_en: string;
  setTitleEn: (value: string) => void;
  title_ko: string;
  setTitleKo: (value: string) => void;
  isEnglish: boolean;
}

const TitleSection = ({ title_en, setTitleEn, title_ko, setTitleKo, isEnglish }: TitleSectionProps) => {
  return (
    <div className="space-y-4">
      <Input
        placeholder={isEnglish ? "English Title" : "한국어 제목"}
        value={isEnglish ? title_en : title_ko}
        onChange={(e) => isEnglish ? setTitleEn(e.target.value) : setTitleKo(e.target.value)}
        className="text-lg font-medium border-gray-200 focus:border-gray-300 focus:ring-gray-200"
      />
    </div>
  );
};

export default TitleSection;
