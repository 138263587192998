
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "@/utils/translations";

const NotFound = () => {
  const location = useLocation();
  const language = location.pathname.startsWith('/ko') ? 'KO' : 'EN';
  const t = useTranslation(language);

  useEffect(() => {
    // Log 404 errors for monitoring
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
  }, [location.pathname]);

  const baseUrl = 'https://durucompliance.com';
  const currentUrl = `${baseUrl}${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>{language === 'KO' ? '페이지를 찾을 수 없습니다' : 'Page Not Found'} | Duru Compliance</title>
        <meta name="robots" content="noindex,follow" />
        <meta name="description" content={language === 'KO' ? 
          '요청하신 페이지를 찾을 수 없습니다.' : 
          'The requested page could not be found.'
        } />
        <link rel="canonical" href={currentUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={language === 'KO' ? '페이지를 찾을 수 없습니다' : 'Page Not Found'} />
        <meta property="og:description" content={language === 'KO' ? 
          '요청하신 페이지를 찾을 수 없습니다.' : 
          'The requested page could not be found.'
        } />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Duru Compliance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": baseUrl
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "404 Not Found",
              "item": currentUrl
            }]
          })}
        </script>
        <meta name="http-status" content="404" />
      </Helmet>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center max-w-lg px-4">
          <h1 className="text-4xl font-bold mb-4">404</h1>
          <p className="text-xl text-gray-600 mb-6">
            {language === 'KO' ? 
              '요청하신 페이지를 찾을 수 없습니다.' : 
              'Oops! Page not found'
            }
          </p>
          <p className="text-gray-500 mb-8">
            {language === 'KO' ? 
              '페이지가 삭제되었거나 주소가 변경되었을 수 있습니다.' : 
              'The page you are looking for might have been removed or its address changed.'
            }
          </p>
          <a 
            href={language === 'KO' ? '/ko' : '/'} 
            className="text-blue-500 hover:text-blue-700 underline"
          >
            {language === 'KO' ? '홈으로 돌아가기' : 'Return to Home'}
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
