import { motion, AnimatePresence } from "framer-motion";
import EventCard from "./EventCard";
import { Skeleton } from "@/components/ui/skeleton";

interface GalleryGridProps {
  events: any[];
  isLoading: boolean;
  language: string;
  onEventClick: (event: any) => void;
}

const GalleryGrid = ({ events, isLoading, language, onEventClick }: GalleryGridProps) => {
  const renderSkeletons = () => (
    <>
      {[1, 2, 3].map((n) => (
        <div key={n} className="space-y-4">
          <Skeleton className="h-48 w-full rounded-lg" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-1/2" />
        </div>
      ))}
    </>
  );

  // Sort events by date in descending order (latest first)
  const sortedEvents = [...events].sort((a, b) => 
    new Date(b.event_date).getTime() - new Date(a.event_date).getTime()
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {isLoading ? (
        renderSkeletons()
      ) : (
        <AnimatePresence mode="wait">
          {sortedEvents.map((event, index) => (
            <motion.div
              key={event.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <EventCard
                event={event}
                language={language}
                onClick={() => onEventClick(event)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </div>
  );
};

export default GalleryGrid;