
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "@/utils/translations";
import { ceoTimelineTranslations } from "@/utils/translations/about/ceoTimeline";
import { Timeline, TimelineItem, TimelineSubItem } from "@/components/ui/timeline";

interface CeoMessageProps {
  language: string;
}

const CeoMessage = ({ language }: CeoMessageProps) => {
  const t = useTranslation(language);
  const timelineData = ceoTimelineTranslations[language as keyof typeof ceoTimelineTranslations];

  const scrollToExperts = () => {
    const expertsSection = document.getElementById('experts-section');
    if (expertsSection) {
      expertsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="ceo-message" className="relative bg-gradient-to-b from-neutral-900 to-neutral-800">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-16">
          {/* CEO Image - Left Column */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <img
              src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/team/ceo-yongjoo-yoo-duru-compliance.webp"
              alt={`${t.about.ceoMessage.ceoName} - ${t.about.ceoMessage.ceoTitle}`}
              className="w-full rounded-lg"
            />
          </motion.div>

          {/* CEO Message and Timeline - Right Column */}
          <div className="space-y-8">
            {/* CEO Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-bold text-white mb-6">
                {t.about.ceoMessage.title}
              </h2>
              
              <blockquote className="text-lg text-neutral-200 border-l-4 border-secondary pl-6 py-2 mb-6">
                "{t.about.ceoMessage.quote}"
              </blockquote>

              <h3 className="text-2xl font-bold text-white mt-4 mb-2">
                {t.about.ceoMessage.ceoName}
              </h3>
              <p className="text-xl text-white/90 mb-6">
                {t.about.ceoMessage.ceoTitle}
              </p>

              <Button 
                variant="outline" 
                className="group bg-transparent border-white text-white hover:bg-white/10"
                onClick={scrollToExperts}
              >
                {t.about.ceoMessage.cta}
                <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
              </Button>
            </motion.div>

            {/* Timeline Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-neutral-800/50 backdrop-blur-sm border border-neutral-700 rounded-lg shadow-xl p-6"
            >
              <h3 className="text-xl font-bold text-white mb-6">
                {timelineData.title}
              </h3>
              
              <div className="pr-4">
                <Timeline>
                  {timelineData.timeline.map((entry, index) => (
                    <TimelineItem
                      key={index}
                      year={entry.period}
                      title={entry.title}
                      description={entry.description}
                      isLast={index === 0}
                    >
                      {entry.subEntries && entry.subEntries.map((subEntry, subIndex) => (
                        <TimelineSubItem
                          key={subIndex}
                          year={subEntry.period}
                          description={subEntry.description}
                        />
                      ))}
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CeoMessage;
