// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://scmtfyjpaivqbafbtooy.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNjbXRmeWpwYWl2cWJhZmJ0b295Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzgwNTQxMzIsImV4cCI6MjA1MzYzMDEzMn0.lkceSscy77_tJkOIgG0oG70NM5YtpRqRIku0YxAUZ-Y";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);