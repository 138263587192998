
import { Eye, Heart, Printer } from "lucide-react";
import { Button } from "@/components/ui/button";
import InsightShareMenu from "./InsightShareMenu";

interface InsightHeaderProps {
  views_count?: number | null;
  likes_count?: number | null;
  isLiked: boolean;
  onLike: () => void;
  onShare: (platform: string) => void;
}

const InsightHeader = ({
  views_count,
  likes_count,
  isLiked,
  onLike,
  onShare,
}: InsightHeaderProps) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="flex items-center justify-between py-3 border-y border-gray-100">
      <div className="flex items-center gap-4 text-sm text-gray-600">
        <span className="flex items-center gap-1.5">
          <Eye className="w-4 h-4 stroke-[1.5]" aria-hidden="true" />
          <span>{views_count || 0} views</span>
        </span>
        <Button
          variant="ghost"
          size="sm"
          onClick={onLike}
          aria-label={isLiked ? "Unlike this article" : "Like this article"}
          aria-pressed={isLiked}
          className={`flex items-center gap-1.5 h-8 px-2 min-w-[64px] ${
            isLiked ? "text-primary" : "text-gray-600"
          }`}
        >
          <Heart 
            className={`w-4 h-4 stroke-[1.5] ${isLiked ? "fill-current" : ""}`}
            aria-hidden="true"
          />
          <span>{likes_count || 0}</span>
        </Button>
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          onClick={handlePrint}
          aria-label="Print this article"
          className="h-8 w-8 p-0 text-gray-600 hover:text-primary min-w-[32px]"
        >
          <Printer className="w-4 h-4 stroke-[1.5]" aria-hidden="true" />
        </Button>
        <InsightShareMenu onShare={onShare} />
      </div>
    </div>
  );
};

export default InsightHeader;
