import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface NavLinkProps {
  to: string;
  onClick: (e: React.MouseEvent) => void;
  isActive: boolean;
  className?: string;
  children: React.ReactNode;
}

const NavLink = ({ to, onClick, isActive, className, children }: NavLinkProps) => {
  const baseClass = cn(
    "hover:text-primary/80 transition-colors relative",
    "after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-0.5 after:bg-primary after:scale-x-0 after:transition-transform after:duration-200",
    "hover:after:scale-x-100",
    isActive && "after:scale-x-100 font-medium",
    className
  );

  return (
    <Link to={to} className={baseClass} onClick={onClick}>
      {children}
    </Link>
  );
};

export default NavLink;