import { Editor } from '@tiptap/react';
import { Bold, Italic } from 'lucide-react';
import EditorToolbarButton from '../EditorToolbarButton';

interface BasicFormattingProps {
  editor: Editor;
}

const BasicFormatting = ({ editor }: BasicFormattingProps) => {
  return (
    <>
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        icon={Bold}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
        icon={Italic}
      />
    </>
  );
};

export default BasicFormatting;