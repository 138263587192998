
import { useQuery } from "@tanstack/react-query";
import { Search } from "lucide-react";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import InsightCard from "@/components/insights/InsightCard";
import { blogTranslations } from "@/utils/translations/blog";
import Footer from "@/components/Footer";
import InsightTagFilter from "@/components/insights/InsightTagFilter";
import type { Insight } from "@/types/insight";

interface InsightsProps {
  language: string;
}

const Insights = ({ language }: InsightsProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const t = blogTranslations[language as keyof typeof blogTranslations];

  const { data: insights, isLoading } = useQuery({
    queryKey: ["insights"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("insights")
        .select("*")
        .eq("status", "published")  // Only fetch published insights
        .order("published_at", { ascending: false });

      if (error) throw error;
      return data as Insight[];
    },
  });

  const allTags = Array.from(
    new Set(insights?.flatMap((insight) => insight.tags) || [])
  ).sort();

  const filteredInsights = insights?.filter((insight) => {
    const matchesSearch =
      searchQuery === "" ||
      insight.title_en.toLowerCase().includes(searchQuery.toLowerCase()) ||
      insight.description_en.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesTags =
      selectedTags.length === 0 ||
      selectedTags.every((tag) => insight.tags.includes(tag));

    return matchesSearch && matchesTags;
  });

  const handleToggleTag = (tag: string) => {
    if (tag === 'all') {
      setSelectedTags([]);
      return;
    }
    
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-neutral flex flex-col">
      {/* Hero Banner Section */}
      <div className="relative bg-neutral-dark overflow-hidden">
        <div className="absolute inset-0">
          <img
            src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/banners/insights_banner.webp"
            alt="Insights Banner"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl font-bold text-[#1A1F2C] mb-6 animate-fade-up opacity-0 [animation-delay:200ms]">
              {t.title}
            </h1>
            <p className="text-xl text-[#403E43] max-w-2xl mx-auto mb-8 animate-fade-up opacity-0 [animation-delay:400ms]">
              {t.subtitle}
            </p>
            <div className="max-w-xl mx-auto animate-fade-up opacity-0 [animation-delay:600ms]">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#8E9196] h-5 w-5" />
                <Input 
                  type="search"
                  placeholder={t.searchPlaceholder}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 bg-[#F1F1F1] border-[#E5E5E5] text-[#333333] placeholder:text-[#8A898C] focus:bg-white focus:border-primary/20 focus:ring-primary/20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {allTags.length > 0 && (
          <InsightTagFilter
            tags={allTags}
            selectedTags={selectedTags}
            onToggleTag={handleToggleTag}
            language={language}
          />
        )}

        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {filteredInsights?.map((insight) => (
            <InsightCard 
              key={insight.id} 
              insight={insight}
              language={language}
            />
          ))}
        </div>

        {(!filteredInsights || filteredInsights.length === 0) && (
          <div className="text-center text-gray-500 py-12">
            {t.noResults}
          </div>
        )}
      </div>

      <Footer language={language} />
    </div>
  );
};

export default Insights;
