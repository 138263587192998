import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import InsightCard from "./InsightCard";
import type { Insight } from "@/types/insight";

interface SuggestedInsightsProps {
  currentInsightId: string;
}

const SuggestedInsights = ({ currentInsightId }: SuggestedInsightsProps) => {
  const { data: insights, isLoading } = useQuery({
    queryKey: ["suggested-insights", currentInsightId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("insights")
        .select("*")
        .neq("id", currentInsightId)
        .eq("is_published", true)
        .order("published_at", { ascending: false })
        .limit(3);

      if (error) throw error;
      return data as Insight[];
    },
  });

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[1, 2, 3].map((i) => (
          <div key={i} className="animate-pulse">
            <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
            <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  if (!insights?.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {insights.map((insight) => (
        <InsightCard key={insight.id} insight={insight} />
      ))}
    </div>
  );
};

export default SuggestedInsights;