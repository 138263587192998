
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import type { Insight } from '@/types/insight';
import InsightListItem from './editor/InsightListItem';

interface InsightListProps {
  insights: Insight[];
  onEdit: (insight: Insight) => void;
  onToggleVisibility: (insight: Insight) => void;
  onDelete: (insight: Insight) => void;
}

const InsightList = ({ insights, onEdit, onToggleVisibility, onDelete }: InsightListProps) => {
  const filteredInsights = (status: 'draft' | 'published' | 'unpublished') => {
    return insights?.filter(insight => insight.status === status) || [];
  };

  return (
    <div className="flex flex-col items-start">
      <div className="mb-6 w-full">
        <Tabs defaultValue="draft" className="w-full">
          <TabsList className="bg-background border-b border-b-muted p-0 h-12">
            <TabsTrigger 
              value="draft"
              className="data-[state=active]:bg-transparent data-[state=active]:border-primary data-[state=active]:border-b-2 rounded-none px-8 h-12"
            >
              Draft ({filteredInsights('draft').length})
            </TabsTrigger>
            <TabsTrigger 
              value="published"
              className="data-[state=active]:bg-transparent data-[state=active]:border-primary data-[state=active]:border-b-2 rounded-none px-8 h-12"
            >
              Published ({filteredInsights('published').length})
            </TabsTrigger>
            <TabsTrigger 
              value="unpublished"
              className="data-[state=active]:bg-transparent data-[state=active]:border-primary data-[state=active]:border-b-2 rounded-none px-8 h-12"
            >
              Unpublished ({filteredInsights('unpublished').length})
            </TabsTrigger>
          </TabsList>

          <TabsContent value="draft" className="mt-6">
            <div className="space-y-4">
              {filteredInsights('draft').map((insight) => (
                <InsightListItem 
                  key={insight.id} 
                  insight={insight}
                  onEdit={onEdit}
                  onToggleVisibility={onToggleVisibility}
                  onDelete={onDelete}
                />
              ))}
            </div>
          </TabsContent>

          <TabsContent value="published" className="mt-6">
            <div className="space-y-4">
              {filteredInsights('published').map((insight) => (
                <InsightListItem 
                  key={insight.id} 
                  insight={insight}
                  onEdit={onEdit}
                  onToggleVisibility={onToggleVisibility}
                  onDelete={onDelete}
                />
              ))}
            </div>
          </TabsContent>

          <TabsContent value="unpublished" className="mt-6">
            <div className="space-y-4">
              {filteredInsights('unpublished').map((insight) => (
                <InsightListItem 
                  key={insight.id} 
                  insight={insight}
                  onEdit={onEdit}
                  onToggleVisibility={onToggleVisibility}
                  onDelete={onDelete}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default InsightList;
