import { Editor } from '@tiptap/react';
import { AlignLeft, AlignCenter, AlignRight } from 'lucide-react';
import EditorToolbarButton from '../EditorToolbarButton';

interface AlignmentControlsProps {
  editor: Editor;
}

const AlignmentControls = ({ editor }: AlignmentControlsProps) => {
  return (
    <>
      <EditorToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        isActive={editor.isActive({ textAlign: 'left' })}
        icon={AlignLeft}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        isActive={editor.isActive({ textAlign: 'center' })}
        icon={AlignCenter}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        isActive={editor.isActive({ textAlign: 'right' })}
        icon={AlignRight}
      />
    </>
  );
};

export default AlignmentControls;