
import { Editor } from '@tiptap/react';
import { Link as LinkIcon, ExternalLink, Unlink } from 'lucide-react';
import { useState } from 'react';
import EditorToolbarButton from '@/components/editor/EditorToolbarButton';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface LinkControlsProps {
  editor: Editor;
}

const LinkControls = ({ editor }: LinkControlsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  const setLink = () => {
    if (!url) return;

    // Check if URL starts with http:// or https://, if not add https://
    const validUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;

    if (editor.isActive('link')) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: validUrl }).run();
    } else {
      editor.chain().focus().setLink({ href: validUrl }).run();
    }
    setIsOpen(false);
    setUrl('');
  };

  const removeLink = () => {
    editor.chain().focus().unsetLink().run();
  };

  return (
    <div className="flex items-center gap-1">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <div>
            <EditorToolbarButton
              onClick={() => {}}
              isActive={editor.isActive('link')}
              icon={LinkIcon}
            />
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Link</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="url">URL</Label>
              <Input
                id="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    setLink();
                  }
                }}
              />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={setLink}>Add Link</Button>
          </div>
        </DialogContent>
      </Dialog>

      {editor.isActive('link') && (
        <EditorToolbarButton
          onClick={removeLink}
          icon={Unlink}
          isActive={false}
        />
      )}
    </div>
  );
};

export default LinkControls;
