
export const callToActionTranslations = {
  EN: {
    title: "Considering Expansion into the Indian Market?",
    subtitle: "Let us help solve your concerns",
    cta: "Contact Us",
    contact: {
      phone: "+91 (123) 456-7890",
      email: "info@durucompliance.com"
    }
  },
  KO: {
    title: "인도 시장에서 귀사의 사업을 확장하세요",
    subtitle: "Duru의 한국인 전문가가 무료로 상담해드립니다.",
    cta: "문의하기",
    contact: {
      phone: "+91 (123) 456-7890",
      email: "info@durucompliance.com"
    }
  }
};
