import React from 'react';

interface SectionHeaderProps {
  title: string;
  description: string;
}

const SectionHeader = ({ title, description }: SectionHeaderProps) => {
  return (
    <div className="text-center space-y-4 mb-16">
      <h2 className="text-4xl font-bold text-neutral-dark">
        {title}
      </h2>
      <p className="text-lg text-neutral-600 max-w-2xl mx-auto">
        {description}
      </p>
    </div>
  );
};

export default SectionHeader;