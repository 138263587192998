
export const ceoTimelineTranslations = {
  EN: {
    title: "Career History",
    timeline: [
      {
        period: "2017-Present",
        title: "CEO, Duru Cooperation",
      },
      {
        period: "2008-2017",
        title: "Samsung Engineering",
        subEntries: [
          {
            period: "2016-2017",
            description: "Malaysia TGAST Project ($7.7B USD) - Head of Management Support"
          },
          {
            period: "2015-2016",
            description: "Head of Global Workforce Recruitment and Redeployment Team (43 sites), Management Support Office"
          },
          {
            period: "2012-2015",
            description: "UAE Borouge3 Project ($19.8B USD) - Head of JV Management Support"
          },
          {
            period: "2008-2012",
            description: "Environmental Design Team - Civil & Architectural Design"
          }
        ]
      },
      {
        period: "2000-2008",
        title: "Yonsei University",
        description: "Civil and Environmental Engineering"
      },
      {
        period: "1997-1999",
        title: "Gangneung High School"
      }
    ]
  },
  KO: {
    title: "대표자 이력",
    timeline: [
      {
        period: "2017-현재",
        title: "Duru Cooperation 대표",
      },
      {
        period: "2008-2017",
        title: "삼성엔지니어링",
        subEntries: [
          {
            period: "2016-2017",
            description: "Malaysia TGAST Project(7.7 Bil USD) 경영지원총괄"
          },
          {
            period: "2015-2016",
            description: "경영지원실 Global 인력 채용 및 재배치 팀장 (43개 현장)"
          },
          {
            period: "2012-2015",
            description: "UAE Borouge3 Project(19.8Bil USD) JV 경영지원총괄"
          },
          {
            period: "2008-2012",
            description: "환경설계팀 토목건축설계"
          }
        ]
      },
      {
        period: "2000-2008",
        title: "연세 대학교",
        description: "토목환경공학과"
      },
      {
        period: "1997-1999",
        title: "강릉 고등학교"
      }
    ]
  }
};
