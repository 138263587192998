import { motion } from "framer-motion";
import { Calendar, MapPin, Image } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";

interface EventCardProps {
  event: {
    id: string;
    title_en: string;
    title_ko: string | null;
    description_en: string;
    description_ko: string | null;
    event_date: string;
    location: string;
    category: string;
    coverImage?: {
      image_url: string;
      alt_text: string | null;
    };
    imageCount?: number;
  };
  language: string;
  onClick: () => void;
}

const EventCard = ({ event, language, onClick }: EventCardProps) => {
  const title = language === 'KO' && event.title_ko ? event.title_ko : event.title_en;
  const description = language === 'KO' && event.description_ko ? event.description_ko : event.description_en;

  // Format the date
  const formattedDate = format(new Date(event.event_date), 'MMM d, yyyy');

  return (
    <div
      className="group cursor-pointer h-full"
      onClick={onClick}
    >
      <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 h-full hover:shadow-lg hover:translate-y-[-4px] flex flex-col">
        <div className="relative w-full pt-[56.25%]"> {/* 16:9 aspect ratio */}
          {event.coverImage ? (
            <>
              <img
                src={event.coverImage.image_url}
                alt={event.coverImage.alt_text || title}
                className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                loading="lazy"
              />
              {event.imageCount && event.imageCount > 0 && (
                <div className="absolute bottom-3 right-3">
                  <Badge variant="secondary" className="flex items-center gap-1 bg-black/70 text-white">
                    <Image className="w-4 h-4" />
                    {event.imageCount}
                  </Badge>
                </div>
              )}
            </>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center">
              <span className="text-gray-400">No image available</span>
            </div>
          )}
        </div>
        
        <div className="p-6 flex flex-col flex-grow">
          <h3 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2 min-h-[3.5rem]">
            {title}
          </h3>
          
          <div className="space-y-2 mb-4">
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Calendar className="w-4 h-4 flex-shrink-0" />
              <span>{formattedDate}</span>
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <MapPin className="w-4 h-4 flex-shrink-0" />
              <span className="line-clamp-1">{event.location}</span>
            </div>
          </div>
          
          <p className="text-sm text-gray-600 mb-4 line-clamp-3 flex-grow">
            {description}
          </p>
          
          <Button
            variant="outline"
            className="w-full mt-auto"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {language === 'KO' ? '이벤트 보기' : 'View Event'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;