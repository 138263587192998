import { Button } from "@/components/ui/button";
import { motion, AnimatePresence } from "framer-motion";

interface InsightTagFilterProps {
  tags: string[];
  selectedTags: string[];
  onToggleTag: (tag: string) => void;
  language: string;
}

const InsightTagFilter = ({
  tags,
  selectedTags,
  onToggleTag,
  language
}: InsightTagFilterProps) => {
  return (
    <motion.div 
      className="flex flex-wrap gap-2 justify-center mb-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key="all"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
        >
          <Button
            variant={selectedTags.length === 0 ? "default" : "outline"}
            onClick={() => onToggleTag('all')}
            className={`min-w-[100px] ${
              selectedTags.length === 0
                ? 'bg-white text-primary border-2 border-primary hover:bg-primary/5' 
                : 'bg-white text-neutral-600 border hover:bg-neutral-50'
            }`}
          >
            {language === 'KO' ? '전체' : 'All'}
          </Button>
        </motion.div>
        {tags.map((tag) => (
          <motion.div
            key={tag}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <Button
              variant={selectedTags.includes(tag) ? "default" : "outline"}
              onClick={() => onToggleTag(tag)}
              className={`min-w-[100px] ${
                selectedTags.includes(tag)
                  ? 'bg-white text-primary border-2 border-primary hover:bg-primary/5' 
                  : 'bg-white text-neutral-600 border hover:bg-neutral-50'
              }`}
            >
              {tag}
            </Button>
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default InsightTagFilter;