
import { Link } from "react-router-dom";
import { ArrowRight, BookOpen } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { blogTranslations } from "@/utils/translations/blog";
import type { Insight } from "@/types/insight";

interface BlogPreviewProps {
  language: string;
}

const BlogPreview = ({ language }: BlogPreviewProps) => {
  const t = blogTranslations[language as keyof typeof blogTranslations];

  const { data: insights, isLoading } = useQuery({
    queryKey: ["latest-insights"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("insights")
        .select("*")
        .eq("is_published", true)
        .order("published_at", { ascending: false })
        .limit(3);

      if (error) throw error;
      return data as Insight[];
    },
  });

  if (isLoading) {
    return (
      <section className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-gray-200 rounded w-1/3 mx-auto" />
            <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto" />
            <div className="grid md:grid-cols-3 gap-8">
              {[1, 2, 3].map((item) => (
                <div key={item} className="space-y-4">
                  <div className="h-48 bg-gray-200 rounded" />
                  <div className="h-6 bg-gray-200 rounded w-3/4" />
                  <div className="h-4 bg-gray-200 rounded w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-neutral-dark mb-4">
            {t.latestSection.title}
          </h2>
          <p className="text-lg text-gray-600">
            {t.latestSection.subtitle}
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {insights?.map((insight) => (
            <div key={insight.id} className="group">
              <div className="relative overflow-hidden rounded-lg mb-4">
                <img
                  src={insight.thumbnail_url || "https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7?auto=format&fit=crop&q=80&w=2070"}
                  alt={language === 'KO' && insight.title_ko ? insight.title_ko : insight.title_en}
                  className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-primary/10 group-hover:bg-primary/0 transition-colors" />
              </div>
              <h3 className="text-xl font-semibold text-neutral-dark mb-2">
                {language === 'KO' && insight.title_ko ? insight.title_ko : insight.title_en}
              </h3>
              <p className="text-gray-600 mb-4 line-clamp-2">
                {language === 'KO' ? insight.description_ko : insight.description_en}
              </p>
              <Link
                to={`/insights/${insight.slug}`}
                className="text-primary hover:text-primary-dark transition-colors inline-flex items-center gap-1"
                aria-label={language === 'KO' ? 
                  `${insight.title_ko} 자세히 보기` : 
                  `Read more about ${insight.title_en}`
                }
              >
                {language === 'KO' ? 
                  `${insight.title_ko} 자세히 보기` : 
                  `Read more about ${insight.title_en}`
                } <ArrowRight className="w-4 h-4" />
              </Link>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Link
            to="/insights"
            className="inline-flex items-center gap-2 px-6 py-3 bg-primary text-white rounded-full hover:bg-primary-dark transition-colors"
            aria-label={language === 'KO' ? "모든 인사이트 보기" : "View all insights and articles"}
          >
            <BookOpen className="w-5 h-5" />
            {language === 'KO' ? "모든 인사이트 보기" : "View all insights and articles"}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogPreview;
