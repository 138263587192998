import { useEffect } from "react";
import Footer from "@/components/Footer";
import ContactInfo from "@/components/contact/ContactInfo";
import ContactForm from "@/components/contact/ContactForm";
import { useTranslation } from "@/utils/translations";

interface ContactProps {
  language: string;
}

const Contact = ({ language }: ContactProps) => {
  const t = useTranslation(language);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="min-h-screen bg-white">
        <section className="relative z-10 py-24 md:py-32">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <span className="text-primary uppercase tracking-wider text-sm font-medium">
                {t.contact.badge}
              </span>
              <h1 className="text-4xl md:text-5xl font-bold text-neutral-dark mt-4">
                {t.contact.title}
              </h1>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto mt-4">
                {t.contact.subtitle}
              </p>
            </div>
            
            <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 mt-12">
              <ContactInfo language={language} />
              <ContactForm language={language} />
            </div>
          </div>
        </section>
      </main>
      <Footer language={language} />
    </>
  );
};

export default Contact;