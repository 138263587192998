import { ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import type { Insight } from "@/types/insight";

interface InsightCardHeaderProps {
  insight: Insight;
  language?: string;
}

const InsightCardHeader = ({ insight, language = 'EN' }: InsightCardHeaderProps) => {
  const displayTitle = language === 'KO' && insight.title_ko 
    ? insight.title_ko 
    : insight.title_en;

  const displayDescription = language === 'KO' && insight.description_ko
    ? insight.description_ko
    : insight.description_en;

  return (
    <div className="text-left">
      {insight.external_url ? (
        <a
          href={insight.external_url}
          target="_blank"
          rel="noopener noreferrer"
          className="group"
        >
          <h3 className="text-lg font-bold text-gray-900 mb-1.5 leading-tight group-hover:text-primary transition-colors">
            {displayTitle}
            <ExternalLink className="inline-block ml-1.5 w-3.5 h-3.5 opacity-0 group-hover:opacity-100 transition-opacity" />
          </h3>
        </a>
      ) : (
        <Link to={`/insights/${insight.slug}`} className="group">
          <h3 className="text-lg font-bold text-gray-900 mb-1.5 leading-tight group-hover:text-primary transition-colors">
            {displayTitle}
          </h3>
        </Link>
      )}
      
      {insight.published_at && (
        <span className="block text-xs text-gray-500 mb-2">
          Published on {format(new Date(insight.published_at), 'MMM d, yyyy')}
        </span>
      )}
      
      <p className="text-sm text-gray-600 line-clamp-2 mb-3">{displayDescription}</p>
    </div>
  );
};

export default InsightCardHeader;
