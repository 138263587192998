
import { useEffect } from 'react';
import { InsightEditorProvider } from './insights/editor/InsightEditorContext';
import EditorLayout from './insights/editor/EditorLayout';
import MainSection from './insights/editor/MainSection';
import MetadataSidebar from './insights/editor/MetadataSidebar';
import ContentEditor from './insights/ContentEditor';
import ActionButtons from './insights/ActionButtons';
import ExternalUrlToggle from './insights/ExternalUrlToggle';
import StatusIndicator from './insights/StatusIndicator';
import { useInsightActions } from './insights/editor/useInsightActions';
import { useInsightEditor } from './insights/editor/InsightEditorContext';
import type { InsightFormData } from '@/types/insight';

interface InsightEditorProps {
  onSave: (data: InsightFormData) => Promise<void>;
  initialData?: Partial<InsightFormData>;
}

const AUTO_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

const InsightEditorContent = ({ onSave }: InsightEditorProps) => {
  const { isSaving, handleSave } = useInsightActions({ onSave });
  const {
    thumbnail_url,
    setThumbnailUrl,
    isExternal,
    setIsExternal,
    currentLanguage,
    content_en,
    content_ko,
    setContentEn,
    setContentKo,
    title_en,
    setTitleEn,
    title_ko,
    setTitleKo,
    description_en,
    setDescriptionEn,
    description_ko,
    setDescriptionKo,
    meta_description_en,
    setMetaDescriptionEn,
    meta_description_ko,
    setMetaDescriptionKo,
    meta_keywords_en,
    meta_keywords_ko,
    external_url,
    setExternalUrl,
    tags,
    setTags,
    newTag,
    setNewTag,
    handleAddTag,
    handleRemoveTag,
    newKeyword,
    setNewKeyword,
    handleAddKeyword,
    handleRemoveKeyword,
    setCurrentLanguage,
    status,
  } = useInsightEditor();

  // Auto-save feature
  useEffect(() => {
    const autoSaveTimer = setInterval(() => {
      if (status === 'draft') {
        handleSave('draft');
      }
    }, AUTO_SAVE_INTERVAL);

    return () => clearInterval(autoSaveTimer);
  }, [status, handleSave]);

  return (
    <div className="min-h-screen bg-neutral">
      <EditorLayout>
        <div className="bg-white">
          <div className="px-8 py-6 border-b border-gray-100 flex justify-between items-center">
            <ExternalUrlToggle isExternal={isExternal} onToggle={setIsExternal} />
            <StatusIndicator status={status} />
          </div>
          
          <div className="px-8 py-6 space-y-8">
            <MainSection 
              title_en={title_en}
              setTitleEn={setTitleEn}
              title_ko={title_ko}
              setTitleKo={setTitleKo}
              description_en={description_en}
              setDescriptionEn={setDescriptionEn}
              description_ko={description_ko}
              setDescriptionKo={setDescriptionKo}
              meta_description_en={meta_description_en}
              setMetaDescriptionEn={setMetaDescriptionEn}
              meta_description_ko={meta_description_ko}
              setMetaDescriptionKo={setMetaDescriptionKo}
              meta_keywords_en={meta_keywords_en}
              meta_keywords_ko={meta_keywords_ko}
              isExternal={isExternal}
              external_url={external_url}
              setExternalUrl={setExternalUrl}
              tags={tags}
              setTags={setTags}
              newTag={newTag}
              setNewTag={setNewTag}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              newKeyword={newKeyword}
              setNewKeyword={setNewKeyword}
              handleAddKeyword={handleAddKeyword}
              handleRemoveKeyword={handleRemoveKeyword}
              currentLanguage={currentLanguage}
              onLanguageChange={setCurrentLanguage}
            />
          </div>

          {!isExternal && (
            <div className="px-8 py-12 border-t border-gray-100">
              <ContentEditor 
                content={currentLanguage === 'en' ? content_en : content_ko} 
                onChange={currentLanguage === 'en' ? setContentEn : setContentKo}
                currentLanguage={currentLanguage}
              />
            </div>
          )}

          <div className="px-8 py-6 border-t border-gray-100">
            <MetadataSidebar
              thumbnailUrl={thumbnail_url}
              setThumbnailUrl={setThumbnailUrl}
            />
          </div>

          <ActionButtons 
            status={status}
            isSaving={isSaving}
            onSave={handleSave}
          />
        </div>
      </EditorLayout>
    </div>
  );
};

const InsightEditor = (props: InsightEditorProps) => {
  return (
    <InsightEditorProvider initialData={props.initialData}>
      <InsightEditorContent {...props} />
    </InsightEditorProvider>
  );
};

export default InsightEditor;
