import { Button } from "@/components/ui/button";
import { motion, AnimatePresence } from "framer-motion";

interface CategoryFilterProps {
  categories: string[];
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
  language: string;
}

const CategoryFilter = ({
  categories,
  selectedCategory,
  onSelectCategory,
  language
}: CategoryFilterProps) => {
  const getCategoryLabel = (category: string) => {
    const labels: Record<string, { EN: string; KO: string }> = {
      'government': { EN: 'Government & Policy', KO: '정부 및 정책' },
      'business': { EN: 'Business Conferences', KO: '비즈니스 컨퍼런스' },
      'networking': { EN: 'Networking Events', KO: '네트워킹 이벤트' },
      'internal': { EN: 'Internal Events', KO: '내부 행사' },
      'awards': { EN: 'Awards & Recognition', KO: '수상 및 인정' }
    };
    return labels[category]?.[language] || category;
  };

  return (
    <motion.div 
      className="flex flex-wrap gap-2 justify-center mb-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key="all"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
        >
          <Button
            variant={selectedCategory === null ? "default" : "outline"}
            onClick={() => onSelectCategory(null)}
            className={`min-w-[100px] ${
              selectedCategory === null 
                ? 'bg-white text-primary border-2 border-primary hover:bg-primary/5' 
                : 'bg-white text-neutral-600 border hover:bg-neutral-50'
            }`}
          >
            {language === 'KO' ? '전체' : 'All'}
          </Button>
        </motion.div>
        {categories.map((category) => (
          <motion.div
            key={category}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <Button
              variant={selectedCategory === category ? "default" : "outline"}
              onClick={() => onSelectCategory(category)}
              className={`min-w-[100px] ${
                selectedCategory === category 
                  ? 'bg-white text-primary border-2 border-primary hover:bg-primary/5' 
                  : 'bg-white text-neutral-600 border hover:bg-neutral-50'
              }`}
            >
              {getCategoryLabel(category)}
            </Button>
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default CategoryFilter;