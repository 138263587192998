
import React from "react";
import { motion } from "framer-motion";
import { Rocket, Search, Handshake, Globe } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { whyChooseTranslations } from "@/utils/translations/whyChoose";

interface WhyChooseSectionProps {
  language: string;
}

const WhyChooseSection = ({ language }: WhyChooseSectionProps) => {
  const navigate = useNavigate();
  const t = whyChooseTranslations[language as keyof typeof whyChooseTranslations];

  const icons = [
    { Icon: Rocket, color: "text-blue-500" },
    { Icon: Search, color: "text-green-500" },
    { Icon: Handshake, color: "text-orange-500" },
    { Icon: Globe, color: "text-purple-500" }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-neutral-dark mb-4">
            {t.title}
          </h2>
          <p className="text-lg text-neutral-600 max-w-3xl mx-auto">
            {t.subtitle}
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {t.differentiators.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.5,
                delay: index * 0.1
              }}
              className="bg-neutral rounded-xl p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-neutral-100 transform hover:-translate-y-1"
            >
              <div className={`${icons[index].color} mb-4`}>
                {React.createElement(icons[index].Icon, { size: 32, strokeWidth: 1.5 })}
              </div>
              <h3 className="text-xl font-semibold text-neutral-dark mb-3">
                {item.title}
              </h3>
              <p className="text-neutral-600 text-sm leading-relaxed">
                {item.description}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-center"
        >
          <Button
            onClick={() => navigate('/contact')}
            size="lg"
            className="bg-primary hover:bg-primary-light text-white font-medium shadow-md hover:shadow-lg transition-all duration-300"
          >
            {t.cta}
          </Button>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyChooseSection;
