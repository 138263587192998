import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Puzzle } from "lucide-react";
import { useTranslation } from "@/utils/translations";
import { Link } from "react-router-dom";

const CallToActionCard = ({ language }: { language: string }) => {
  const t = useTranslation(language);

  return (
    <Card className="h-full bg-primary text-white transition-all duration-300 hover:shadow-xl">
      <CardHeader>
        <div className="flex items-center gap-3 mb-2">
          <Puzzle className="w-8 h-8" />
          <CardTitle className="text-2xl">{t.about.callToAction.title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col justify-between h-[calc(100%-8rem)]">
        <p className="text-base text-white/90 mb-8">
          {t.about.callToAction.subtitle}
        </p>
        <Button 
          variant="secondary" 
          className="w-full text-primary hover:text-primary-dark"
          asChild
        >
          <Link to="/contact">
            {t.about.callToAction.cta}
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </Button>
      </CardContent>
    </Card>
  );
};

export default CallToActionCard;