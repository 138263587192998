import { motion } from "framer-motion";
import { ArrowRight, Flag } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { BorderBeam } from "@/components/ui/border-beam";
import { useTranslation } from "@/utils/translations";

interface HeroMainContentProps {
  language: string;
}

const HeroMainContent = ({ language }: HeroMainContentProps) => {
  const t = useTranslation(language);

  const BadgeContent = () => (
    <div className="flex items-center gap-2 justify-center lg:justify-start">
      <motion.div
        animate={{ rotateY: [0, 360] }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      >
        <Flag className="h-3 w-3 md:h-4 md:w-4 text-orange-500" />
      </motion.div>
      <span className="text-sm md:text-base">{t.hero.badge}</span>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto lg:mx-0">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="inline-flex items-center gap-2 px-2 py-1 md:px-3 md:py-1.5 rounded-full bg-primary/[0.03] border border-primary/[0.08] mb-6 md:mb-8 text-base"
      >
        <BadgeContent />
      </motion.div>

      <motion.h1 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold mb-8 tracking-tight leading-[1.1]"
      >
        {language === "KO" ? (
          <>
            <div className="flex flex-col gap-2">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-light via-primary to-secondary">
                {t.hero.title}
              </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-light via-primary to-secondary">
                {t.hero.titleHighlight}
              </span>
            </div>
          </>
        ) : (
          <>
            <span className="bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary/80">
              {t.hero.title}
            </span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-light via-primary to-secondary">
              {t.hero.titleHighlight}
              </span>
          </>
        )}
      </motion.h1>

      <motion.p 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="text-lg md:text-xl lg:text-2xl text-primary/60 mb-12 leading-relaxed max-w-3xl mx-auto lg:mx-0"
      >
        {t.hero.subtitle}
      </motion.p>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
        className="flex flex-col sm:flex-row items-center lg:items-start gap-4 justify-center lg:justify-start"
      >
        <Link to="/contact" className="relative">
          <Button 
            size="lg"
            className="bg-primary hover:bg-primary-dark text-white font-medium px-6 py-5 md:px-8 md:py-7 rounded-full transition-all duration-300 transform hover:scale-105 text-base md:text-lg"
          >
            {language === "KO" ? "전문가와 상담하기" : "Talk to an Expert"}
            <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5" />
          </Button>
          <BorderBeam size={200} duration={8} delay={0} />
        </Link>
        <Link to="/about" className="relative">
          <Button 
            variant="outline" 
            size="lg"
            className="border-primary/20 text-primary hover:bg-primary/5 hover:border-primary/30 font-medium px-6 py-5 md:px-8 md:py-7 rounded-full transition-all duration-300 backdrop-blur-sm text-base md:text-lg"
          >
            {language === "KO" ? "자세히 알아보기" : "Learn More"}
          </Button>
          <BorderBeam size={200} duration={8} delay={2} />
        </Link>
      </motion.div>
    </div>
  );
};

export default HeroMainContent;