
import { CreateButton } from '@/components/admin/AdminButtonStyles';

interface InsightHeaderProps {
  onCreateNew: () => void;
}

const InsightHeader = ({ onCreateNew }: InsightHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Insights</h2>
      <CreateButton onClick={onCreateNew}>
        Create New
      </CreateButton>
    </div>
  );
};

export default InsightHeader;
