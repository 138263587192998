import { FormTips } from './tips/FormTips';
import { BasicFields } from './fields/BasicFields';
import { DetailFields } from './fields/DetailFields';
import type { UseFormReturn } from 'react-hook-form';

interface EventFormFieldsProps {
  form: UseFormReturn<any>;
  section: 'basic' | 'details';
}

export const EventFormFields = ({ form, section }: EventFormFieldsProps) => {
  return (
    <div className="space-y-4">
      <FormTips section={section} />
      {section === 'basic' ? (
        <BasicFields form={form} />
      ) : (
        <DetailFields form={form} />
      )}
    </div>
  );
};