
import { useEditor, EditorContent } from '@tiptap/react';
import { Label } from '@/components/ui/label';
import EditorToolbar from '@/components/editor/EditorToolbar';
import BubbleToolbar from '@/components/editor/BubbleToolbar';
import { getExtensions, editorStyles } from '@/components/editor/editorConfig';
import { useEffect, useRef } from 'react';

interface ContentEditorProps {
  content: string;
  onChange: (content: string) => void;
  currentLanguage?: 'en' | 'ko';
  insightId?: string;
}

const ContentEditor = ({ content, onChange, currentLanguage = 'en', insightId }: ContentEditorProps) => {
  const updateListenerEnabled = useRef(true);
  const lastSelection = useRef<{from: number; to: number} | null>(null);
  const scrollPosition = useRef<number>(0);

  const editor = useEditor({
    extensions: getExtensions(currentLanguage),
    content: content || '<p></p>',
    onUpdate: ({ editor }) => {
      if (updateListenerEnabled.current) {
        if (editor.state.selection) {
          lastSelection.current = {
            from: editor.state.selection.from,
            to: editor.state.selection.to
          };
        }
        onChange(editor.getHTML());
      }
    },
    editorProps: {
      attributes: {
        class: 'min-h-[400px] w-full prose prose-sm sm:prose lg:prose-lg xl:prose-xl focus:outline-none prose-placeholder:text-gray-400 prose-placeholder:font-normal prose-p:my-3',
      },
    },
  });

  // Save scroll position before language change
  useEffect(() => {
    scrollPosition.current = window.scrollY;
  }, [currentLanguage]);

  // Restore scroll position after content update
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      updateListenerEnabled.current = false;
      editor.chain().focus().setContent(content || '<p></p>', false).run();
      if (lastSelection.current) {
        editor.commands.setTextSelection(lastSelection.current);
      }
      updateListenerEnabled.current = true;
      
      // Restore scroll position after a brief delay to ensure content is rendered
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollPosition.current);
      });
    }
  }, [editor, content]);

  useEffect(() => {
    if (editor) {
      editor.setOptions({
        extensions: getExtensions(currentLanguage),
      });
    }
  }, [editor, currentLanguage]);

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="space-y-4">
      <Label className="text-lg font-semibold flex items-center gap-2">
        {currentLanguage === 'en' ? 'Content' : '내용'}
      </Label>
      <div className="border rounded-lg overflow-hidden bg-white">
        <EditorToolbar editor={editor} insightId={insightId} />
        <style>{editorStyles}</style>
        <EditorContent editor={editor} />
        <BubbleToolbar editor={editor} />
      </div>
    </div>
  );
};

export default ContentEditor;

