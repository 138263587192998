import { useState, useMemo } from "react";
import Footer from "@/components/Footer";
import GalleryHeader from "@/components/gallery/GalleryHeader";
import CategoryFilter from "@/components/gallery/CategoryFilter";
import GalleryGrid from "@/components/gallery/GalleryGrid";
import EventPreviewDialog from "@/components/gallery/EventPreviewDialog";
import GallerySEO from "@/components/gallery/GallerySEO";
import { categories } from "@/components/admin/gallery/constants";
import { useGalleryEvents, type EventWithImages } from "@/hooks/useGalleryEvents";

interface GalleryProps {
  language: string;
}

const Gallery = ({ language }: GalleryProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventWithImages | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { data: events = [], isLoading } = useGalleryEvents(selectedCategory);

  // Get event images when an event is selected
  const eventImages = useMemo(() => {
    if (!selectedEvent) return [];
    return selectedEvent.images || [];
  }, [selectedEvent]);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? eventImages.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === eventImages.length - 1 ? 0 : prev + 1
    );
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
    setCurrentImageIndex(0);
  };

  return (
    <main className="min-h-screen">
      <GallerySEO language={language} events={events} />
      <GalleryHeader language={language} />

      <section className="py-12">
        <div className="container mx-auto px-4">
          <CategoryFilter
            categories={categories.map(c => c.value)}
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
            language={language}
          />

          <GalleryGrid
            events={events}
            isLoading={isLoading}
            language={language}
            onEventClick={setSelectedEvent}
          />
        </div>
      </section>

      <EventPreviewDialog
        event={selectedEvent}
        language={language}
        images={eventImages}
        currentImageIndex={currentImageIndex}
        onClose={handleCloseDialog}
        onPreviousImage={handlePreviousImage}
        onNextImage={handleNextImage}
      />

      <Footer language={language} />
    </main>
  );
};

export default Gallery;