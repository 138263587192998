import React from 'react';

const LoadingState = () => {
  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-neutral-600">Loading logos...</p>
        </div>
      </div>
    </section>
  );
};

export default LoadingState;