
export const validateInsightFields = (
  action: 'draft' | 'publish' | 'unpublish' | 'delete',
  {
    title_en,
    title_ko,
    description_en,
    description_ko,
    meta_description_en,
    meta_description_ko,
    meta_keywords_en,
    meta_keywords_ko,
    tags,
  }: {
    title_en: string;
    title_ko: string | null;
    description_en: string;
    description_ko: string | null;
    meta_description_en: string;
    meta_description_ko: string | null;
    meta_keywords_en: string[];
    meta_keywords_ko: string[];
    tags: string[];
  }
) => {
  if (action === 'publish') {
    if (!title_en && !title_ko) {
      throw new Error('At least one title (English or Korean) is required');
    }

    if (!description_en && !description_ko) {
      throw new Error('At least one description is required');
    }

    if (!meta_description_en && !meta_description_ko) {
      throw new Error('At least one meta description is required');
    }

    const hasEnKeywords = Array.isArray(meta_keywords_en) && meta_keywords_en.length > 0;
    const hasKoKeywords = Array.isArray(meta_keywords_ko) && meta_keywords_ko.length > 0;
    if (hasEnKeywords !== hasKoKeywords) {
      throw new Error('Meta keywords must be provided for both languages or neither');
    }

    if (!Array.isArray(tags) || tags.length === 0) {
      throw new Error('At least one tag is required');
    }
  } else if (action !== 'delete') {
    // For draft and unpublish, still require at least one title
    if (!title_en && !title_ko) {
      throw new Error('At least one title (English or Korean) is required');
    }
  }
};
