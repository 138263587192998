
import * as React from "react"

interface TimelineProps {
  children: React.ReactNode
}

const Timeline = ({ children }: TimelineProps) => {
  return (
    <div className="space-y-6 relative">
      <div className="absolute left-3.5 top-3 h-[calc(100%-24px)] w-px bg-neutral-600" />
      {children}
    </div>
  )
}

interface TimelineItemProps {
  title: string
  year: string
  description?: string
  isLast?: boolean
  children?: React.ReactNode
}

const TimelineItem = ({ title, year, description, isLast, children }: TimelineItemProps) => {
  return (
    <div className="relative pl-8">
      <div className="absolute left-0 flex items-center justify-center">
        <div 
          className={`w-7 h-7 rounded-full border-2 flex items-center justify-center ${
            isLast 
              ? "border-secondary bg-secondary" 
              : "border-neutral-600 bg-neutral-800 hover:border-neutral-500 transition-colors"
          }`}
        >
          <div className={`w-3 h-3 rounded-full ${isLast ? "bg-white" : "bg-neutral-600"}`} />
        </div>
      </div>
      <div>
        <div className="flex items-start gap-3">
          <span className="text-base font-semibold text-neutral-400 min-w-[100px]">
            {year}
          </span>
          <div className="flex-1">
            <h3 className="text-base font-semibold text-white">
              {title}
            </h3>
            {description && (
              <p className="mt-2 text-sm text-neutral-300">
                {description}
              </p>
            )}
          </div>
        </div>
        {children && (
          <div className="mt-4 space-y-3">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

interface TimelineSubItemProps {
  year: string
  description: string
}

const TimelineSubItem = ({ year, description }: TimelineSubItemProps) => {
  return (
    <div className="relative flex items-start gap-3 pl-[32px] before:absolute before:left-0 before:top-[0.6rem] before:h-px before:w-4 before:bg-neutral-600">
      <span className="text-sm font-medium text-neutral-400 min-w-[100px]">
        {year}
      </span>
      <p className="text-sm text-neutral-300">
        {description}
      </p>
    </div>
  )
}

export { Timeline, TimelineItem, TimelineSubItem }
