
export const taxationTranslations = {
  EN: {
    title: "Comprehensive Taxation & Corporate Compliance in India",
    description: "Ensure your business remains compliant with corporate taxation, indirect tax (GST), and financial reporting",
    items: [
      "Corporate tax, GST compliance",
      "Financial reporting & audits",
      "Consolidated financial statements"
    ],
    details: [
      {
        title: "Tax Planning & Strategy",
        content: "Strategic tax planning and compliance management for optimal business operations."
      },
      {
        title: "Audit Support",
        content: "Comprehensive support for statutory audits and financial reporting requirements."
      }
    ]
  },
  KO: {
    title: "인도 세무 및 기업 규정 준수",
    description: "법인세, 간접세, 중앙정부와 주정부의 규정을 준수하세요.",
    items: [
      "월, 분기, 연간 기본 세무 업무",
      "이사회, 주주총회 실시",
      "해외 직접 투자 관리",
      "한국 연결 재무제표 작성"
    ],
    details: [
      {
        title: "세무 계획 및 전략",
        content: "최적의 사업 운영을 위한 전략적 세무 계획 및 규정 준수 관리"
      },
      {
        title: "감사 지원",
        content: "법정 감사 및 재무 보고 요건에 대한 포괄적인 지원"
      }
    ]
  }
};
