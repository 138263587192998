
import { FileText, Check, XOctagon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface StatusIndicatorProps {
  status: 'draft' | 'published' | 'unpublished';
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const statusConfig = {
    draft: {
      icon: FileText,
      label: 'Draft',
      className: 'bg-yellow-100 text-yellow-800 border-yellow-200',
    },
    published: {
      icon: Check,
      label: 'Published',
      className: 'bg-green-100 text-green-800 border-green-200',
    },
    unpublished: {
      icon: XOctagon,
      label: 'Unpublished',
      className: 'bg-red-100 text-red-800 border-red-200',
    },
  };

  const config = statusConfig[status];
  const Icon = config.icon;

  return (
    <div className={cn(
      'inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium border',
      config.className
    )}>
      <Icon className="w-3.5 h-3.5 mr-1" />
      {config.label}
    </div>
  );
};

export default StatusIndicator;
