
import { Extension } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import CommandList from '../slash-commands/CommandList';
import { getSuggestionItems } from '../slash-commands/items';
import { Editor } from '@tiptap/core';
import { LucideIcon } from 'lucide-react';

export interface CommandItem {
  title: string;
  description: string;
  icon: LucideIcon;
  command: ({ editor, range }: { editor: Editor; range: any }) => void;
}

interface CommandListRef {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const SlashCommands = Extension.create({
  name: 'slash-commands',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }: { editor: Editor; range: any; props: any }) => {
          props.command({ editor, range });
        },
        items: getSuggestionItems,
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        render: () => {
          let component: ReactRenderer | null = null;
          let popup: any[] = [];

          return {
            onStart: (props) => {
              // Don't proceed if we don't have valid props or clientRect
              if (!props?.clientRect) {
                return;
              }

              component = new ReactRenderer(CommandList, {
                props,
                editor: this.editor,
              });

              // Don't proceed if component wasn't created properly
              if (!component?.element) {
                return;
              }

              const createDOMRect = (rect?: DOMRect): DOMRect => {
                if (!rect) {
                  return new DOMRect(0, 0, 0, 0);
                }
                return new DOMRect(
                  rect.left || 0,
                  rect.top || 0,
                  rect.width || 0,
                  rect.height || 0
                );
              };

              // Create a safe getBoundingClientRect function
              const getRect = () => {
                try {
                  if (!props.clientRect) {
                    return createDOMRect();
                  }
                  const rect = props.clientRect();
                  return createDOMRect(rect);
                } catch (e) {
                  console.error('Error getting client rect:', e);
                  return createDOMRect();
                }
              };

              popup = tippy('body', {
                getReferenceClientRect: getRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                arrow: false,
                zIndex: 50,
              });
            },

            onUpdate(props) {
              if (!component) {
                return;
              }

              component.updateProps(props);

              if (popup[0] && props.clientRect) {
                popup[0].setProps({
                  getReferenceClientRect: () => {
                    try {
                      const rect = props.clientRect();
                      return new DOMRect(
                        rect?.left || 0,
                        rect?.top || 0,
                        rect?.width || 0,
                        rect?.height || 0
                      );
                    } catch (e) {
                      console.error('Error updating position:', e);
                      return new DOMRect(0, 0, 0, 0);
                    }
                  },
                });
              }
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup[0]?.hide();
                return true;
              }

              if (!component?.ref) {
                return false;
              }

              return (component.ref as CommandListRef).onKeyDown(props);
            },

            onExit() {
              popup[0]?.destroy();
              component?.destroy();
            },
          };
        },
      }),
    ];
  },
});

export default SlashCommands;
