import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Logo {
  id: string;
  name: string;
  logo_url: string;
  display_order: number;
}

interface LogoGridProps {
  logos: Logo[];
}

const LogoGrid = ({ logos }: LogoGridProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const initialLogosCount = 20;
  const initialLogos = logos.slice(0, initialLogosCount);
  const displayedLogos = isExpanded ? logos : initialLogos;
  const hasMoreLogos = logos.length > initialLogos.length;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 max-w-6xl mx-auto">
        {displayedLogos.map((logo, index) => (
          <div 
            key={`${logo.id}-${index}`}
            className="flex items-center justify-center"
          >
            <div className="w-full aspect-[3/2] p-4 flex items-center justify-center 
                        bg-white rounded-lg border border-neutral-200
                        shadow-[0_2px_8px_rgba(0,0,0,0.08)]
                        transition-all duration-300 hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]"
                 style={{ maxWidth: '180px' }}>
              <img
                src={logo.logo_url}
                alt={logo.name}
                className="w-full h-full object-contain hover:scale-105 transition-transform duration-300"
              />
            </div>
          </div>
        ))}
      </div>
      
      {hasMoreLogos && (
        <div className="flex justify-center">
          <Button
            variant="outline"
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-4 bg-white/5 text-neutral-600 hover:text-neutral-800"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUp className="ml-2 h-4 w-4" />
              </>
            ) : (
              <>
                View More <ChevronDown className="ml-2 h-4 w-4" />
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LogoGrid;