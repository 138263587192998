
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CommandItem } from '../extensions/SlashCommands';
import { LucideIcon } from 'lucide-react';

interface CommandListProps {
  items: CommandItem[];
  command: (item: CommandItem) => void;
}

const CommandList = forwardRef((props: CommandListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      props.command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (!props.items.length) {
    return null;
  }

  return (
    <div className="slash-commands-menu bg-white rounded-lg shadow-lg border border-gray-200 min-w-[200px] z-50">
      <div className="items py-1">
        {props.items.map((item, index) => {
          const Icon = item.icon as LucideIcon;
          return (
            <button
              className={`item flex items-center gap-3 w-full px-4 py-2 text-left hover:bg-gray-100 ${
                index === selectedIndex ? 'bg-gray-100' : ''
              }`}
              key={index}
              onClick={() => selectItem(index)}
            >
              <div className="icon">
                <Icon className="w-4 h-4" />
              </div>
              <div className="content">
                <div className="title text-sm font-medium">{item.title}</div>
                <div className="description text-xs text-gray-500">
                  {item.description}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
});

CommandList.displayName = 'CommandList';

export default CommandList;
