
import { ceoMessageTranslations } from './ceoMessage';
import { valuesTranslations } from './values';
import { whyChooseTranslations } from './whyChoose';
import { expertsTranslations } from './experts';
import { callToActionTranslations } from './callToAction';
import { headerTranslations } from './header';
import { ceoTimelineTranslations } from './ceoTimeline';

export const aboutTranslations = {
  EN: {
    ceoMessage: ceoMessageTranslations.EN,
    values: valuesTranslations.EN,
    whyChoose: whyChooseTranslations.EN,
    experts: expertsTranslations.EN,
    callToAction: callToActionTranslations.EN,
    header: headerTranslations.EN,
    ceoTimeline: ceoTimelineTranslations.EN
  },
  KO: {
    ceoMessage: ceoMessageTranslations.KO,
    values: valuesTranslations.KO,
    whyChoose: whyChooseTranslations.KO,
    experts: expertsTranslations.KO,
    callToAction: callToActionTranslations.KO,
    header: headerTranslations.KO,
    ceoTimeline: ceoTimelineTranslations.KO
  }
};
