export const blogTranslations = {
  EN: {
    title: "Latest Insights & Articles",
    subtitle: "Stay updated with our latest thoughts on Indian business landscape",
    searchPlaceholder: "Search articles...",
    noResults: "No articles found matching your criteria.",
    filterByTags: "Filter by tags",
    latestSection: {
      title: "Latest Insights & Articles",
      subtitle: "Stay updated with our latest thoughts on Indian business landscape",
      viewAll: "View All Articles"
    }
  },
  KO: {
    title: "인사이트 & 아티클",
    subtitle: "인도 비즈니스 환경과 시장 진출 전략에 대한 최신 견해를 확인하세요",
    searchPlaceholder: "게시글 검색...",
    noResults: "검색 조건과 일치하는 게시글이 없습니다.",
    filterByTags: "태그로 필터링",
    latestSection: {
      title: "인도 인사이트",
      subtitle: "인도 비즈니스 환경에 대한 정보를 확인하세요",
      viewAll: "전체보기"
    }
  }
};