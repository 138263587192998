
export const valuesTranslations = {
  EN: {
    title: "Core Values and Vision",
    vision: "We contribute to our clients' growth through excellence in service delivery, fulfilling our duties and responsibilities with dedication, and maintaining transparent and honest operations.",
    values: [
      {
        title: "Pursuit of Excellence",
        description: "Maintaining the highest level of performance to contribute to our clients' growth."
      },
      {
        title: "Ethical Management",
        description: "Building long-term trust with our clients through honest and transparent operations."
      },
      {
        title: "Mutual Growth",
        description: "Aiming for mutual growth with our clients through comprehensive collaboration."
      }
    ],
    cta: "Meet our team"
  },
  KO: {
    title: "핵심가치와 비전",
    vision: "최고의 실력을 바탕으로 의무와 책임을 성실히 이행하며, 투명하고 정직한 운영을 통해 고객사의 성장에 기여한다.",
    values: [
      {
        title: "최고지향",
        description: "최고 수준의 업무 성과를 유지하여 고객사의 성장에 기여합니다."
      },
      {
        title: "정도경영",
        description: "정직하고 투명한 운영을 통하여 고객사와의 장기적인 신뢰를 구축합니다."
      },
      {
        title: "상생추구",
        description: "포괄적인 협력을 통해 고객사와의 동반 성장을 목표로 합니다."
      }
    ],
    cta: "팀 소개"
  }
};
