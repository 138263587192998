
import { useState } from 'react';
import { useToast } from '@/hooks/use-toast';
import { calculateReadingTime } from '@/utils/readingTime';
import { useInsightEditor } from './InsightEditorContext';
import { supabase } from '@/integrations/supabase/client';
import { deleteInsightImages, updateTemporaryImages } from './actions/useImageActions';
import { validateInsightFields } from './actions/useInsightValidation';

interface UseInsightActionsProps {
  onSave: (data: any) => Promise<void>;
  insightId?: string;
}

export const useInsightActions = ({ onSave, insightId }: UseInsightActionsProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast();
  const {
    title_en,
    content_en,
    description_en,
    meta_description_en,
    meta_keywords_en,
    title_ko,
    content_ko,
    description_ko,
    meta_description_ko,
    meta_keywords_ko,
    external_url,
    tags,
    thumbnail_url,
    isExternal,
    status,
  } = useInsightEditor();

  const handleSave = async (action: 'draft' | 'publish' | 'unpublish' | 'delete') => {
    try {
      setIsSaving(true);
      
      try {
        validateInsightFields(action, {
          title_en,
          title_ko,
          description_en,
          description_ko,
          meta_description_en,
          meta_description_ko,
          meta_keywords_en,
          meta_keywords_ko,
          tags,
        });
      } catch (validationError) {
        if (validationError instanceof Error) {
          toast({
            title: "Validation Error",
            description: validationError.message,
            variant: "destructive",
          });
        }
        return;
      }
      
      if (action === 'delete' && insightId) {
        await deleteInsightImages(insightId);

        const { error: insightDeleteError } = await supabase
          .from('insights')
          .delete()
          .eq('id', insightId);

        if (insightDeleteError) {
          throw insightDeleteError;
        }

        toast({
          title: "Success",
          description: "Article and all associated data deleted successfully",
          variant: "success"
        });
        return;
      }

      const readingTime = !isExternal ? calculateReadingTime(content_en) : null;
      let newStatus = status;

      switch (action) {
        case 'draft':
          newStatus = 'draft';
          break;
        case 'publish':
          newStatus = 'published';
          break;
        case 'unpublish':
          newStatus = 'unpublished';
          break;
      }
      
      await onSave({
        title_en,
        title_ko,
        content_en: isExternal ? null : content_en,
        content_ko: isExternal ? null : content_ko,
        description_en,
        description_ko,
        meta_description_en,
        meta_description_ko,
        meta_keywords_en,
        meta_keywords_ko,
        external_url: isExternal ? external_url : null,
        status: newStatus,
        tags,
        thumbnail_url: thumbnail_url || null,
        reading_time: readingTime,
        last_auto_save: action === 'draft' ? new Date().toISOString() : null,
      });

      // Update temporary images to permanent if this is an update
      if (insightId) {
        await updateTemporaryImages(insightId);
      }
      
      const actionMessages = {
        draft: "Article saved as draft",
        publish: "Article published successfully",
        unpublish: "Article unpublished",
        delete: "Article deleted successfully",
      };

      toast({
        title: "Success",
        description: actionMessages[action],
        variant: "success"
      });
    } catch (error) {
      console.error('Error in handleSave:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to save article",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    handleSave,
  };
};
