
import { Tag } from 'lucide-react';
import { Label } from '@/components/ui/label';
import TagSelector from './TagSelector';

interface TagsSectionProps {
  tags: string[];
  newTag: string;
  setNewTag: (value: string) => void;
  handleAddTag: (e: React.KeyboardEvent) => void;
  handleRemoveTag: (tag: string) => void;
  setTags: (tags: string[]) => void;
}

const TagsSection = ({
  tags,
  handleRemoveTag,
  setTags,
}: TagsSectionProps) => {
  const handleTagsChange = (newTags: string[]) => {
    console.log('TagsSection handleTagsChange called with:', newTags);
    // Directly update the tags with the new set
    setTags(newTags);
  };

  return (
    <div className="space-y-2">
      <Label className="flex items-center gap-2">
        <Tag className="w-4 h-4 text-gray-500" />
        Tags
      </Label>
      <TagSelector
        selectedTags={tags}
        onTagsChange={handleTagsChange}
      />
    </div>
  );
};

export default TagsSection;
