
export const incorporationTranslations = {
  EN: {
    title: "Launch Your Business in India Within 30 Days",
    description: "Transform your India expansion plans into reality with our comprehensive incorporation services",
    items: [
      "Streamlined company registration process",
      "Complete legal & tax compliance setup",
      "Bank account opening assistance",
      "Digital signature & business licenses"
    ],
    details: [
      {
        title: "Entity Selection & Registration",
        content: "Expert guidance on choosing between Private Limited, LLP, or Branch Office structures based on your business goals."
      },
      {
        title: "Compliance Setup",
        content: "Complete setup of GST, PAN, TAN, and other mandatory registrations with ongoing compliance support."
      }
    ]
  },
  KO: {
    title: "30일 이내 인도 법인 설립",
    description: "귀사의 인도 진출 계획을 현실로 만들어 드립니다. (※ 타업체 평균 3개월)",
    items: [
      "최적화된 법인 설립 프로세스",
      "사업 별 필수 정부 허가 취득",
      "법인 은행 계좌 개설 지원",
      "기타 사업자 등록 및 전자서명"
    ],
    details: [
      {
        title: "법인 형태 선택 및 등록",
        content: "귀사의 사업 목표에 맞는 Private Limited, LLP, Branch Office 등 법인 형태 선택에 대한 전문가 상담"
      },
      {
        title: "규정 준수 설정",
        content: "GST, PAN, TAN 등 필수 등록과 지속적인 규정 준수 지원"
      }
    ]
  }
};
