
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Globe } from 'lucide-react';

interface ExternalUrlToggleProps {
  isExternal: boolean;
  onToggle: (value: boolean) => void;
}

const ExternalUrlToggle = ({ isExternal, onToggle }: ExternalUrlToggleProps) => {
  return (
    <div className="flex items-center space-x-3">
      <Switch
        checked={isExternal}
        onCheckedChange={onToggle}
        id="external-toggle"
        className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors 
          data-[state=checked]:bg-[#F97316] data-[state=unchecked]:bg-gray-200
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#F97316] focus-visible:ring-offset-2
          disabled:cursor-not-allowed disabled:opacity-50"
      />
      <Label 
        htmlFor="external-toggle" 
        className="flex items-center gap-2 text-sm font-medium text-gray-700 cursor-pointer select-none"
      >
        <Globe className="w-4 h-4 text-gray-500" />
        External Article
      </Label>
    </div>
  );
};

export default ExternalUrlToggle;

