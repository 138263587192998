
import { FileText } from 'lucide-react';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

interface ArticleSummarySectionProps {
  description_en: string;
  setDescriptionEn: (value: string) => void;
  description_ko: string;
  setDescriptionKo: (value: string) => void;
  isEnglish: boolean;
}

const ArticleSummarySection = ({
  description_en,
  setDescriptionEn,
  description_ko,
  setDescriptionKo,
  isEnglish,
}: ArticleSummarySectionProps) => {
  return (
    <div className="space-y-2">
      <Label className="flex items-center gap-2">
        <FileText className="w-4 h-4 text-gray-500" />
        {isEnglish ? "Article Summary" : "기사 요약"}
      </Label>
      <Textarea
        placeholder={isEnglish ? "Brief summary of the article..." : "기사에 대한 간단한 요약..."}
        value={isEnglish ? description_en : description_ko}
        onChange={(e) => isEnglish ? setDescriptionEn(e.target.value) : setDescriptionKo(e.target.value)}
        className="min-h-[100px] border-gray-200 focus:border-gray-300 focus:ring-gray-200"
      />
    </div>
  );
};

export default ArticleSummarySection;
