export const navbarTranslations = {
  EN: {
    home: "Home",
    services: "Services",
    about: "About",
    gallery: "Gallery",
    insights: "Insights",
    contactUs: "Contact Us"
  },
  KO: {
    home: "홈",
    services: "서비스",
    about: "회사 소개",
    gallery: "갤러리",
    insights: "인사이트",
    contactUs: "문의하기"
  }
};