import { Globe } from 'lucide-react';
import { cn } from '@/lib/utils';

interface LanguageSelectorProps {
  language: string;
  onLanguageChange: (lang: string) => void;
  isMobile?: boolean;
}

const LanguageSelector = ({ language, onLanguageChange, isMobile = false }: LanguageSelectorProps) => {
  const toggleLanguage = () => {
    const newLanguage = language === 'EN' ? 'KO' : 'EN';
    onLanguageChange(newLanguage);
  };

  if (isMobile) {
    return (
      <div className="px-3 py-2">
        <button
          onClick={toggleLanguage}
          className={cn(
            "flex items-center gap-2 w-full px-3 py-2 text-gray-600 hover:text-primary transition-colors",
            language === 'KO' && "text-primary"
          )}
        >
          <Globe className="h-4 w-4" />
          <span>{language === 'EN' ? 'English' : '한국어'}</span>
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={toggleLanguage}
      className="inline-flex items-center gap-2 px-3 py-2 transition-colors rounded-md border text-gray-600 border-gray-200 bg-gray-50 hover:bg-gray-100"
    >
      <Globe className="h-4 w-4" />
      <span>{language}</span>
    </button>
  );
};

export default LanguageSelector;