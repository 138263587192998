
import { createContext, useContext, useState } from 'react';
import type { InsightEditorContext, InsightFormData, InsightStatus } from '@/types/insight';

const InsightEditorContext = createContext<InsightEditorContext | undefined>(undefined);

export const useInsightEditor = () => {
  const context = useContext(InsightEditorContext);
  if (!context) {
    throw new Error('useInsightEditor must be used within an InsightEditorProvider');
  }
  return context;
};

interface InsightEditorProviderProps {
  children: React.ReactNode;
  initialData?: Partial<InsightFormData>;
}

export const InsightEditorProvider = ({ children, initialData }: InsightEditorProviderProps) => {
  const [title_en, setTitleEn] = useState(initialData?.title_en || '');
  const [content_en, setContentEn] = useState(initialData?.content_en || '');
  const [description_en, setDescriptionEn] = useState(initialData?.description_en || '');
  const [meta_description_en, setMetaDescriptionEn] = useState(initialData?.meta_description_en || '');
  const [meta_keywords_en, setMetaKeywordsEn] = useState<string[]>(initialData?.meta_keywords_en || []);
  
  const [title_ko, setTitleKo] = useState(initialData?.title_ko || '');
  const [content_ko, setContentKo] = useState(initialData?.content_ko || '');
  const [description_ko, setDescriptionKo] = useState(initialData?.description_ko || '');
  const [meta_description_ko, setMetaDescriptionKo] = useState(initialData?.meta_description_ko || '');
  const [meta_keywords_ko, setMetaKeywordsKo] = useState<string[]>(initialData?.meta_keywords_ko || []);
  
  const [external_url, setExternalUrl] = useState(initialData?.external_url || '');
  const [tags, setTags] = useState<string[]>(initialData?.tags || []);
  const [thumbnail_url, setThumbnailUrl] = useState(initialData?.thumbnail_url || '');
  const [isExternal, setIsExternal] = useState(!!initialData?.external_url);
  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'ko'>('en');
  const [status, setStatus] = useState<InsightStatus>(initialData?.status || 'draft');
  const [reading_time, setReadingTime] = useState<number | null>(initialData?.reading_time || null);

  // Tag management
  const [newTag, setNewTag] = useState('');
  const handleAddTag = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newTag.trim()) {
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };
  const handleRemoveTag = (tag: string) => {
    setTags(tags.filter(t => t !== tag));
  };

  // Keyword management
  const [newKeyword, setNewKeyword] = useState('');
  const handleAddKeyword = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newKeyword.trim()) {
      if (currentLanguage === 'en') {
        setMetaKeywordsEn([...meta_keywords_en, newKeyword.trim()]);
      } else {
        setMetaKeywordsKo([...meta_keywords_ko, newKeyword.trim()]);
      }
      setNewKeyword('');
    }
  };
  const handleRemoveKeyword = (keyword: string) => {
    if (currentLanguage === 'en') {
      setMetaKeywordsEn(meta_keywords_en.filter(k => k !== keyword));
    } else {
      setMetaKeywordsKo(meta_keywords_ko.filter(k => k !== keyword));
    }
  };

  const value: InsightEditorContext = {
    title_en,
    setTitleEn,
    content_en,
    setContentEn,
    description_en,
    setDescriptionEn,
    meta_description_en,
    setMetaDescriptionEn,
    meta_keywords_en,
    setMetaKeywordsEn,
    
    title_ko,
    setTitleKo,
    content_ko,
    setContentKo,
    description_ko,
    setDescriptionKo,
    meta_description_ko,
    setMetaDescriptionKo,
    meta_keywords_ko,
    setMetaKeywordsKo,
    
    external_url,
    setExternalUrl,
    tags,
    setTags,
    thumbnail_url,
    setThumbnailUrl,
    isExternal,
    setIsExternal,
    currentLanguage,
    setCurrentLanguage,
    status,
    setStatus,
    reading_time,

    newTag,
    setNewTag,
    handleAddTag,
    handleRemoveTag,
    newKeyword,
    setNewKeyword,
    handleAddKeyword,
    handleRemoveKeyword,
  };

  return (
    <InsightEditorContext.Provider value={value}>
      {children}
    </InsightEditorContext.Provider>
  );
};
