import { AboutTranslations } from "@/types/about";
import { heroTranslations } from './translations/hero';
import { navbarTranslations } from './translations/navbar';
import { servicesTranslations } from './translations/services';
import { contactTranslations } from './translations/contact';
import { ceoPreviewTranslations } from './translations/ceoPreview';
import { trustBannerTranslations } from './translations/trustBanner';
import { teamTranslations } from './translations/team';
import { blogTranslations } from './translations/blog';
import { aboutTranslations } from './translations/about';
import { footerTranslations } from './translations/footer';

interface Translations {
  navbar: typeof navbarTranslations.EN;
  hero: typeof heroTranslations.EN;
  services: typeof servicesTranslations.EN;
  contact: typeof contactTranslations.EN;
  ceoPreview: typeof ceoPreviewTranslations.EN;
  trustBanner: typeof trustBannerTranslations.EN;
  team: typeof teamTranslations.EN;
  blog: typeof blogTranslations.EN;
  about: AboutTranslations;
  footer: typeof footerTranslations.EN;
}

export const translations: Record<'EN' | 'KO', Translations> = {
  EN: {
    hero: heroTranslations.EN,
    navbar: navbarTranslations.EN,
    services: servicesTranslations.EN,
    contact: contactTranslations.EN,
    ceoPreview: ceoPreviewTranslations.EN,
    trustBanner: trustBannerTranslations.EN,
    team: teamTranslations.EN,
    blog: blogTranslations.EN,
    about: aboutTranslations.EN,
    footer: footerTranslations.EN
  },
  KO: {
    hero: heroTranslations.KO,
    navbar: navbarTranslations.KO,
    services: servicesTranslations.KO,
    contact: contactTranslations.KO,
    ceoPreview: ceoPreviewTranslations.KO,
    trustBanner: trustBannerTranslations.KO,
    team: teamTranslations.KO,
    blog: blogTranslations.KO,
    about: aboutTranslations.KO,
    footer: footerTranslations.KO
  }
};

export const useTranslation = (language: string) => {
  return translations[language as keyof typeof translations] || translations.EN;
};