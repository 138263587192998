
export const strategyTranslations = {
  EN: {
    title: "Strategic Market Entry & Business Development",
    description: "Navigate the Indian market with confidence using our proven entry strategies",
    items: [
      "Customized market entry strategy",
      "Local partnership facilitation",
      "Market research & analysis",
      "Business development support"
    ],
    details: [
      {
        title: "Market Analysis",
        content: "Comprehensive market research, competitor analysis, and opportunity assessment for your industry."
      },
      {
        title: "Partnership Development",
        content: "Identification and due diligence of potential partners, distributors, or acquisition targets."
      }
    ]
  },
  KO: {
    title: "인도 시장 진출 전략 수립",
    description: "검증된 진입 전략 수립 후 인도 시장에 자신있게 진출하세요.",
    items: [
      "시장 조사 및 분석",
      "기업 맞춤형 시장 진출 전략",
      "인도 법인 구성 및 해외투자 계획",
      "타임라인 별 업무 계획 수립"
    ],
    details: [
      {
        title: "시장 분석",
        content: "귀사 산업에 대한 포괄적인 시장 조사, 경쟁사 분석 및 기회 평가"
      },
      {
        title: "파트너십 개발",
        content: "잠재적 파트너, 유통업체 또는 인수 대상 식별 및 실사"
      }
    ]
  }
};
