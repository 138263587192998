import { motion } from "framer-motion";
import { Diamond, Scale, Users } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { useTranslation } from "@/utils/translations";

interface CoreValuesProps {
  language: string;
}

const CoreValues = ({ language }: CoreValuesProps) => {
  const t = useTranslation(language);

  const values = [
    {
      icon: <Diamond className="w-8 h-8 text-secondary" />,
      title: t.about.values.values[0].title,
      description: t.about.values.values[0].description
    },
    {
      icon: <Scale className="w-8 h-8 text-secondary" />,
      title: t.about.values.values[1].title,
      description: t.about.values.values[1].description
    },
    {
      icon: <Users className="w-8 h-8 text-secondary" />,
      title: t.about.values.values[2].title,
      description: t.about.values.values[2].description
    }
  ];

  const scrollToExperts = () => {
    const expertsSection = document.getElementById('experts-section');
    if (expertsSection) {
      expertsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-3xl mx-auto mb-12"
        >
          <h2 className="text-3xl font-bold text-neutral-dark mb-6">
            {t.about.values.title}
          </h2>
          <div className="bg-gradient-to-r from-neutral/30 via-neutral/50 to-neutral/30 p-6 rounded-xl shadow-sm">
            <p className="text-lg text-neutral-700 italic font-medium leading-relaxed">
              "{t.about.values.vision}"
            </p>
          </div>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6 mb-10">
          {values.map((value, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <Card className="group h-full hover:shadow-lg transition-all duration-300 bg-white border border-neutral/20">
                <CardContent className="p-6">
                  <div className="flex flex-col items-center text-center gap-4">
                    <div className="p-3 bg-neutral rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300 group-hover:-translate-y-1">
                      {value.icon}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2 text-neutral-dark">
                        {value.title}
                      </h3>
                      <p className="text-neutral-600 text-sm leading-relaxed">
                        {value.description}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="text-center"
        >
          <button 
            onClick={scrollToExperts}
            className="inline-flex items-center gap-2 text-base font-medium px-6 py-3 bg-secondary text-white rounded-lg hover:bg-secondary-dark transition-colors duration-300 shadow hover:shadow-lg"
          >
            {t.about.values.cta} →
          </button>
        </motion.div>
      </div>
    </section>
  );
};

export default CoreValues;