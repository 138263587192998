
export const realEstateTranslations = {
  EN: {
    title: "Find the Perfect Business Location in India",
    description: "From acquiring land to setting up offices and factories, we provide end-to-end assistance",
    items: [
      "Office, factory & land acquisition",
      "Business infrastructure setup",
      "Government approvals & permits"
    ],
    details: [
      {
        title: "Location Analysis",
        content: "Strategic analysis of potential business locations based on your requirements."
      },
      {
        title: "Setup Support",
        content: "End-to-end support in property acquisition and infrastructure setup."
      }
    ]
  },
  KO: {
    title: "최적의 사업장 구축",
    description: "토지 취득부터 공장 설립까지 인도 내 최적의 사업장을 구축하세요. (※ 파트너 협업)",
    items: [
      "사무실 선정 및 리테일러",
      "공장 임대",
      "토지 매입 및 공장 건설",
      "정부 승인 및 허가"
    ],
    details: [
      {
        title: "위치 분석",
        content: "귀사의 요구사항에 기반한 잠재적 사업장 위치의 전략적 분석"
      },
      {
        title: "설립 지원",
        content: "부동산 취득 및 인프라 구축을 위한 종합 지원"
      }
    ]
  }
};
