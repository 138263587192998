import { Node } from '@tiptap/core';

const formatFileSize = (size: number): string => {
  if (!size) return '0 B';
  const units = ['B', 'KB', 'MB', 'GB'];
  const digitGroups = Math.floor(Math.log10(size) / Math.log10(1024));
  return `${(size / Math.pow(1024, digitGroups)).toFixed(1)} ${units[digitGroups]}`;
};

export const FileAttachment = Node.create({
  name: 'fileAttachment',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      href: { default: null },
      fileName: { default: null },
      fileSize: { default: null },
      fileType: { default: null },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="file-attachment"]',
        getAttrs: (dom: any) => ({
          href: dom.querySelector('a')?.href,
          fileName: dom.querySelector('.file-name')?.textContent,
          fileSize: dom.querySelector('.file-size')?.getAttribute('data-size'),
          fileType: dom.querySelector('.file-type')?.getAttribute('data-type'),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const fileName = HTMLAttributes.fileName || 'Untitled';
    const fileSize = formatFileSize(parseInt(HTMLAttributes.fileSize) || 0);
    const fileType = HTMLAttributes.fileType || '';
    const isPreviewable = fileType.startsWith('image/') || 
                         fileType === 'application/pdf' ||
                         fileType.startsWith('text/');

    return [
      'div',
      { 
        'data-type': 'file-attachment',
        class: 'flex items-center gap-2 p-3 border rounded-md bg-white hover:bg-gray-50 transition-colors my-2 shadow-sm'
      },
      [
        'a',
        { 
          href: HTMLAttributes.href || '#',
          target: isPreviewable ? '_blank' : '_self',
          rel: 'noopener noreferrer',
          download: !isPreviewable ? fileName : undefined,
          class: 'flex items-center gap-3 text-blue-600 hover:text-blue-800 flex-1'
        },
        [
          'svg',
          { 
            xmlns: 'http://www.w3.org/2000/svg',
            width: '20',
            height: '20',
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: 'currentColor',
            'stroke-width': '2',
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round',
            class: 'shrink-0'
          },
          [
            'path',
            { 
              d: 'M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48'
            }
          ]
        ],
        [
          'div',
          { class: 'flex flex-col' },
          [
            'span',
            { 
              class: 'font-medium text-sm file-name',
            },
            fileName
          ],
          [
            'span',
            { 
              class: 'text-xs text-gray-500 file-size',
              'data-size': HTMLAttributes.fileSize
            },
            fileSize
          ],
          [
            'span',
            {
              class: 'hidden file-type',
              'data-type': fileType
            },
            fileType
          ]
        ]
      ]
    ] as const;
  },
});