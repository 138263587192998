import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Calendar, MapPin } from "lucide-react";

interface EventPreviewDialogProps {
  event: any;
  language: string;
  images: any[];
  currentImageIndex: number;
  onClose: () => void;
  onPreviousImage: () => void;
  onNextImage: () => void;
}

const EventPreviewDialog = ({
  event,
  language,
  images,
  currentImageIndex,
  onClose,
  onPreviousImage,
  onNextImage
}: EventPreviewDialogProps) => {
  if (!event) return null;

  return (
    <Dialog open={!!event} onOpenChange={onClose}>
      <DialogContent className="max-w-5xl h-[90vh] p-0 bg-white">
        <div className="h-full flex flex-col">
          {/* Header Section */}
          <div className="p-6 border-b bg-white">
            <h2 className="text-2xl font-bold text-gray-900">
              {language === 'KO' && event.title_ko 
                ? event.title_ko 
                : event.title_en}
            </h2>
            <div className="flex items-center gap-4 text-sm text-gray-600 mt-2">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                {new Date(event.event_date).toLocaleDateString()}
              </div>
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4" />
                {event.location}
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative bg-neutral-900 flex items-center justify-center">
            {images.length > 0 ? (
              <div className="relative w-full h-full flex items-center justify-center">
                <div className="relative w-full h-full max-h-[60vh]">
                  <img
                    src={images[currentImageIndex].image_url}
                    alt={images[currentImageIndex].alt_text || event.title_en}
                    className="w-full h-full object-contain"
                  />
                </div>
                
                {/* Image Counter */}
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/70 text-white px-4 py-2 rounded-full text-sm">
                  {currentImageIndex + 1} / {images.length}
                </div>

                {/* Navigation Buttons */}
                {images.length > 1 && (
                  <>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/70 text-white hover:bg-black/90"
                      onClick={onPreviousImage}
                    >
                      <ChevronLeft className="w-6 h-6" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/70 text-white hover:bg-black/90"
                      onClick={onNextImage}
                    >
                      <ChevronRight className="w-6 h-6" />
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <div className="text-white text-center p-8">No images available</div>
            )}
          </div>

          {/* Description Section */}
          <div className="p-6 border-t bg-white">
            <ScrollArea className="h-32">
              <p className="text-gray-600">
                {language === 'KO' && event.description_ko 
                  ? event.description_ko 
                  : event.description_en}
              </p>
            </ScrollArea>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventPreviewDialog;