
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Image, Layout, FileText } from "lucide-react";
import LogoManager from "@/components/admin/LogoManager";
import GalleryManager from "@/components/admin/GalleryManager";
import InsightManager from "@/components/InsightManager";

function TabDemo() {
  return (
    <Tabs defaultValue="insights">
      <ScrollArea>
        <TabsList className="mb-3 h-auto -space-x-px bg-background p-0 shadow-sm shadow-black/5 rtl:space-x-reverse">
          <TabsTrigger
            value="insights"
            className="relative overflow-hidden rounded-none border border-border py-2 after:pointer-events-none after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 first:rounded-s last:rounded-e data-[state=active]:bg-muted data-[state=active]:after:bg-primary"
          >
            <FileText
              className="-ms-0.5 me-1.5 opacity-60"
              size={16}
              strokeWidth={2}
              aria-hidden="true"
            />
            Insights
          </TabsTrigger>
          <TabsTrigger
            value="gallery"
            className="relative overflow-hidden rounded-none border border-border py-2 after:pointer-events-none after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 first:rounded-s last:rounded-e data-[state=active]:bg-muted data-[state=active]:after:bg-primary"
          >
            <Layout
              className="-ms-0.5 me-1.5 opacity-60"
              size={16}
              strokeWidth={2}
              aria-hidden="true"
            />
            Gallery
          </TabsTrigger>
          <TabsTrigger
            value="logos"
            className="relative overflow-hidden rounded-none border border-border py-2 after:pointer-events-none after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 first:rounded-s last:rounded-e data-[state=active]:bg-muted data-[state=active]:after:bg-primary"
          >
            <Image
              className="-ms-0.5 me-1.5 opacity-60"
              size={16}
              strokeWidth={2}
              aria-hidden="true"
            />
            Logos
          </TabsTrigger>
        </TabsList>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <TabsContent value="insights" className="mt-6 bg-white p-6 rounded-lg shadow-sm">
        <InsightManager />
      </TabsContent>
      <TabsContent value="gallery" className="mt-6 bg-white p-6 rounded-lg shadow-sm">
        <GalleryManager />
      </TabsContent>
      <TabsContent value="logos" className="mt-6 bg-white p-6 rounded-lg shadow-sm">
        <LogoManager />
      </TabsContent>
    </Tabs>
  );
}

export { TabDemo };
