import { Editor } from '@tiptap/react';
import { List, ListOrdered } from 'lucide-react';
import EditorToolbarButton from '../EditorToolbarButton';

interface ListControlsProps {
  editor: Editor;
}

const ListControls = ({ editor }: ListControlsProps) => {
  return (
    <>
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
        icon={List}
      />
      <EditorToolbarButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive('orderedList')}
        icon={ListOrdered}
      />
    </>
  );
};

export default ListControls;