
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import type { Insight } from '@/types/insight';

export const useInsightManagement = () => {
  const { toast } = useToast();

  const { data: insights, isLoading, refetch } = useQuery({
    queryKey: ['insights-admin'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('insights')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as Insight[];
    },
  });

  const generateSlug = (titleEn: string | null, titleKo: string | null) => {
    const title = titleEn || titleKo;
    if (!title) {
      throw new Error('At least one title (English or Korean) is required');
    }

    return title
      .toLowerCase()
      .replace(/[^a-zA-Z0-9가-힣\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  const validateMandatoryFields = (data: any, status: 'draft' | 'published' | 'unpublished') => {
    if (status === 'published') {
      if (!data.title_en && !data.title_ko) {
        throw new Error('At least one title (English or Korean) is required');
      }

      if (!data.description_en && !data.description_ko) {
        throw new Error('At least one description is required');
      }

      if (!data.meta_description_en && !data.meta_description_ko) {
        throw new Error('At least one meta description is required');
      }

      const hasEnKeywords = Array.isArray(data.meta_keywords_en) && data.meta_keywords_en.length > 0;
      const hasKoKeywords = Array.isArray(data.meta_keywords_ko) && data.meta_keywords_ko.length > 0;
      if (hasEnKeywords !== hasKoKeywords) {
        throw new Error('Meta keywords must be provided for both languages or neither');
      }

      if (!Array.isArray(data.tags) || data.tags.length === 0) {
        throw new Error('At least one tag is required');
      }

      if (!data.author) {
        throw new Error('Author is required');
      }
    }

    if (!data.title_en && !data.title_ko) {
      throw new Error('At least one title (English or Korean) is required');
    }
  };

  const handleSave = async (data: any, selectedInsight: Insight | null) => {
    try {
      validateMandatoryFields(data, data.status);

      const slug = selectedInsight?.slug || generateSlug(data.title_en, data.title_ko);

      if (selectedInsight) {
        const { error } = await supabase
          .from('insights')
          .update({
            ...data,
            slug,
            updated_at: new Date().toISOString(),
          })
          .eq('id', selectedInsight.id);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('insights')
          .insert([{
            ...data,
            author: 'Admin',
            slug
          }]);

        if (error) throw error;
      }

      await refetch();
      return true;
    } catch (error) {
      console.error('Save error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to save article",
        variant: "destructive",
      });
      return false;
    }
  };

  const handleToggleVisibility = async (insight: Insight) => {
    try {
      if (insight.status !== 'published') {
        validateMandatoryFields(insight, 'published');
      }

      const { error } = await supabase
        .from('insights')
        .update({
          status: insight.status === 'published' ? 'unpublished' : 'published',
          updated_at: new Date().toISOString(),
        })
        .eq('id', insight.id);

      if (error) throw error;

      await refetch();
      toast({
        title: "Success",
        description: `Article ${insight.status === 'published' ? 'unpublished' : 'published'} successfully`,
      });
      return true;
    } catch (error) {
      console.error('Toggle visibility error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update article status",
        variant: "destructive",
      });
      return false;
    }
  };

  const handleDelete = async (insight: Insight) => {
    try {
      const { error } = await supabase
        .from('insights')
        .delete()
        .eq('id', insight.id);

      if (error) throw error;

      await refetch();
      toast({
        title: "Success",
        description: "Article deleted successfully",
      });
      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete article",
        variant: "destructive",
      });
      return false;
    }
  };

  return {
    insights,
    isLoading,
    handleSave,
    handleToggleVisibility,
    handleDelete,
  };
};
