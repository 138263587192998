import { NavigateFunction } from 'react-router-dom';

export const scrollToTop = () => {
  requestAnimationFrame(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  });
};

export const scrollToServices = () => {
  const servicesSection = document.getElementById('services');
  if (servicesSection) {
    const headerHeight = 64;
    const elementPosition = servicesSection.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

export const handleHomeNavigation = (
  e: React.MouseEvent,
  navigate: NavigateFunction,
  currentPath: string,
  onMobileClick?: () => void
) => {
  e.preventDefault();
  if (onMobileClick) onMobileClick();

  if (currentPath === '/') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  } else {
    navigate('/', { replace: true });
    scrollToTop();
  }
};

export const handleServicesNavigation = (
  e: React.MouseEvent,
  navigate: NavigateFunction,
  currentPath: string,
  onMobileClick?: () => void
) => {
  e.preventDefault();
  if (currentPath !== '/') {
    navigate('/', { 
      replace: true,
      state: { scrollToServices: true }
    });
  } else {
    scrollToServices();
  }
  if (onMobileClick) onMobileClick();
};

export const handleAboutNavigation = (
  e: React.MouseEvent,
  navigate: NavigateFunction,
  onMobileClick?: () => void
) => {
  e.preventDefault();
  if (onMobileClick) onMobileClick();
  
  navigate('/about', { replace: true });
  scrollToTop();
};