import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "@/utils/translations";

interface CallToActionProps {
  language: string;
}

const CallToAction = ({ language }: CallToActionProps) => {
  const t = useTranslation(language);

  return (
    <section className="py-20 bg-primary text-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-3xl mx-auto"
        >
          <h2 className="text-4xl font-bold mb-6">
            {t.about.callToAction.title}
          </h2>
          <p className="text-xl text-white/90 mb-8">
            {t.about.callToAction.subtitle}
          </p>
          
          <div className="flex justify-center">
            <Button 
              variant="secondary"
              size="lg"
              className="w-full sm:w-auto"
              asChild
            >
              <a href="/contact">
                {t.about.callToAction.cta}
                <ArrowRight className="ml-2 h-4 w-4" />
              </a>
            </Button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CallToAction;