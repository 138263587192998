
export const ceoMessageTranslations = {
  EN: {
    title: "CEO Message",
    quote: "The Indian market is drawing global attention with its immense growth potential, backed by abundant human resources and a vast domestic market. However, Indian market entry is not an easy task due to complex systems, regulations, and India's unique cultural differences. Duru is committed to providing the optimal business environment in India to actively support our clients' successful market entry and sustainable growth.",
    ceoName: "Yongjoo Yoo",
    ceoTitle: "CEO, Duru Compliance",
    cta: "Read More About Our Leadership"
  },
  KO: {
    title: "CEO 메시지",
    quote: "인도 시장은 풍부한 인적 자원과 거대한 내수 시장을 기반으로 높은 성장 가능성을 갖추고 있어 전 세계의 주목을 받고 있습니다. 그러나 복잡한 시스템과 규정, 그리고 인도 특유의 문화적 차이로 인해 인도 시장 진출이 결코 쉬운 일은 아닙니다. 두루는 인도에서 최적의 사업 환경을 제공하여 고객사의 성공적인 시장 진입과 지속적인 성장을 적극 지원하겠습니다.",
    ceoName: "유용주",
    ceoTitle: "CEO, 두루 컴플라이언스",
    cta: "리더십 팀 자세히 보기"
  }
};
