
export const recruitmentTranslations = {
  EN: {
    title: "Build Your Dream Team in India",
    description: "Access top talent and build high-performing teams with our recruitment expertise",
    items: [
      "Access to pre-screened talent pool",
      "Complete HR compliance setup",
      "Payroll & benefits management",
      "Performance management systems"
    ],
    details: [
      {
        title: "Talent Acquisition",
        content: "End-to-end recruitment process from job posting to offer letter negotiation."
      },
      {
        title: "HR Compliance",
        content: "Setup of compliant HR policies, contracts, and statutory benefits."
      }
    ]
  },
  KO: {
    title: "인도 현지 드림팀 구축",
    description: "인도 중앙정부의 승인을 받은 채용 업체인 Duru와 최고의 인도 인재를 확보하세요.",
    items: [
      "다양한 산업의 검증된 인재 풀 보유",
      "최적화된 채용 프로세스",
      "Duru 채용 관리 시스템",
      "신규 인력 입사 지원"
    ],
    details: [
      {
        title: "인재 채용",
        content: "채용 공고에서 오퍼레터 협상까지 전체 채용 프로세스"
      },
      {
        title: "인사 규정 준수",
        content: "규정을 준수하는 인사 정책, 계약 및 법정 복리후생 설정"
      }
    ]
  }
};
