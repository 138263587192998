export interface TrustBannerTranslations {
  title: string;
  description: string;
  certifications: string[];
  footer: string;
}

export const trustBannerTranslations = {
  EN: {
    title: "Trusted by Global Companies",
    description: "Our expertise is backed by years of successful business setups in India",
    certifications: [
      "KOTRA Global Regional Expert",
      "NIPA Global ICT Portal Local Expert",
      "SMBA Global Business Partner"
    ],
    footer: "Join 200+ companies who trust us with their India business journey"
  },
  KO: {
    title: "글로벌 기업들이 신뢰하는 파트너",
    description: "수년간의 성공적인 인도 비즈니스 설립 경험을 보유하고 있습니다",
    certifications: [
      "KOTRA 글로벌 지역 전문가",
      "NIPA 글로벌 ICT 포털 현지 전문가",
      "중소벤처기업부 글로벌 비즈니스 파트너"
    ],
    footer: "200개 이상의 기업이 인도 비즈니스 여정을 저희와 함께하고 있습니다"
  }
};