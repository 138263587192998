
import LanguageToggle from '../../LanguageToggle';

interface ArticleHeaderProps {
  currentLanguage: 'en' | 'ko';
  onLanguageChange: (lang: 'en' | 'ko') => void;
}

const ArticleHeader = ({ currentLanguage, onLanguageChange }: ArticleHeaderProps) => {
  return (
    <div className="flex justify-between items-center border-b border-gray-100 pb-4 mb-6">
      <h2 className="text-lg font-semibold">Select language version</h2>
      <LanguageToggle
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
      />
    </div>
  );
};

export default ArticleHeader;
