export const whyChooseTranslations = {
  EN: {
    title: "Why Choose Duru Compliance?",
    subtitle: "Your trusted partner for seamless business expansion, compliance, and strategy in India.",
    differentiators: [
      {
        title: "Fast & Hassle-Free Market Entry",
        description: "We streamline the business incorporation process, cutting through red tape to get you operational in India within weeks, not months."
      },
      {
        title: "Trusted by Global Enterprises",
        description: "Over 128+ companies from Korea, Europe, and the US trust us to navigate the Indian market. We are an approved partner by leading institutions."
      },
      {
        title: "End-to-End Business Support",
        description: "From company registration to taxation, HR, and real estate, we handle every aspect of your India expansion, so you focus on growth."
      },
      {
        title: "Regulatory & Compliance Expertise",
        description: "Our team of legal experts, financial advisors, and compliance specialists ensure you stay 100% compliant with India's evolving regulations."
      }
    ],
    cta: "Talk to Our Experts →"
  },
  KO: {
    title: "두루 컴플라이언스를 선택하는 이유",
    subtitle: "인도 시장 진출 관련 모든 업무에서 신뢰할 수 있는 파트너",
    differentiators: [
      {
        title: "신속하고 간편한 시장 진입",
        description: "인도 시장에 대한 깊은 이해와 풍부한 경험을 바탕으로, 30일 내 법인 설립을 완료하고 60일 내 사업을 시작할 수 있도록 지원합니다."
      },
      {
        title: "글로벌 기업의 신뢰",
        description: "고객 의뢰에 대한 100% 성과를 바탕으로 KOTRA, NIPA 등 한국 정부 기관으로부터 공인을 받았으며, 한국, 미국, 중동 등 다양한 글로벌 기업들과 협력하고 있습니다"
      },
      {
        title: "원스톱 비즈니스 지원",
        description: "법인 설립부터 인력 채용, 세무, 공장 설립까지 인도 진출과 관련된 모든 경영 지원 업무를 수행하여, 고객사가 오로지 성장에만 집중할 수 있도록 돕습니다"
      },
      {
        title: "책임감과 실력",
        description: "인도 업체에 외주를 맡기는 타사와 달리, 두루는 한국인 관리자와 인도인 전문가가 직접 경영 지원 업무를 수행하여, 글로벌 기준에 부합하는 서비스를 제공합니다."
      }
    ],
    cta: "전문가와 상담하기 →"
  }
};