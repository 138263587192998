
import { Editor } from '@tiptap/react';
import BasicFormatting from './toolbar/BasicFormatting';
import ListControls from './toolbar/ListControls';
import HeadingControls from './toolbar/HeadingControls';
import AlignmentControls from './toolbar/AlignmentControls';
import MediaControls from './toolbar/MediaControls';
import TableControls from './toolbar/TableControls';
import LinkControls from './toolbar/LinkControls';

interface EditorToolbarProps {
  editor: Editor;
  insightId?: string;
}

const EditorToolbar = ({ editor, insightId }: EditorToolbarProps) => {
  return (
    <div className="bg-white border-b p-2 flex gap-2 flex-wrap">
      <BasicFormatting editor={editor} />
      <ListControls editor={editor} />
      <HeadingControls editor={editor} />
      <TableControls editor={editor} />
      <AlignmentControls editor={editor} />
      <LinkControls editor={editor} />
      <MediaControls editor={editor} insightId={insightId} />
    </div>
  );
};

export default EditorToolbar;
