export const whyChooseTranslations = {
  EN: {
    title: "Why Choose Duru Compliance?",
    subtitle: "Your trusted partner for business expansion in India",
    solutionsTitle: "One-Stop India Entry Solutions",
    solutions: [
      "Indian Company Registration & Incorporation",
      "Office & Business Location Setup",
      "Organization & HR Setup in India",
      "Annual Accounting & Corporate Compliance"
    ],
    stats: {
      businesses: "Indian Companies Established",
      clients: "Recruitment Success",
      experience: "Fastest Problem Resolution",
      recognitions: "Expert Professionals"
    },
    cta: "Start Your India Journey",
    trustSignals: {
      title: ""
    }
  },
  KO: {
    title: "두루컴플라이언스를 선택하는 이유",
    subtitle: "인도 비즈니스 확장을 위한 신뢰할 수 있는 파트너",
    solutionsTitle: "인도 진출 원스탑 솔루션",
    solutions: [
      "인도 법인 설립 및 등록",
      "사무실 및 사업장 셋업",
      "인도 조직 및 인사 셋업",
      "연간 회계 및 법인 규정 준수"
    ],
    stats: {
      businesses: "인도 법인 설립 및 진출 지원",
      clients: "인도 인력 채용",
      experience: "법인 설립 및 문제 해결 속도",
      recognitions: "두루 소속 전문가"
    },
    cta: "인도 진출 시작하기",
    trustSignals: {
      title: ""
    }
  }
};