
import { editorStyles } from '@/components/editor/editorConfig';

interface InsightContentProps {
  content: string;
}

const InsightContent = ({ content }: InsightContentProps) => {
  return (
    <>
      <style>{editorStyles}</style>
      <div 
        className="prose prose-lg max-w-none prose-headings:font-bold prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-p:text-gray-600 prose-a:text-primary prose-img:rounded-lg prose-pre:bg-gray-100 prose-pre:p-4 prose-ul:list-disc prose-ol:list-decimal prose-table:border-collapse prose-td:border prose-td:p-2 prose-th:border prose-th:p-2 prose-table:w-full ProseMirror"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  );
};

export default InsightContent;
