export const contactTranslations = {
  EN: {
    badge: "Contact Us",
    title: "Get in Touch",
    subtitle: "Have questions about our services? We're here to help you navigate your business journey in India.",
    form: {
      title: "Send us a Message",
      name: "Name",
      email: "Email",
      phone: "Phone Number",
      phoneHint: "Include country code (e.g., +91 for India)",
      subject: "Subject",
      message: "Message",
      submit: "Send Message",
      success: "Message Sent",
      successDetail: "We'll get back to you as soon as possible."
    },
    info: {
      title: "Contact Information",
      address: {
        label: "Office Address",
        value: "No. 23, 2nd Floor,\n1st Main Road, MCHS,\nJakkur Layout, Jakkur,\nBengaluru, Karnataka 560064,\nIndia"
      },
      phone: {
        label: "Phone Number",
        value: "+91 (123) 456-7890"
      },
      email: {
        label: "Email Address",
        value: "contact@durucompliance.com"
      }
    },
    marketing: {
      globalExpertise: {
        title: "Global Expertise",
        description: "With over 20 years of experience in international business consulting, we've helped hundreds of companies successfully expand into India."
      },
      endToEndSupport: {
        title: "End-to-End Support",
        description: "From company registration to compliance management, we provide comprehensive solutions for your business needs."
      },
      localKnowledge: {
        title: "Local Knowledge",
        description: "Our team of experts understands both local regulations and international business practices to ensure your success."
      }
    }
  },
  KO: {
    badge: "문의하기",
    title: "연락하기",
    subtitle: "서비스에 대해 궁금하신 점이 있으신가요? 인도 비즈니스 진출을 도와드리겠습니다.",
    form: {
      title: "메시지 보내기",
      name: "이름",
      email: "이메일",
      phone: "전화번호",
      phoneHint: "국가 코드를 포함해 주세요 (예: 한국 +82)",
      subject: "제목",
      message: "메시지 내용",
      submit: "메시지 전송",
      success: "전송 완료",
      successDetail: "최대한 빨리 답변 드리도록 하겠습니다."
    },
    info: {
      title: "연락처 정보",
      address: {
        label: "사무실 주소",
        value: "No. 23, 2층,\n1st Main Road, MCHS,\nJakkur Layout, Jakkur,\nBengaluru, Karnataka 560064,\nIndia"
      },
      phone: {
        label: "전화번호",
        value: "+91 (123) 456-7890"
      },
      email: {
        label: "이메일",
        value: "contact@durucompliance.com"
      }
    },
    marketing: {
      globalExpertise: {
        title: "글로벌 전문성",
        description: "20년 이상의 국제 비즈니스 컨설팅 경험을 바탕으로 수백 개의 기업의 성공적인 인도 진출을 지원했습니다."
      },
      endToEndSupport: {
        title: "원스톱 지원",
        description: "법인 설립부터 규정 준수 관리까지, 귀사의 비즈니스 요구사항에 대한 포괄적인 솔루션을 제공합니다."
      },
      localKnowledge: {
        title: "현지 전문성",
        description: "현지 규정과 국제 비즈니스 관행을 모두 이해하는 전문가 팀이 귀사의 성공을 보장합니다."
      }
    }
  }
};
