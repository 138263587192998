
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Hero from "@/components/Hero";
import Services from "@/components/Services";
import Testimonials from "@/components/Testimonials";
import BlogPreview from "@/components/BlogPreview";
import CeoPreviewSection from "@/components/CeoPreviewSection";
import WhyChooseSection from "@/components/WhyChooseSection";
import FeaturedEvents from "@/components/about/FeaturedEvents";
import Footer from "@/components/Footer";
import SEOMetaTags from "@/components/SEOMetaTags";

interface IndexProps {
  language: string;
}

const Index = ({ language }: IndexProps) => {
  const location = useLocation();
  const servicesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.state?.scrollToServices && servicesRef.current) {
      const headerHeight = 64;
      const elementPosition = servicesRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, [location.state]);

  return (
    <>
      <SEOMetaTags language={language} path={location.pathname} />
      <main className="min-h-screen">
        <Hero language={language} />
        <CeoPreviewSection language={language} />
        <WhyChooseSection language={language} />
        <div ref={servicesRef}>
          <Services language={language} />
        </div>
        <Testimonials language={language} />
        <FeaturedEvents language={language} />
        <BlogPreview language={language} />
        <Footer language={language} />
      </main>
    </>
  );
};

export default Index;
