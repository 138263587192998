
export const footerTranslations = {
  EN: {
    logoAlt: "Duru Compliance Logo",
    description: "Seamless business expansion in India—Incorporation, compliance, taxation, and recruitment, all in one place.",
    quickLinks: {
      title: "Quick Links",
      services: "Services",
      about: "About",
      insights: "Insights",
      contact: "Contact",
      gallery: "Gallery",
      admin: "Admin"
    },
    contact: {
      title: "Contact Info",
      address: "Bengaluru, India"
    },
    social: {
      title: "Follow Us",
      facebook: "Follow us on Facebook",
      instagram: "Follow us on Instagram",
      linkedin: "Follow us on LinkedIn",
      whatsapp: "Contact us on WhatsApp",
      kakao: "Contact us on Kakao"
    },
    copyright: "© 2024 Duru Compliance. All rights reserved."
  },
  KO: {
    logoAlt: "두루 컴플라이언스 로고",
    description: "인도 비즈니스 확장을 위한 원스톱 솔루션—법인 설립, 규정 준수, 세무, 채용을 한 곳에서.",
    quickLinks: {
      title: "빠른 링크",
      services: "서비스",
      about: "회사 소개",
      insights: "인사이트",
      contact: "연락처",
      gallery: "갤러리",
      admin: "관리자"
    },
    contact: {
      title: "연락처 정보",
      address: "인도 벵갈루루"
    },
    social: {
      title: "팔로우하기",
      facebook: "페이스북에서 팔로우하기",
      instagram: "인스타그램에서 팔로우하기",
      linkedin: "링크드인에서 팔로우하기",
      whatsapp: "왓츠앱으로 연락하기",
      kakao: "카카오로 연락하기"
    },
    copyright: "© 2024 두루 컴플라이언스. 모든 권리 보유."
  }
};
