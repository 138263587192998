
export const editorStyles = `
  .ProseMirror {
    min-height: 200px;
    outline: none;
    padding: 1rem 1.5rem;
  }
  
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  
  .ProseMirror h1 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  .ProseMirror h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  .ProseMirror h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  .ProseMirror img {
    max-width: 100%;
    height: auto;
    margin: 1em 0;
  }
  
  .ProseMirror table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
    font-size: 0.9em;
  }
  
  .ProseMirror td,
  .ProseMirror th {
    border: 0.5px solid #e5e7eb;
    box-sizing: border-box;
    min-width: 1em;
    padding: 0.15rem 0.35rem;
    position: relative;
    vertical-align: middle;
    line-height: 1;
    height: 1.5em;
  }
  
  .ProseMirror th {
    background-color: #f8f9fa;
    font-weight: 600;
    text-align: left;
    padding: 0.2rem 0.35rem;
  }
  
  .ProseMirror .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }

  .ProseMirror ul {
    list-style-type: disc;
    padding-left: 1.5em;
    margin: 1em 0;
  }
  
  .ProseMirror ol {
    list-style-type: decimal;
    padding-left: 1.5em;
    margin: 1em 0;
  }
  
  .ProseMirror li {
    margin-bottom: 0.5em;
  }

  .ProseMirror li p {
    margin: 0;
  }

  .ProseMirror p {
    margin: 1em 0;
  }

  .ProseMirror [style*="text-align:center"],
  .ProseMirror [style*="text-align: center"] {
    text-align: center;
  }

  .ProseMirror [style*="text-align:right"],
  .ProseMirror [style*="text-align: right"] {
    text-align: right;
  }

  .ProseMirror [style*="text-align:left"],
  .ProseMirror [style*="text-align: left"] {
    text-align: left;
  }

  .ProseMirror a {
    color: #2563eb;
    text-decoration: underline;
    transition: color 0.2s ease;
  }

  .ProseMirror a:hover {
    color: #1d4ed8;
  }

  .slash-commands-menu {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    max-height: 400px;
    overflow-y: auto;
    padding: 0.5rem;
    width: 320px;
  }

  .slash-commands-menu .items {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .slash-commands-menu .item {
    align-items: center;
    border-radius: 0.375rem;
    display: flex;
    gap: 0.75rem;
    padding: 0.5rem;
    text-align: left;
    width: 100%;
  }

  .slash-commands-menu .item:hover,
  .slash-commands-menu .item.is-selected {
    background: #f3f4f6;
  }

  .slash-commands-menu .item .icon {
    align-items: center;
    background: #f3f4f6;
    border-radius: 0.25rem;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
  }

  .slash-commands-menu .item.is-selected .icon {
    background: #e5e7eb;
  }

  .slash-commands-menu .item .content {
    flex: 1;
  }

  .slash-commands-menu .item .title {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .slash-commands-menu .item .description {
    color: #6b7280;
    font-size: 0.75rem;
  }
`;
