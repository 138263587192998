import React, { useState } from "react";
import { Card } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import type { Insight } from "@/types/insight";
import InsightCardHeader from "./InsightCardHeader";
import InsightCardFooter from "./InsightCardFooter";

interface InsightCardProps {
  insight: Insight;
  language?: string;
}

const InsightCard = ({ insight, language = 'EN' }: InsightCardProps) => {
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(insight.likes_count || 0);

  const handleShare = (platform: string) => {
    const url = window.location.origin + `/insights/${insight.slug}`;
    const title = language === 'KO' && insight.title_ko ? insight.title_ko : insight.title_en;
    
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
      email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`Check out this insight: ${url}`)}`
    };

    window.open(shareUrls[platform as keyof typeof shareUrls], '_blank');
  };

  const handleLike = async () => {
    try {
      const newLikesCount = isLiked ? likesCount - 1 : likesCount + 1;
      
      const { error } = await supabase
        .from('insights')
        .update({ likes_count: newLikesCount })
        .eq('id', insight.id);

      if (error) throw error;

      setIsLiked(!isLiked);
      setLikesCount(newLikesCount);
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  return (
    <Card className="flex flex-col h-full overflow-hidden transition-all duration-300 hover:shadow-lg bg-white border border-gray-100">
      {insight.thumbnail_url && (
        <div className="relative h-40 overflow-hidden">
          <img
            src={insight.thumbnail_url}
            alt={language === 'KO' && insight.title_ko ? insight.title_ko : insight.title_en}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
        </div>
      )}
      
      <div className="flex flex-col flex-grow p-4">
        <div className="flex-grow">
          <InsightCardHeader insight={insight} language={language} />

          {insight.tags.length > 0 && (
            <div className="flex flex-wrap gap-1.5 mb-3">
              {insight.tags.map((tag) => (
                <span
                  key={tag}
                  className="text-[10px] bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full"
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>

        <InsightCardFooter
          readingTime={insight.reading_time || 5}
          likesCount={likesCount}
          viewsCount={insight.views_count || 0}
          isLiked={isLiked}
          onLike={handleLike}
          onShare={handleShare}
        />
      </div>
    </Card>
  );
};

export default InsightCard;