
export const calculateReadingTime = (content: string): number => {
  // Average reading speed (words per minute)
  const wordsPerMinute = 200;
  
  // Count words (split by spaces and filter out empty strings)
  const wordCount = content.split(/\s+/).filter(word => word.length > 0).length;
  
  // Calculate reading time in minutes, rounded up to nearest minute
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  
  // Return at least 1 minute for very short content
  return Math.max(1, readingTime);
};
