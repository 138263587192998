
import { Facebook, Instagram, Linkedin, MapPin } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "@/utils/translations";

interface FooterProps {
  language: string;
}

const Footer = ({ language }: FooterProps) => {
  const t = useTranslation(language);

  return (
    <footer className="bg-neutral-dark text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <img
              src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/dcl_logo/duru_compliance_logo_monochrome.png"
              alt={t.footer.logoAlt}
              className="h-10 w-auto"
            />
            <p className="text-sm text-gray-300">
              {t.footer.description}
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="font-semibold mb-4 text-left">{t.footer.quickLinks.title}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/services" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.services}
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.about}
                </Link>
              </li>
              <li>
                <Link to="/insights" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.insights}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.contact}
                </Link>
              </li>
              <li>
                <Link to="/gallery" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.gallery}
                </Link>
              </li>
              <li>
                <Link to="/admin" className="text-sm text-gray-300 hover:text-white transition-colors">
                  {t.footer.quickLinks.admin}
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="font-semibold mb-4 text-left">{t.footer.contact.title}</h3>
            <ul className="space-y-3">
              <li className="flex items-start gap-2 text-sm text-gray-300">
                <MapPin className="w-4 h-4 mt-1 flex-shrink-0" />
                <span>{t.footer.contact.address}</span>
              </li>
              <li>
                <a
                  href="https://wa.me/919008177740"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-sm text-gray-300 hover:text-white transition-colors"
                >
                  <img 
                    src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/footer/whatsapp-48.png" 
                    alt="WhatsApp"
                    className="w-4 h-4 flex-shrink-0"
                  />
                  <span>WhatsApp</span>
                </a>
              </li>
              <li>
                <a
                  href="https://open.kakao.com/o/sKpfsVYg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-sm text-gray-300 hover:text-white transition-colors"
                >
                  <img 
                    src="https://scmtfyjpaivqbafbtooy.supabase.co/storage/v1/object/public/dcl_website_asset/footer/kakaotalk_logo.png" 
                    alt="KakaoTalk"
                    className="w-4 h-4 flex-shrink-0"
                  />
                  <span>KakaoTalk</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="font-semibold mb-4 text-left">{t.footer.social.title}</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.linkedin.com/company/duru-compliance/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white transition-colors"
                aria-label={t.footer.social.linkedin}
              >
                <Linkedin className="w-5 h-5" />
              </a>
              <a
                href="https://www.facebook.com/durucompliance"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white transition-colors"
                aria-label={t.footer.social.facebook}
              >
                <Facebook className="w-5 h-5" />
              </a>
              <a
                href="https://www.instagram.com/duru_compliance/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white transition-colors"
                aria-label={t.footer.social.instagram}
              >
                <Instagram className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-8 text-sm text-gray-300 text-center">
          <p>{t.footer.copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
