import { useState } from 'react';
import { useToast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { supabase } from '@/integrations/supabase/client';
import { Image, Loader2 } from 'lucide-react';

interface ImageUploadProps {
  eventId: string;
  onUploadComplete?: () => void;
}

const ImageUpload = ({ eventId, onUploadComplete }: ImageUploadProps) => {
  const { toast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [isCover, setIsCover] = useState(false);

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      // Upload file to storage
      const fileExt = file.name.split('.').pop();
      const filePath = `${crypto.randomUUID()}.${fileExt}`;
      
      const { error: uploadError } = await supabase.storage
        .from('gallery_images')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('gallery_images')
        .getPublicUrl(filePath);

      // Save image metadata to database
      const { error: dbError } = await supabase
        .from('gallery_images')
        .insert([{
          event_id: eventId,
          image_url: publicUrl,
          is_cover: isCover,
          alt_text: file.name,
        }]);

      if (dbError) throw dbError;

      toast({
        title: 'Success',
        description: 'Image uploaded successfully',
      });

      // Reset form
      event.target.value = '';
      setIsCover(false);
      
      // Trigger refresh of the image grid
      onUploadComplete?.();
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <Label>Upload Images</Label>
        <div className="mt-2 space-y-2">
          <Input
            type="file"
            accept="image/*"
            onChange={handleUpload}
            disabled={uploading}
          />
          <div className="flex items-center space-x-2">
            <Switch
              id="is_cover"
              checked={isCover}
              onCheckedChange={setIsCover}
              disabled={uploading}
            />
            <Label htmlFor="is_cover">Set as cover image</Label>
          </div>
        </div>
      </div>

      {uploading && (
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          <Loader2 className="w-4 h-4 animate-spin" />
          <span>Uploading...</span>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;